import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import axiosService from "../../utils/axios";
import RequestCard from "../../components/request-card/RequestCard";
import locationSlice from "../../store/slices/location";
import "./Requests.css";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import authSlice from "../../store/slices/auth";
import { RootState } from "../../store";
import PlatformNavigation from "../../components/platform-navigation/PlatformNavigation";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import PendingIcon from "@mui/icons-material/Pending";
import CheckIcon from "@mui/icons-material/Check";
import HelpIcon from "@mui/icons-material/Help";
import AddRequest from "../../components/add-request/AddRequest";
import ForwardIcon from "@mui/icons-material/Forward";
import MobileAddRequest from "../../components/mobile-add-request/MobileAddRequest";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import RequestsHiddenHints from "../../components/request-hidden-hints/RequestsHiddenHints";
import Tooltip from "@mui/material/Tooltip";
import StartIcon from "@mui/icons-material/Start";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { OutlinedInput, TextField } from "@mui/material";
import ClearAllIcon from "@mui/icons-material/ClearAll";

import { useAlert } from "react-alert";
const RequestsUser = () => {
  const [requests, setRequests] = useState([]);
  const [filteredRequests, setFilteredRequests] = useState<Array<any>>([]);
  const alert = useAlert();
  const [checkedClicked, setCheckedClicked] = useState(false);
  const [acceptedClicked, setAcceptedClicked] = useState(false);
  const [rejectedClicked, setRejectedClicked] = useState(false);
  const [startedClicked, setStartedClicked] = useState(false);
  const [finishedClicked, setFinishedClicked] = useState(false);
  const [numChecked, setNumChecked] = useState(0);
  const [numAccepted, setNumAccepted] = useState(0);
  const [numRejected, setNumRejected] = useState(0);
  const [numStarted, setNumStarted] = useState(0);
  const [numFinished, setNumFinished] = useState(0);
  const [isFirst, setIsFirst] = useState(true);
  const [hideHints, setHideHints] = useState(false);
  const [showAddRequestPopup, setShowAddRequestPopup] = useState(false);
  const [hotSkills, setHotSkills] = useState<any[]>([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state: RootState) => state.auth);
  const scrollToRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isFirst) {
      getRequests();
      getSkills();
      setIsFirst(false);
    }

    if (!auth.account) {
      navigate("/login");
    }
  }, []);

  const getSkills = () => {
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_skills`)
      .then((res) => {
        let tmpHotSkills: any[] = [];
        res.data.forEach((data: any) => {
          if (data.is_hot_skill) {
            tmpHotSkills.push(data);
          }
        });

        setHotSkills(tmpHotSkills);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (scrollToRef.current?.offsetTop) {
      window.scrollTo({
        top: scrollToRef.current?.offsetTop - 100,
        behavior: "smooth",
      });
      setTimeout(function () {
        clearSelected();
      }, 2000);
    }
  }, [scrollToRef.current]);

  // useLayoutEffect(() => {
  //   return () => {
  //     clearSelected();
  //   };
  // }, []);

  const clearSelected = () => {
    dispatch(authSlice.actions.setSelectedSubgroup(null));
    dispatch(authSlice.actions.setSelectedSubgroupId(null));
    dispatch(authSlice.actions.setSelectedGroup(null));
    dispatch(authSlice.actions.setSelectedGroupId(null));
  };
  const getRequests = () => {
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_user_requests/` + auth.account)
      .then((res) => {
        setRequests(res.data);
        let tmpRej = 0;
        let tmpFin = 0;
        let tmpSt = 0;
        let tmpAcc = 0;
        let tmpPend = 0;
        res.data.forEach((request: any) => {
          if (request.status === "REJECTED") {
            tmpRej++;
          } else if (request.status === "FINISHED") {
            tmpFin++;
          } else if (request.status === "STARTED") {
            tmpSt++;
          } else if (request.status === "ACCEPTED") {
            tmpAcc++;
          } else if (request.status === "PENDING") {
            tmpPend++;
          }
        });
        setNumAccepted(tmpAcc);
        setNumChecked(tmpPend);
        setNumFinished(tmpFin);
        setNumStarted(tmpSt);
        setNumRejected(tmpRej);
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };

  const handleFinishedClick = () => {
    setFinishedClicked(!finishedClicked);
    if (!finishedClicked) {
      let tmp = filteredRequests;
      requests.forEach((request: any) => {
        if (request.status === "FINISHED") {
          tmp.push(request);
        }
      });
      setFilteredRequests(tmp);
    } else {
      let tmp: any[] = [];
      filteredRequests.forEach((request: any) => {
        if (request.status !== "FINISHED") {
          tmp.push(request);
        }
      });
      setFilteredRequests(tmp);
    }
  };
  const handleCheckedClick = () => {
    setCheckedClicked(!checkedClicked);
    if (!checkedClicked) {
      let tmp = filteredRequests;
      requests.forEach((request: any) => {
        if (request.status === "PENDING") {
          tmp.push(request);
        }
      });
      setFilteredRequests(tmp);
    } else {
      let tmp: any[] = [];
      filteredRequests.forEach((request: any) => {
        if (request.status !== "PENDING") {
          tmp.push(request);
        }
      });

      setFilteredRequests(tmp);
    }
  };
  const handleAcceptedClick = () => {
    setAcceptedClicked(!acceptedClicked);
    if (!acceptedClicked) {
      let tmp = filteredRequests;
      requests.forEach((request: any) => {
        if (request.status === "ACCEPTED") {
          tmp.push(request);
        }
      });
      setFilteredRequests(tmp);
    } else {
      let tmp: any[] = [];
      filteredRequests.forEach((request: any) => {
        if (request.status !== "ACCEPTED") {
          tmp.push(request);
        }
      });

      setFilteredRequests(tmp);
    }
  };
  const handleRejectedClick = () => {
    setRejectedClicked(!rejectedClicked);
    if (!rejectedClicked) {
      let tmp = filteredRequests;
      requests.forEach((request: any) => {
        if (request.status === "REJECTED") {
          tmp.push(request);
        }
      });

      setFilteredRequests(tmp);
    } else {
      let tmp: any[] = [];
      filteredRequests.forEach((request: any) => {
        if (request.status !== "REJECTED") {
          tmp.push(request);
        }
      });

      setFilteredRequests(tmp);
    }
  };
  const handleStartedClick = () => {
    setStartedClicked(!startedClicked);
    if (!startedClicked) {
      let tmp = filteredRequests;
      requests.forEach((request: any) => {
        if (request.status === "STARTED") {
          tmp.push(request);
        }
      });
      setFilteredRequests(tmp);
    } else {
      let tmp: any[] = [];
      filteredRequests.forEach((request: any) => {
        if (request.status !== "STARTED") {
          tmp.push(request);
        }
      });

      setFilteredRequests(tmp);
    }
  };

  const handleClosePopup = () => {
    setShowAddRequestPopup(false);
    getRequests();
  };

  return (
    <div className="column-space-between">
      <div className="req-add-button-background">
        <span className="heading">Requests</span>
        <span
          onClick={() => setShowAddRequestPopup(true)}
          className="text-Proxima Nova700 orange-text pointer"
          style={{ marginLeft: 20, marginTop: 6 }}
        >
          + Add a new Request
        </span>
      </div>

      {!auth.expert && (
        <div
          className="row-space-between wrap "
          style={{ justifyContent: hideHints ? "flex-end" : "space-between" }}
        >
          <div
            className="req-card-hints"
            style={{ display: hideHints ? "none" : "" }}
          >
            <div className="leads-hints-left">
              <b className="heading-lora">Add a new request</b>
              <span className="text-Proxima Nova opp-grey padding-up-down20">
                Request a project proposal (RfPP) by letting us know all the
                details of how we can support you. We’ll break your RfPP down
                into jobs to align the staffing with you. You can add a
                dedicated proposal manager if you want to do so, else you’ll
                stay the manager of the RfPP.
              </span>
              <div className="opp-arrow-out padding-up-down">
                <span className="text-Proxima Nova-small blue">
                  Add an RfPP
                </span>
                <ForwardIcon className="arrow" sx={{ height: 20 }} />
                <span className="text-Proxima Nova-small blue">
                  We verify the RfPP & create dedicated jobs to staff{" "}
                </span>
                <ForwardIcon className="arrow" sx={{ height: 20 }} />
                <span className="text-Proxima Nova-small blue">
                  We’ll align the developer short list with you
                </span>
              </div>
            </div>
            <img
              src={require("../../assets/images/hints.png")}
              className="request-img"
            />

            <Tooltip title="Hide" placement="bottom" arrow>
              <RemoveCircleIcon
                sx={{ fontSize: 30 }}
                className="req-hide-icon"
                onClick={() => setHideHints(!hideHints)}
                style={{
                  justifySelf: hideHints ? "flex-end" : "",
                }}
              ></RemoveCircleIcon>
            </Tooltip>
          </div>

          <div
            className="separate-area"
            style={{ display: hideHints ? "none" : "" }}
          />
          <div
            className="row-space-between"
            style={{ display: hideHints ? "" : "none" }}
          >
            <RequestsHiddenHints
              setHideHints={setHideHints}
            ></RequestsHiddenHints>
          </div>
        </div>
      )}

      <div className="leads-background-grey">
        {window.innerWidth >= 800
          ? showAddRequestPopup && (
              <AddRequest
                show={showAddRequestPopup}
                onClose={handleClosePopup}
              />
            )
          : showAddRequestPopup && (
              <MobileAddRequest
                show={showAddRequestPopup}
                onClose={handleClosePopup}
              />
            )}

        {window.innerWidth >= 1506 ? (
          <div className="projects-row-start wrap">
            <div
              className="kpi-card-requests margin-right-small"
              style={{
                border: !finishedClicked
                  ? "0px solid grey"
                  : "1px solid #FF6210",

                boxShadow: !finishedClicked
                  ? "0px 2px 6px rgba(0, 0, 0, 0.16)"
                  : "none",
              }}
              onClick={handleFinishedClick}
            >
              <DoneAllIcon className="margin-kpi-projects req-green" />
              <strong className="text-Proxima Nova700-small darkgrey padding-r5">
                {" "}
                Finished Requests
              </strong>
              <strong className="text-Proxima Nova-small lightgrey">
                ({numFinished})
              </strong>
            </div>

            <div
              className="kpi-card-requests margin-right-small"
              style={{
                border: !startedClicked
                  ? "0px solid grey"
                  : "1px solid #FF6210",
                boxShadow: !startedClicked
                  ? "0px 2px 6px rgba(0, 0, 0, 0.16)"
                  : "none",
              }}
              onClick={handleStartedClick}
            >
              <StartIcon className="margin-kpi-projects req-blue" />
              <strong className="text-Proxima Nova700-small darkgrey padding-r5">
                {" "}
                Started Requests
              </strong>
              <strong className="text-Proxima Nova-small lightgrey">
                ({numStarted})
              </strong>
            </div>

            <div
              className="kpi-card-requests margin-right-small"
              style={{
                border: !acceptedClicked
                  ? "0px solid grey"
                  : "1px solid #FF6210",
                boxShadow: !acceptedClicked
                  ? "0px 2px 6px rgba(0, 0, 0, 0.16)"
                  : "none",
              }}
              onClick={handleAcceptedClick}
            >
              <ThumbUpOffAltIcon className="margin-kpi-projects req-purple" />
              <strong className="text-Proxima Nova700-small darkgrey padding-r5">
                Accepted Requests
              </strong>
              <strong className="text-Proxima Nova-small lightgrey">
                ({numAccepted})
              </strong>
            </div>

            <div
              className="kpi-card-requests margin-right-small"
              style={{
                border: !checkedClicked
                  ? "0px solid grey"
                  : "1px solid #FF6210",
                boxShadow: !checkedClicked
                  ? "0px 2px 6px rgba(0, 0, 0, 0.16)"
                  : "none",
              }}
              onClick={handleCheckedClick}
            >
              <HourglassEmptyIcon className="margin-kpi-projects req-turquoise" />
              <strong className="text-Proxima Nova700-small darkgrey padding-r5">
                {" "}
                Requests Processing
              </strong>
              <strong className="text-Proxima Nova-small lightgrey">
                ({numChecked})
              </strong>
            </div>

            <div
              className="kpi-card-requests"
              style={{
                border: !rejectedClicked
                  ? "0px solid grey"
                  : "1px solid #FF6210",
                boxShadow: !rejectedClicked
                  ? "0px 2px 6px rgba(0, 0, 0, 0.16)"
                  : "none",
              }}
              onClick={handleRejectedClick}
            >
              <DoDisturbIcon className="margin-kpi-projects req-red" />
              <strong className="text-Proxima Nova700-small darkgrey padding-r5">
                {" "}
                Rejected Requests
              </strong>
              <strong className="text-Proxima Nova-small lightgrey">
                ({numRejected})
              </strong>
            </div>
          </div>
        ) : (
          <Box className="full-percent-width">
            <FormControl fullWidth>
              <InputLabel shrink={true} id="demo-simple-select-label">
                Filter Requests
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                // value={skill.level}
                input={
                  <OutlinedInput
                    id="select-multiple-chip"
                    label="Filter Requests"
                    notched
                  />
                }
              >
                <MenuItem value={"ALLREQUESTS"}>
                  {" "}
                  <ClearAllIcon className="margin-r3 " />
                  All Requests
                </MenuItem>
                <MenuItem value={"FINISHEDREQUESTS"}>
                  {" "}
                  <DoneAllIcon className="margin-r3 req-green" />
                  Finished Requests {"( " + numFinished.toString() + " )"}
                </MenuItem>
                <MenuItem value={"STARTEDREQUESTS"}>
                  {" "}
                  <StartIcon className="margin-r3 req-blue" />
                  Started Requests {"( " + numStarted.toString() + " )"}
                </MenuItem>
                <MenuItem value={"ACCEPTEDREQUESTS"}>
                  <ThumbUpOffAltIcon className="margin-r3 req-purple" />
                  Accepted Requests {"( " + numAccepted.toString() + " )"}
                </MenuItem>
                <MenuItem value={"PROCESSINGREQUESTS"}>
                  <HourglassEmptyIcon className="margin-r3 req-turquoise" />
                  Processing Requests {"( " + numChecked.toString() + " )"}
                </MenuItem>
                <MenuItem value={"REJECTEDREQUESTS"}>
                  <DoDisturbIcon className="margin-r3 req-red" />
                  Rejected Requests {"( " + numRejected.toString() + " )"}
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        )}

        <div className="separate-area requests-body">
          {filteredRequests.length > 0 ? (
            <div>
              {filteredRequests.map((request: any) => {
                const itemProps =
                  auth.selectedGroup === "REQUEST" &&
                  auth.selectedGroupId === request.id
                    ? { ref: scrollToRef }
                    : {};
                return (
                  <div
                    key={request.id}
                    {...itemProps}
                    onClick={clearSelected}
                    className={
                      request.id === auth.selectedGroupId
                        ? "highlight-selected"
                        : ""
                    }
                  >
                    <RequestCard
                      highlight={request.id === auth.selectedGroupId}
                      key={request.id}
                      request={request}
                      hotSkills={hotSkills}
                      getRequests={getRequests}
                    />
                  </div>
                );
              })}
            </div>
          ) : requests.length > 0 ? (
            <div>
              {requests.map((request: any) => {
                const itemProps =
                  auth.selectedGroup === "REQUEST" &&
                  auth.selectedGroupId === request.id
                    ? { ref: scrollToRef }
                    : {};
                return (
                  <div
                    key={request.id}
                    {...itemProps}
                    onClick={clearSelected}
                    className={
                      request.id === auth.selectedGroupId
                        ? "highlight-selected"
                        : ""
                    }
                  >
                    <RequestCard
                      highlight={request.id === auth.selectedGroupId}
                      key={request.id}
                      request={request}
                      hotSkills={hotSkills}
                      getRequests={getRequests}
                    />
                  </div>
                );
              })}
            </div>
          ) : (
            <br />
          )}
        </div>
      </div>
    </div>
  );
};

export default RequestsUser;
