import { useEffect, useState } from "react";
import "./AddFrameWorkAgreement.css";
import PropTypes from "prop-types";
import axiosService from "../../utils/axios";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import FormControlLabel from "@mui/material/FormControlLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Button, Input, TextareaAutosize, TextField } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAlert } from "react-alert";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AddFrameWorkAgreement = (props: any) => {
  const [show, setShow] = useState(false);

  const [selectedDev, setSelectedDev] = useState("");
  const [selectedClientManager, setSelectedClientManager] = useState("");
  const [clientUsers, setClientUsers] = useState<Array<string>>([]);
  const [isFirst, setIsFirst] = useState(true);
  const [hoursPerWeek, setHoursPerWeek] = useState<number>(40);
  const [eurosPerHour, setEurosPerHour] = useState<number>(0);
  const [file, setFile] = useState<any>();
  const [fileClient, setFileClient] = useState<any>();
  const alert = useAlert();

  const closeHandler = (e: any) => {
    setShow(false);
    props.onClose(false);
  };
  useEffect(() => {
    if (isFirst) {
      setIsFirst(false);
    }
  }, []);

  useEffect(() => {
    const close = (e: any) => {
      if (e.keyCode === 27) {
        closeHandler(true);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  const addContract = () => {
    const form = new FormData();
    let url = "";

    if (props.dev) {
      form.append("file", file, file.name);

      form.append("id", props.id.toString());
      url = "/add_framework_agreement_dev";
    } else {
      form.append("file_client", fileClient, fileClient.name);
      form.append("id", props.id.toString());
      url = "/add_framework_agreement_client";
    }

    axiosService
      .post(process.env.REACT_APP_API_URL + url, form)
      .then((res) => {
        alert.success("The Framework Agreement was added");
        props.onClose(true);
      })

      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  const onFileChange = (event: any) => {
    setFile(event.target.files[0]);
  };

  const onFileChangeClient = (event: any) => {
    setFileClient(event.target.files[0]);
  };

  const fileData = () => {
    if (file) {
      return (
        <div className="row-center padding-normal full-percent-width">
          <h2>File Details:</h2>

          <p> {file.name}</p>
        </div>
      );
    } else {
      return (
        <div className="row-center padding-normal full-percent-width">
          <br />
          <h4>Please select a File</h4>
        </div>
      );
    }
  };
  const fileDataClient = () => {
    if (fileClient) {
      return (
        <div className="row-center padding-normal full-percent-width">
          <h2>File Details:</h2>

          <p> {fileClient.name}</p>
        </div>
      );
    } else {
      return (
        <div className="row-center padding-normal full-percent-width">
          <br />
          <h4>Please select a File</h4>
        </div>
      );
    }
  };

  return (
    <div
      style={{
        visibility: show ? "visible" : "hidden",
        opacity: show ? "1" : "0",
      }}
      className={"overlay"}
    >
      <div className={"popup-add-contract"}>
        <div className="row-space-between-center">
          <div className="row-start-no-width">
            <span className="heading-lora padding-up-down margin-right">
              Add a Framework Agreement
            </span>
          </div>
          <span className={"close"} onClick={closeHandler}>
            &times;
          </span>
        </div>

        <div className="column-space-between">
          <div className="column-start-start">
            <div className="row-space-between">
              {props.dev ? (
                <div className="column-center margin-right padding-normal">
                  <label htmlFor="upload-framework">
                    <input
                      style={{ display: "none" }}
                      id="upload-framework"
                      name="upload-framework"
                      type="file"
                      accept="application/pdf"
                      onChange={onFileChange}
                    />
                    <Button
                      color="primary"
                      size="small"
                      component="span"
                      aria-label="add"
                    >
                      Upload the Framework Agreement for the Dev
                    </Button>
                  </label>
                  {fileData()}
                </div>
              ) : (
                <div className="column-center padding-normal">
                  <label htmlFor="upload-framework-client">
                    <input
                      style={{ display: "none" }}
                      id="upload-framework-client"
                      name="upload-framework-client"
                      type="file"
                      accept="application/pdf"
                      onChange={onFileChangeClient}
                    />
                    <Button
                      color="primary"
                      size="small"
                      component="span"
                      aria-label="add"
                    >
                      Upload the Framework Agreement for the Client
                    </Button>
                  </label>
                  {fileDataClient()}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="row-space-between-center margin-t20">
          <button
            className="button-white-small margin-r20 text-Proxima Nova700-small"
            onClick={() => props.onClose(true)}
          >
            {" "}
            Cancel
          </button>
          <button
            className="button-black-small-nm text-Proxima Nova700-small"
            onClick={addContract}
          >
            {" "}
            Upload Framework Agreement
          </button>
        </div>
      </div>
    </div>
  );
};

AddFrameWorkAgreement.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  dev: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
};

export default AddFrameWorkAgreement;
