import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import axiosService from "../../utils/axios";

import authSlice from "../../store/slices/auth";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "moment/locale/en-gb";
import locationSlice from "../../store/slices/location";
import "./Projects.css";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import SearchIcon from "@mui/icons-material/Search";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import CheckIcon from "@mui/icons-material/Check";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ProjectCard from "../../components/project-card/ProjectCard";
import ContractCard from "../../components/contract-card/ContractCard";
import PhaseCard from "../../components/phase-card/PhaseCard";
import MilestoneCard from "../../components/milestone-card/MilestoneCard";
import moment from "moment";
import ReportCard from "../../components/report-card/ReportCard";
import AddPhase from "../../components/add-phase/AddPhase";
import TimesheetCard from "../../components/timesheet-card/TimesheetCard";
import AddTimesheet from "../../components/add-timesheet/AddTimesheet";
import AddInvoice from "../../components/add-invoice/AddInvoice";
import InvoiceCard from "../../components/invoice-card/InvoiceCard";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import Tooltip from "@mui/material/Tooltip";
import ProjectsHiddenHints from "../../components/projects-hidden-hints/ProjectsHiddenHints";
import ForwardIcon from "@mui/icons-material/Forward";
import AddMilestone from "../../components/add-milestone/AddMilestone";
import AddReport from "../../components/add-report/AddReport";
import { OutlinedInput, TextField } from "@mui/material";
import MobileSearchReports from "../../components/mobile-search-reports/MobileSearchReports";
import AddIcon from "@mui/icons-material/Add";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import PhasesTimeline from "../../components/project-phases-timeline/PhasesTimeline";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import JoinLeftIcon from "@mui/icons-material/JoinLeft";
import PushPinIcon from "@mui/icons-material/PushPin";
import ClearAllIcon from "@mui/icons-material/ClearAll";

function ProjectsUser() {
  const [projects, setProjects] = useState([]);
  const [rfpps, setRfpps] = useState<any[]>([]);
  const [filteredProjects, setFilteredProjects] = useState<any[]>([]);
  const [reports, setReports] = useState([]);
  const [selectedProject, setSelectedProject] = useState<any>({});
  const [selectedRfpp, setSelectedRfpp] = useState<any>({});

  const [searchReportEscalate, setSearchReportEscalate] = useState(false);
  const [searchReportString, setSearchReportString] = useState("");
  const [searchReportRate, setSearchReportRate] = useState("");
  const [searchReportStartDate, setSearchReportStartDate] = useState("");
  const [searchReportEndDate, setSearchReportEndDate] = useState("");
  const [contractsClicked, setContractsClicked] = useState(false);
  const [acceptedContractsClicked, setAcceptedContractsClicked] =
    useState(false);
  const [alignmentContractsClicked, setAlignmentContractsClicked] =
    useState(false);
  const alert = useAlert();
  const [newContractsClicked, setNewContractsClicked] = useState(false);
  const [phasesClicked, setPhasesClicked] = useState(true);
  const [selectedPhaseId, setSelectedPhaseId] = useState<number | null>(null);
  const [selectedMilestoneId, setSelectedMilestoneId] = useState<number | null>(
    null
  );
  const [selectedTimesheet, setSelectedTimesheet] = useState<any | null>(null);
  const [showAddPhasePopup, setShowAddPhasePopup] = useState(false);
  const [showAddReportPopup, setShowAddReportPopup] = useState(false);
  const [showAddMilestonePopup, setShowAddMilestonePopup] = useState(false);
  const [showAddTimesheetPopup, setShowAddTimesheetPopup] = useState(false);
  const [showAddInvoicePopup, setShowAddInvoicePopup] = useState(false);
  const [newPhasesClicked, setNewPhasesClicked] = useState(false);

  const [inProgressPhasesClicked, setInProgressPhasesClicked] = useState(false);
  const [okPhasesClicked, setOkPhasesClicked] = useState(false);
  const [breachedPhasesClicked, setBreachedPhasesClicked] = useState(false);
  const [newMilestonesClicked, setNewMilestonesClicked] = useState(false);
  const [inProgressMilestonesClicked, setInProgressMilestonesClicked] =
    useState(false);
  const [okMilestonesClicked, setOkMilestonesClicked] = useState(false);
  const [breachedMilestonesClicked, setBreachedMilestonesClicked] =
    useState(false);
  const [newTimesheetsClicked, setNewTimesheetsClicked] = useState(false);
  const [rejectedTimesheetsClicked, setRejectedTimesheetsClicked] =
    useState(false);
  const [okTimesheetsClicked, setOkTimesheetsClicked] = useState(false);
  const [newInvoicesClicked, setNewInvoicesClicked] = useState(false);
  const [cancelledInvoicesClicked, setCancelledInvoicesClicked] =
    useState(false);
  const [paidInvoicesClicked, setPaidInvoicesClicked] = useState(false);
  const [milestonesClicked, setMilestonesClicked] = useState(false);
  const [reportsClicked, setReportsClicked] = useState(false);
  const [timesheetsClicked, setTimesheetsClicked] = useState(false);
  const [invoicesClicked, setInvoicesClicked] = useState(false);
  const [messagesClicked, setMessagesClicked] = useState(false);
  const [contracts, setContracts] = useState<Array<any>>([]);
  const [phases, setPhases] = useState<Array<any>>([]);
  const [milestones, setMilestones] = useState<Array<any>>([]);
  const [timesheets, setTimesheets] = useState<Array<any>>([]);
  const [invoices, setInvoices] = useState<Array<any>>([]);
  const [weekReport, setWeekReport] = useState(0);
  const [monthReport, setMonthReport] = useState(0);
  const [allTimeReport, setAllTimeReport] = useState(0);
  const [numAcceptedContracts, setNumAcceptedContracts] = useState(0);
  const [numAlignmentContracts, setNumAlignmentContracts] = useState(0);
  const [numNewContracts, setNumNewContracts] = useState(0);
  const [numNewPhases, setNumNewPhases] = useState(0);
  const [numInProgressPhases, setNumInProgressPhases] = useState(0);
  const [numOkPhases, setNumOkPhases] = useState(0);
  const [numBreachedPhases, setNumBreachedPhases] = useState(0);
  const [numNewMilestones, setNumNewMilestones] = useState(0);
  const [numInProgressMilestones, setNumInProgressMilestones] = useState(0);
  const [numOkMilestones, setNumOkMilestones] = useState(0);
  const [numNewTimesheets, setNumNewTimesheets] = useState(0);
  const [numRejectedTimesheets, setNumRejectedTimesheets] = useState(0);
  const [numOkTimesheets, setNumOkTimesheets] = useState(0);
  const [numNewInvoices, setNumNewInvoices] = useState(0);
  const [numCancelledInvoices, setNumCancelledInvoices] = useState(0);
  const [numPaidInvoices, setNumPaidInvoices] = useState(0);
  const [numBreachedMilestones, setNumBreachedMilestones] = useState(0);
  const [isFirst, setIsFirst] = useState(true);
  const [hideHints, setHideHints] = useState(false);
  const [showInvoices, setShowInvoices] = useState(false);
  const [updateTimeline, setUpdateTimeline] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state: RootState) => state.auth);
  const scrollToRef = useRef<HTMLDivElement>(null);
  const ref = useRef<any>(null);
  const [width, setWidth] = useState(0);
  const [showHighlight, setShowHighlight] = useState(false);
  const [mobileFilterInvoices, setMobileFilterInvoices] = useState("");
  const [mobileFilterTimesheets, setMobileFilterTimesheets] = useState("");
  const [mobileFilterMilestones, setMobileFilterMilestones] = useState("");
  const [mobileFilterPhases, setMobileFilterPhases] = useState("");
  const [hotSkills, setHotSkills] = useState<any[]>([]);

  useEffect(() => {
    if (isFirst) {
      getProjects();
      getSkills();

      setIsFirst(false);
    }

    dispatch(
      locationSlice.actions.setLocation({
        path: "/platform/projects",
      })
    );
    if (ref.current) {
      setWidth(ref.current.offsetWidth);
    }
    if (!auth.account) {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    const resize = () => {
      if (ref.current) {
        setWidth(ref.current.offsetWidth);
      }
    };
    window.addEventListener("resize", resize);
    return () => window.removeEventListener("resize", resize);
  }, []);

  useEffect(() => {
    if (scrollToRef.current?.offsetTop) {
      window.scrollTo({
        top: scrollToRef.current?.offsetTop - 100,
        behavior: "smooth",
      });
      setTimeout(function () {
        clearSelected();
      }, 2000);
    }
  }, [scrollToRef.current, showHighlight]);

  const getSkills = () => {
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_skills`)
      .then((res) => {
        let tmpHotSkills: any[] = [];
        res.data.forEach((data: any) => {
          if (data.is_hot_skill) {
            tmpHotSkills.push(data);
          }
        });

        setHotSkills(tmpHotSkills);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useLayoutEffect(() => {
    if (auth.selectedGroup === "CONTRACT" || auth.selectedGroup === "PROJECT") {
      switch (auth.selectedSubgroup) {
        case "TIMESHEET":
          setTimesheetsClicked(true);
          setPhasesClicked(false);
          setInvoicesClicked(false);
          setMilestonesClicked(false);
          setReportsClicked(false);

          break;
        case "INVOICE":
          setTimesheetsClicked(false);
          setPhasesClicked(false);
          setInvoicesClicked(true);
          setMilestonesClicked(false);
          setReportsClicked(false);

          break;
        case "REPORT":
          setTimesheetsClicked(false);
          setPhasesClicked(false);
          setInvoicesClicked(false);
          setMilestonesClicked(false);
          setReportsClicked(true);

          break;
        case "PHASE":
          setTimesheetsClicked(false);
          setPhasesClicked(true);
          setInvoicesClicked(false);
          setMilestonesClicked(false);
          setReportsClicked(false);

          break;
        case "MILESTONE":
          setTimesheetsClicked(false);
          setPhasesClicked(false);
          setInvoicesClicked(false);
          setMilestonesClicked(true);
          setReportsClicked(false);

          break;
      }
    }
  }, []);

  const clearSelected = () => {
    dispatch(authSlice.actions.setSelectedSubgroup(null));
    dispatch(authSlice.actions.setSelectedSubgroupId(null));
    dispatch(authSlice.actions.setSelectedGroup(null));
    dispatch(authSlice.actions.setSelectedGroupId(null));
  };

  useEffect(() => {
    if (selectedRfpp.id) {
      let tmp_filtered: any[] = [];
      projects.forEach((project: any) => {
        if (project.job.rfpp?.id === selectedRfpp.id) {
          tmp_filtered.push(project);
        }
      });
      setFilteredProjects(tmp_filtered);
    }
  }, [selectedRfpp, projects]);

  useEffect(() => {
    if (selectedProject && selectedProject.job) {
      getReports();
      let tmp1 = 0;
      let tmp2 = 0;
      let tmp3 = 0;
      let tmp4 = 0;

      // selectedProject.jobs.forEach((job: any) => {
      // if (job.contract_status === "ACCEPTED") {
      //   tmp1++;
      // }
      // if (job.contract_status === "NEW") {
      //   tmp2++;
      // }
      // if (job.contract_status === "IN_ALIGNMENT") {
      //   tmp3++;
      // }
      // });
      setNumAcceptedContracts(tmp1);
      setNumAlignmentContracts(tmp3);
      setNumNewContracts(tmp2);
      // tmp1 = 0;
      // tmp2 = 0;
      // tmp3 = 0;
      selectedProject.phases.forEach((phase: any) => {
        if (phase.status === "IN_PROGRESS") {
          tmp1++;
        }
        if (phase.status === "NEW") {
          tmp2++;
        }
        if (phase.status === "BREACHED") {
          tmp3++;
        }
        if (phase.status === "OK") {
          tmp4++;
        }
      });

      setNumBreachedPhases(tmp3);
      setNumOkPhases(tmp4);
      setNumNewPhases(tmp2);
      setNumInProgressPhases(tmp1);
      tmp1 = 0;
      tmp2 = 0;
      tmp3 = 0;
      tmp4 = 0;
      selectedProject.milestones.forEach((milestone: any) => {
        if (milestone.status === "IN_PROGRESS") {
          tmp1++;
        }
        if (milestone.status === "NEW") {
          tmp2++;
        }
        if (milestone.status === "BREACHED") {
          tmp3++;
        }
        if (milestone.status === "OK") {
          tmp4++;
        }
      });

      setNumBreachedMilestones(tmp3);
      setNumOkMilestones(tmp4);
      setNumNewMilestones(tmp2);
      setNumInProgressMilestones(tmp1);
      tmp1 = 0;
      tmp2 = 0;
      tmp3 = 0;
      tmp4 = 0;
      selectedProject.timesheets.forEach((timesheet: any) => {
        if (timesheet.status === "OK") {
          tmp1++;
        }
        if (timesheet.status === "NEW") {
          tmp2++;
        }
        if (timesheet.status === "REJECTED") {
          tmp3++;
        }
      });

      setNumOkTimesheets(tmp1);
      setNumRejectedTimesheets(tmp3);
      setNumNewTimesheets(tmp2);
      tmp1 = 0;
      tmp2 = 0;
      tmp3 = 0;
      tmp4 = 0;
      selectedProject.invoices.forEach((invoice: any) => {
        if (invoice.status === "PAID") {
          tmp1++;
        }
        if (invoice.status === "NEW") {
          tmp2++;
        }
        if (invoice.status === "CANCELLED") {
          tmp3++;
        }
      });

      setNumPaidInvoices(tmp1);
      setNumCancelledInvoices(tmp3);
      setNumNewInvoices(tmp2);

      tmp1 = 0;
      tmp2 = 0;
      tmp3 = 0;
      tmp4 = 0;
      let tmp5 = 0;
      let tmp6 = 0;
      if (selectedProject.reports) {
        selectedProject.reports.forEach((report: any) => {
          if (moment().subtract(7, "days") <= moment(report.createdAt)) {
            tmp1++;
            if (report.rate === "EXCITED") {
              tmp2 += 5;
            } else if (report.rate === "HAPPY") {
              tmp2 += 4;
            } else if (report.rate === "NEUTRAL") {
              tmp2 += 3;
            } else if (report.rate === "SAD") {
              tmp2 += 2;
            } else if (report.rate === "ANGRY") {
              tmp2 += 1;
            }
          }
          if (moment().subtract(30, "days") <= moment(report.createdAt)) {
            tmp3++;
            if (report.rate === "EXCITED") {
              tmp4 += 5;
            } else if (report.rate === "HAPPY") {
              tmp4 += 4;
            } else if (report.rate === "NEUTRAL") {
              tmp4 += 3;
            } else if (report.rate === "SAD") {
              tmp4 += 2;
            } else if (report.rate === "ANGRY") {
              tmp4 += 1;
            }
          }
          tmp5++;
          if (report.rate === "EXCITED") {
            tmp6 += 5;
          } else if (report.rate === "HAPPY") {
            tmp6 += 4;
          } else if (report.rate === "NEUTRAL") {
            tmp6 += 3;
          } else if (report.rate === "SAD") {
            tmp6 += 2;
          } else if (report.rate === "ANGRY") {
            tmp6 += 1;
          }
        });

        if (tmp3 !== 0) {
          setMonthReport(tmp4 / tmp3);
        }

        if (tmp1 !== 0) {
          setWeekReport(tmp2 / tmp1);
        }
        if (tmp5 !== 0) {
          setAllTimeReport(tmp6 / tmp5);
        }
      }
    }
  }, [selectedProject]);

  const getReports = () => {
    let body = {
      rate: "",
      contract: selectedProject.id,
      start_date: "",
      end_date: "",
      search_string: "",
    };

    axiosService
      .post(`${process.env.REACT_APP_API_URL}/search_reports`, body)
      .then((res) => {
        setReports(res.data);
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };

  const handleSearchReport = () => {
    let body = {
      rate: searchReportRate,
      escalate: searchReportEscalate,
      start_date: moment(searchReportStartDate).format("YYYY-MM-DD").toString(),
      contract: selectedProject.id,
      end_date: moment(searchReportEndDate).format("YYYY-MM-DD").toString(),
      search_string: searchReportString,
    };
    axiosService
      .post(`${process.env.REACT_APP_API_URL}/search_reports`, body)
      .then((res) => {
        setReports(res.data);
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };
  const getProjects = () => {
    if (auth.selectedView === "DEVELOPER") {
      axiosService
        .get(
          `${process.env.REACT_APP_API_URL}/get_user_projects/` + auth.account
        )
        .then((res) => {
          setShowInvoices(true);
          setProjects(res.data);
          setFilteredProjects(res.data);
          if (
            selectedProject.id ||
            ((auth.selectedGroup === "CONTRACT" ||
              auth.selectedGroup === "PROJECT") &&
              auth.selectedGroupId !== null)
          ) {
            res.data.forEach((project: any) => {
              if (
                (selectedProject.id && project.id === selectedProject.id) ||
                project.id === auth.selectedGroupId
              ) {
                project.applied = true;
                setSelectedProject(project);
              }
            });
          } else {
            if (res.data.length > 0) {
              res.data[0].applied = true;
              setSelectedProject(res.data[0]);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (auth.selectedView === "CLIENT") {
      axiosService
        .get(
          `${process.env.REACT_APP_API_URL}/get_user_projects_client/` +
            auth.account
        )
        .then((res) => {
          let tmp_jobs: any = [];
          let tmp_rfpps: any[] = [];
          if (res.data.length > 0) {
            setShowInvoices(res.data[0].show_invoices);
          }

          if (
            selectedProject.id ||
            ((auth.selectedGroup === "CONTRACT" ||
              auth.selectedGroup === "PROJECT") &&
              auth.selectedGroupId !== null)
          ) {
            res.data.forEach((project: any) => {
              if (
                project.id === selectedProject.id ||
                project.id === auth.selectedGroupId
              ) {
                project.applied = true;
                setSelectedProject(project);
                setSelectedRfpp(project.job.rfpp);
                let tmp_filtered: any[] = [];
                res.data.forEach((p: any) => {
                  if (p.job.rfpp.id && p.job.rfpp.id === project.job.rfpp.id) {
                    tmp_filtered.push(project);
                  }
                });
                setFilteredProjects(tmp_filtered);
              }
              if (project.job.rfpp) {
                tmp_jobs.push(project);
                project.applied = true;

                if (!tmp_rfpps.some((e) => e.id === project.job.rfpp.id)) {
                  tmp_rfpps.push(project.job.rfpp);
                }
              }

              setProjects(tmp_jobs);

              setRfpps(tmp_rfpps);
            });
          } else {
            res.data.forEach((project: any) => {
              if (project.job.rfpp) {
                tmp_jobs.push(project);
                project.applied = true;
                setSelectedProject(project);
                setSelectedRfpp(project.job.rfpp);

                if (!tmp_rfpps.some((e) => e.id === project.job.rfpp.id)) {
                  tmp_rfpps.push(project.job.rfpp);
                }
              }
            });
            setProjects(tmp_jobs);
            setRfpps(tmp_rfpps);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleProjectSelect = (event: any) => {
    projects.forEach((project: any) => {
      if (project.id === event.target.value) {
        project.applied = true;
        setSelectedProject(project);
      }
    });
  };

  const handleRfppSelect = (event: any) => {
    let tmp_filtered: any[] = [];

    projects.forEach((project: any) => {
      if (project.job.rfpp.id && project.job.rfpp.id === event.target.value) {
        setSelectedRfpp(project.job.rfpp);
        tmp_filtered.push(project);
      }
    });
    setFilteredProjects(tmp_filtered);
  };

  const clearAllSearchReport = () => {
    setSearchReportEndDate("");
    setSearchReportEscalate(false);
    setSearchReportStartDate("");
    setSearchReportString("");
    setSearchReportRate("");
  };

  const handleAcceptedContractsClick = () => {
    setAcceptedContractsClicked(!acceptedContractsClicked);
    if (!acceptedContractsClicked) {
      let tmp = contracts;
      selectedProject.jobs.forEach((job: any) => {
        if (job.contract_status === "ACCEPTED") {
          tmp.push(job);
        }
      });
      setContracts(tmp);
    } else {
      let tmp: any[] = [];
      contracts.forEach((job: any) => {
        if (job.contract_status !== "ACCEPTED") {
          tmp.push(job);
        }
      });
      setContracts(tmp);
    }
  };
  const handleAlignmentContractsClick = () => {
    setAlignmentContractsClicked(!alignmentContractsClicked);
    if (!alignmentContractsClicked) {
      let tmp = contracts;
      selectedProject.jobs.forEach((job: any) => {
        if (job.contract_status === "IN_ALIGNMENT") {
          tmp.push(job);
        }
      });
      setContracts(tmp);
    } else {
      let tmp: any[] = [];
      contracts.forEach((job: any) => {
        if (job.contract_status !== "IN_ALIGNMENT") {
          tmp.push(job);
        }
      });
      setContracts(tmp);
    }
  };
  const handleNewContractsClick = () => {
    setNewContractsClicked(!newContractsClicked);
    if (!newContractsClicked) {
      let tmp = contracts;
      selectedProject.jobs.forEach((job: any) => {
        if (job.contract_status === "NEW") {
          tmp.push(job);
        }
      });
      setContracts(tmp);
    } else {
      let tmp: any[] = [];
      contracts.forEach((job: any) => {
        if (job.contract_status !== "NEW") {
          tmp.push(job);
        }
      });
      setContracts(tmp);
    }
  };
  const handleNewPhasesClick = () => {
    setNewPhasesClicked(!newPhasesClicked);
    if (!newPhasesClicked) {
      let tmp = phases;
      selectedProject.phases.forEach((phase: any) => {
        if (phase.status === "NEW") {
          tmp.push(phase);
        }
      });
      setPhases(tmp);
    } else {
      let tmp: any[] = [];
      phases.forEach((phase: any) => {
        if (phase.status !== "NEW") {
          tmp.push(phase);
        }
      });
      setPhases(tmp);
    }
  };
  const handleInProgressPhasesClick = () => {
    setInProgressPhasesClicked(!inProgressPhasesClicked);
    if (!inProgressPhasesClicked) {
      let tmp = phases;
      selectedProject.phases.forEach((phase: any) => {
        if (phase.status === "IN_PROGRESS") {
          tmp.push(phase);
        }
      });
      setPhases(tmp);
    } else {
      let tmp: any[] = [];
      phases.forEach((phase: any) => {
        if (phase.status !== "IN_PROGRESS") {
          tmp.push(phase);
        }
      });
      setPhases(tmp);
    }
  };
  const handleClickMilestone = (milestone: any) => {
    setSelectedMilestoneId(milestone.id);
    setShowHighlight(true);
    setPhasesClicked(false);
    setMilestonesClicked(true);
    setReportsClicked(false);
    setTimesheetsClicked(false);
    setInvoicesClicked(false);
    setMessagesClicked(false);

    setTimeout(function () {
      setShowHighlight(false);
    }, 3000);
  };

  const handleClickPhase = (phase: any) => {
    setPhasesClicked(true);
    setMilestonesClicked(false);
    setReportsClicked(false);
    setTimesheetsClicked(false);
    setInvoicesClicked(false);
    setMessagesClicked(false);
    setSelectedPhaseId(phase.id);
    setShowHighlight(true);
    setTimeout(function () {
      setShowHighlight(false);
    }, 3000);
  };
  const handleEditPhase = (id: number) => {
    setSelectedPhaseId(id);
    setShowAddPhasePopup(true);
  };

  const handleEditMilestone = (id: number) => {
    setSelectedMilestoneId(id);
    setShowAddMilestonePopup(true);
  };
  const handleEditTimesheet = (timesheet: any) => {
    setSelectedTimesheet(timesheet);
    setShowAddTimesheetPopup(true);
  };
  const handleChangeMobileFilterPhases = (value: string) => {
    setMobileFilterPhases(value);
    if (value == "IN_PROGRESS") {
      handleInProgressPhasesClickMobile();
    } else if (value == "NEW") {
      handleNewPhasesClickMobile();
    } else if (value == "BREACHED") {
      handleBreachedPhasesClickMobile();
    } else if (value == "OK") {
      handleOkPhasesClickMobile();
    } else {
      setPhases(selectedProject.phases);
    }
  };
  const handleOkPhasesClickMobile = () => {
    setOkPhasesClicked(true);
    setNewPhasesClicked(false);
    setBreachedPhasesClicked(false);
    setInProgressPhasesClicked(false);

    let tmp: any[] = [];
    selectedProject.phases.forEach((phase: any) => {
      if (phase.status === "OK") {
        tmp.push(phase);
      }
    });
    setPhases(tmp);
  };
  const handleBreachedPhasesClickMobile = () => {
    setOkPhasesClicked(false);
    setNewPhasesClicked(false);
    setBreachedPhasesClicked(true);
    setInProgressPhasesClicked(false);

    let tmp: any[] = [];
    selectedProject.phases.forEach((phase: any) => {
      if (phase.status === "BREACHED") {
        tmp.push(phase);
      }
    });
    setPhases(tmp);
  };
  const handleNewPhasesClickMobile = () => {
    setOkPhasesClicked(false);
    setNewPhasesClicked(true);
    setBreachedPhasesClicked(false);
    setInProgressPhasesClicked(false);

    let tmp: any[] = [];
    selectedProject.phases.forEach((phase: any) => {
      if (phase.status === "NEW") {
        tmp.push(phase);
      }
    });
    setPhases(tmp);
  };
  const handleInProgressPhasesClickMobile = () => {
    setOkPhasesClicked(false);
    setNewPhasesClicked(false);
    setBreachedPhasesClicked(false);
    setInProgressPhasesClicked(true);

    let tmp: any[] = [];
    selectedProject.phases.forEach((phase: any) => {
      if (phase.status === "IN_PROGRESS") {
        tmp.push(phase);
      }
    });
    setPhases(tmp);
  };
  const handleOkPhasesClick = () => {
    setOkPhasesClicked(!okPhasesClicked);
    if (!okPhasesClicked) {
      let tmp = phases;
      selectedProject.phases.forEach((phase: any) => {
        if (phase.status === "OK") {
          tmp.push(phase);
        }
      });
      setPhases(tmp);
    } else {
      let tmp: any[] = [];
      phases.forEach((phase: any) => {
        if (phase.status !== "OK") {
          tmp.push(phase);
        }
      });
      setPhases(tmp);
    }
  };
  const handleBreachedPhasesClick = () => {
    setBreachedPhasesClicked(!breachedPhasesClicked);
    if (!breachedPhasesClicked) {
      let tmp = phases;
      selectedProject.phases.forEach((phase: any) => {
        if (phase.status === "BREACHED") {
          tmp.push(phase);
        }
      });
      setPhases(tmp);
    } else {
      let tmp: any[] = [];
      phases.forEach((phase: any) => {
        if (phase.status !== "BREACHED") {
          tmp.push(phase);
        }
      });
      setPhases(tmp);
    }
  };
  const handleChangeMobileFilterMilestones = (value: string) => {
    setMobileFilterMilestones(value);
    if (value == "IN_PROGRESS") {
      handleInProgressMilestonesClickMobile();
    } else if (value == "NEW") {
      handleNewMilestonesClickMobile();
    } else if (value == "BREACHED") {
      handleBreachedMilestonesClickMobile();
    } else if (value == "OK") {
      handleOkMilestonesClickMobile();
    } else {
      setMilestones(selectedProject.milestones);
    }
  };
  const handleNewMilestonesClickMobile = () => {
    setNewMilestonesClicked(true);
    setOkMilestonesClicked(false);
    setBreachedMilestonesClicked(false);
    setInProgressMilestonesClicked(false);

    let tmp: any[] = [];
    selectedProject.milestones.forEach((milestone: any) => {
      if (milestone.status === "NEW") {
        tmp.push(milestone);
      }
    });
    setMilestones(tmp);
  };
  const handleInProgressMilestonesClickMobile = () => {
    setNewMilestonesClicked(false);
    setOkMilestonesClicked(false);
    setBreachedMilestonesClicked(false);
    setInProgressMilestonesClicked(true);

    let tmp: any[] = [];
    selectedProject.milestones.forEach((milestone: any) => {
      if (milestone.status === "IN_PROGRESS") {
        tmp.push(milestone);
      }
    });
    setMilestones(tmp);
  };
  const handleOkMilestonesClickMobile = () => {
    setNewMilestonesClicked(false);
    setOkMilestonesClicked(true);
    setBreachedMilestonesClicked(false);
    setInProgressMilestonesClicked(false);

    let tmp: any[] = [];
    selectedProject.milestones.forEach((milestone: any) => {
      if (milestone.status === "OK") {
        tmp.push(milestone);
      }
    });
    setMilestones(tmp);
  };
  const handleBreachedMilestonesClickMobile = () => {
    setNewMilestonesClicked(false);
    setOkMilestonesClicked(false);
    setBreachedMilestonesClicked(true);
    setInProgressMilestonesClicked(false);

    let tmp: any[] = [];
    selectedProject.milestones.forEach((milestone: any) => {
      if (milestone.status === "BREACHED") {
        tmp.push(milestone);
      }
    });
    setMilestones(tmp);
  };
  const handleNewMilestonesClick = () => {
    setNewMilestonesClicked(!newMilestonesClicked);
    if (!newMilestonesClicked) {
      let tmp = milestones;
      selectedProject.milestones.forEach((milestone: any) => {
        if (milestone.status === "NEW") {
          tmp.push(milestone);
        }
      });
      setMilestones(tmp);
    } else {
      let tmp: any[] = [];
      milestones.forEach((milestone: any) => {
        if (milestone.status !== "NEW") {
          tmp.push(milestone);
        }
      });
      setMilestones(tmp);
    }
  };
  const handleInProgressMilestonesClick = () => {
    setInProgressMilestonesClicked(!inProgressMilestonesClicked);
    if (!inProgressMilestonesClicked) {
      let tmp = milestones;
      selectedProject.milestones.forEach((milestone: any) => {
        if (milestone.status === "IN_PROGRESS") {
          tmp.push(milestone);
        }
      });
      setMilestones(tmp);
    } else {
      let tmp: any[] = [];
      milestones.forEach((milestone: any) => {
        if (milestone.status !== "IN_PROGRESS") {
          tmp.push(milestone);
        }
      });
      setMilestones(tmp);
    }
  };
  const handleOkMilestonesClick = () => {
    setOkMilestonesClicked(!okMilestonesClicked);
    if (!okMilestonesClicked) {
      let tmp = milestones;
      selectedProject.milestones.forEach((milestone: any) => {
        if (milestone.status === "OK") {
          tmp.push(milestone);
        }
      });
      setMilestones(tmp);
    } else {
      let tmp: any[] = [];
      milestones.forEach((milestone: any) => {
        if (milestone.status !== "OK") {
          tmp.push(milestone);
        }
      });
      setMilestones(tmp);
    }
  };
  const handleBreachedMilestonesClick = () => {
    setBreachedMilestonesClicked(!breachedMilestonesClicked);
    if (!breachedMilestonesClicked) {
      let tmp = milestones;
      selectedProject.milestones.forEach((milestone: any) => {
        if (milestone.status === "BREACHED") {
          tmp.push(milestone);
        }
      });
      setMilestones(tmp);
    } else {
      let tmp: any[] = [];
      milestones.forEach((milestone: any) => {
        if (milestone.status !== "BREACHED") {
          tmp.push(milestone);
        }
      });
      setMilestones(tmp);
    }
  };
  const handleChangeMobileFilterInvoices = (value: string) => {
    setMobileFilterInvoices(value);
    if (value == "PAID") {
      handlePaidInvoicesClickMobile();
    } else if (value == "NEW") {
      handleNewInvoicesClickMobile();
    } else if (value == "CANCELLED") {
      handleCancelledInvoicesClickMobile();
    } else {
      setInvoices(selectedProject.invoices);
    }
  };
  const handleNewInvoicesClickMobile = () => {
    setNewInvoicesClicked(true);
    setCancelledInvoicesClicked(false);
    setPaidInvoicesClicked(false);

    let tmp: any[] = [];
    selectedProject.invoices.forEach((invoice: any) => {
      if (invoice.status === "NEW") {
        tmp.push(invoice);
      }
    });
    setInvoices(tmp);
  };
  const handleCancelledInvoicesClickMobile = () => {
    setNewInvoicesClicked(false);
    setCancelledInvoicesClicked(true);
    setPaidInvoicesClicked(false);

    let tmp: any[] = [];
    selectedProject.invoices.forEach((invoice: any) => {
      if (invoice.status === "CANCELLED") {
        tmp.push(invoice);
      }
    });
    setInvoices(tmp);
  };
  const handlePaidInvoicesClickMobile = () => {
    setNewInvoicesClicked(false);
    setCancelledInvoicesClicked(false);
    setPaidInvoicesClicked(true);

    let tmp: any[] = [];
    selectedProject.invoices.forEach((invoice: any) => {
      if (invoice.status === "PAID") {
        tmp.push(invoice);
      }
    });
    setInvoices(tmp);
  };
  const handleNewInvoicesClick = () => {
    setNewInvoicesClicked(!newInvoicesClicked);
    if (!newInvoicesClicked) {
      let tmp = invoices;
      selectedProject.invoices.forEach((invoice: any) => {
        if (invoice.status === "NEW") {
          tmp.push(invoice);
        }
      });
      setInvoices(tmp);
    } else {
      let tmp: any[] = [];
      invoices.forEach((invoice: any) => {
        if (invoice.status !== "NEW") {
          tmp.push(invoice);
        }
      });
      setInvoices(tmp);
    }
  };
  const handleCancelledInvoicesClick = () => {
    setCancelledInvoicesClicked(!cancelledInvoicesClicked);
    if (!cancelledInvoicesClicked) {
      let tmp = invoices;
      selectedProject.invoices.forEach((invoice: any) => {
        if (invoice.status === "CANCELLED") {
          tmp.push(invoice);
        }
      });
      setInvoices(tmp);
    } else {
      let tmp: any[] = [];
      invoices.forEach((invoice: any) => {
        if (invoice.status !== "CANCELLED") {
          tmp.push(invoice);
        }
      });
      setInvoices(tmp);
    }
  };
  const handlePaidInvoicesClick = () => {
    setPaidInvoicesClicked(!paidInvoicesClicked);
    if (!paidInvoicesClicked) {
      let tmp = invoices;
      selectedProject.invoices.forEach((invoice: any) => {
        if (invoice.status === "PAID") {
          tmp.push(invoice);
        }
      });
      setInvoices(tmp);
    } else {
      let tmp: any[] = [];
      invoices.forEach((invoice: any) => {
        if (invoice.status !== "PAID") {
          tmp.push(invoice);
        }
      });
      setInvoices(tmp);
    }
  };
  const handleChangeMobileFilterTimesheets = (value: string) => {
    setMobileFilterTimesheets(value);
    if (value == "OK") {
      handleOkTimesheetsClickMobile();
    } else if (value == "NEW") {
      handleNewTimesheetsClickMobile();
    } else if (value == "REJECTED") {
      handleRejectedTimesheetsClickMobile();
    } else {
      setTimesheets(selectedProject.timesheets);
    }
  };
  const handleOkTimesheetsClickMobile = () => {
    setOkTimesheetsClicked(true);
    setRejectedTimesheetsClicked(false);
    setNewTimesheetsClicked(false);

    let tmp: any[] = [];
    selectedProject.timesheets.forEach((timesheet: any) => {
      if (timesheet.status === "OK") {
        tmp.push(timesheet);
      }
    });
    setTimesheets(tmp);
  };

  const handleNewTimesheetsClickMobile = () => {
    setOkTimesheetsClicked(false);
    setRejectedTimesheetsClicked(false);
    setNewTimesheetsClicked(true);

    let tmp: any[] = [];
    selectedProject.timesheets.forEach((timesheet: any) => {
      if (timesheet.status === "NEW") {
        tmp.push(timesheet);
      }
    });
    setTimesheets(tmp);
  };

  const handleRejectedTimesheetsClickMobile = () => {
    setOkTimesheetsClicked(false);
    setRejectedTimesheetsClicked(true);
    setNewTimesheetsClicked(false);

    let tmp: any[] = [];
    selectedProject.timesheets.forEach((timesheet: any) => {
      if (timesheet.status === "REJECTED") {
        tmp.push(timesheet);
      }
    });
    setTimesheets(tmp);
  };
  const handleOkTimesheetsClick = () => {
    setOkTimesheetsClicked(!okTimesheetsClicked);
    if (!okTimesheetsClicked) {
      let tmp = timesheets;
      selectedProject.timesheets.forEach((timesheet: any) => {
        if (timesheet.status === "OK") {
          tmp.push(timesheet);
        }
      });
      setTimesheets(tmp);
    } else {
      let tmp: any[] = [];
      timesheets.forEach((timesheet: any) => {
        if (timesheet.status !== "OK") {
          tmp.push(timesheet);
        }
      });
      setTimesheets(tmp);
    }
  };

  const handleNewTimesheetsClick = () => {
    setNewTimesheetsClicked(!newTimesheetsClicked);
    if (!newTimesheetsClicked) {
      let tmp = timesheets;
      selectedProject.timesheets.forEach((timesheet: any) => {
        if (timesheet.status === "NEW") {
          tmp.push(timesheet);
        }
      });
      setTimesheets(tmp);
    } else {
      let tmp: any[] = [];
      timesheets.forEach((timesheet: any) => {
        if (timesheet.status !== "NEW") {
          tmp.push(timesheet);
        }
      });
      setTimesheets(tmp);
    }
  };

  const handleRejectedTimesheetsClick = () => {
    setRejectedTimesheetsClicked(!rejectedTimesheetsClicked);
    if (!rejectedTimesheetsClicked) {
      let tmp = timesheets;
      selectedProject.timesheets.forEach((timesheet: any) => {
        if (timesheet.status === "REJECTED") {
          tmp.push(timesheet);
        }
      });
      setTimesheets(tmp);
    } else {
      let tmp: any[] = [];
      timesheets.forEach((timesheet: any) => {
        if (timesheet.status !== "REJECTED") {
          tmp.push(timesheet);
        }
      });
      setTimesheets(tmp);
    }
  };

  const handleContractsTabClick = () => {
    setContractsClicked(true);
    setPhasesClicked(false);
    setMilestonesClicked(false);
    setReportsClicked(false);
    setTimesheetsClicked(false);
    setInvoicesClicked(false);
    setMessagesClicked(false);
  };
  const handlePhasesTabClick = () => {
    setContractsClicked(false);
    setPhasesClicked(!phasesClicked);
    setMilestonesClicked(false);
    setReportsClicked(false);
    setTimesheetsClicked(false);
    setInvoicesClicked(false);
    setMessagesClicked(false);
  };
  const handleMilestonesTabClick = () => {
    setContractsClicked(false);
    setPhasesClicked(false);
    setMilestonesClicked(!milestonesClicked);
    setReportsClicked(false);
    setTimesheetsClicked(false);
    setInvoicesClicked(false);
    setMessagesClicked(false);
  };
  const handleReportsTabClick = () => {
    setContractsClicked(false);
    setPhasesClicked(false);
    setMilestonesClicked(false);
    setReportsClicked(!reportsClicked);
    setTimesheetsClicked(false);
    setInvoicesClicked(false);
    setMessagesClicked(false);
  };
  const handleTimesheetsTabClick = () => {
    setContractsClicked(false);
    setPhasesClicked(false);
    setMilestonesClicked(false);
    setReportsClicked(false);
    setTimesheetsClicked(!timesheetsClicked);
    setInvoicesClicked(false);
    setMessagesClicked(false);
  };
  const handleInvoicesTabClick = () => {
    setContractsClicked(false);
    setPhasesClicked(false);
    setMilestonesClicked(false);
    setReportsClicked(false);
    setTimesheetsClicked(false);
    setInvoicesClicked(!invoicesClicked);
    setMessagesClicked(false);
  };
  const handleMessagesTabClick = () => {
    setContractsClicked(false);
    setPhasesClicked(false);
    setMilestonesClicked(false);
    setReportsClicked(false);
    setTimesheetsClicked(false);
    setInvoicesClicked(false);
    setMessagesClicked(!messagesClicked);
  };
  const handleCloseAddPhase = () => {
    setShowAddPhasePopup(false);
    setSelectedPhaseId(null);
    getProjects();
  };
  const handleCloseAddReport = () => {
    setShowAddReportPopup(false);
    getProjects();
  };
  const handleCloseAddMilestone = () => {
    setShowAddMilestonePopup(false);
    setSelectedMilestoneId(null);
    getProjects();
  };
  const handleCloseAddTimesheet = () => {
    setShowAddTimesheetPopup(false);
    getProjects();
  };
  const handleCloseAddInvoice = () => {
    setShowAddInvoicePopup(false);
    getProjects();
  };

  return (
    <div className="column-space-between">
      <span className="heading margin-l20">Projects</span>
      {!auth.expert && (
        <div
          className="row-space-between wrap "
          style={{ justifyContent: hideHints ? "flex-end" : "space-between" }}
        >
          <div
            className="card-hints"
            style={{ display: hideHints ? "none" : "" }}
          >
            <div className="leads-hints-left">
              <div className="projects-row-start-2center">
                <img
                  className="bulb"
                  src={require("../../assets/images/bulb.png")}
                />
                <b className="heading-lora">
                  Take the hassle out of administration!
                </b>
              </div>
              {auth.selectedView === "CLIENT" ? (
                <span className="text-Proxima Nova opp-grey">
                  Select an RfPP and a specific contract that you want details
                  for. You can see the contract specific phase and milestone
                  breakdown, the developers timesheets and the contract specific
                  invoices. Once you’re ready to pay your first invoice, the
                  project rebate will be applied automatically.
                </span>
              ) : (
                <span className="text-Proxima Nova opp-grey">
                  The Project page is your central repository, to easily
                  administer all the contracts that you’ve won. A GM will break
                  down the project into phases and milestones with you for
                  better transparency. All your time sheets and invoices are
                  stored and managed here.
                </span>
              )}
              {auth.selectedView === "CLIENT" ? (
                <div className="opp-arrow-out">
                  <span className="text-Proxima Nova-small blue">
                    Select RfPP{" "}
                  </span>
                  <ForwardIcon className="arrow" sx={{ height: 20 }} />
                  <span className="text-Proxima Nova-small blue">
                    Select Contract{" "}
                  </span>
                  <ForwardIcon className="arrow" sx={{ height: 20 }} />
                  <span className="text-Proxima Nova-small blue">
                    View timesheets & receive invoices
                  </span>
                </div>
              ) : (
                <div className="opp-arrow-out">
                  <span className="text-Proxima Nova-small blue">
                    Breakdown the project with a GM
                  </span>
                  <ForwardIcon className="arrow" sx={{ height: 20 }} />
                  <span className="text-Proxima Nova-small blue">
                    Upload relevant timesheets
                  </span>
                  <ForwardIcon className="arrow" sx={{ height: 20 }} />
                  <span className="text-Proxima Nova-small blue">
                    Upload invoices{" "}
                  </span>
                </div>
              )}
            </div>

            <img
              className="projects-img"
              src={require("../../assets/images/projects.png")}
            />
            {!hideHints && (
              <Tooltip title="Hide" placement="bottom" arrow>
                <RemoveCircleIcon
                  sx={{ fontSize: 30 }}
                  className="jobs-help-icon"
                  onClick={() => setHideHints(!hideHints)}
                  style={{
                    justifySelf: hideHints ? "flex-end" : "",
                  }}
                ></RemoveCircleIcon>
              </Tooltip>
            )}
          </div>

          <div
            className="separate-area"
            style={{ display: hideHints ? "none" : "" }}
          ></div>

          <div
            className="row-space-between"
            style={{ display: hideHints ? "" : "none" }}
          >
            <ProjectsHiddenHints
              hideHints={hideHints}
              setHideHints={setHideHints}
            ></ProjectsHiddenHints>
          </div>

          {/* <HelpIcon
          className="help-icon"
          onClick={() => setHideHints(!hideHints)}
          style={{ justifySelf: hideHints ? "flex-end" : "" }}
        /> */}

          <div
            className="separate-area"
            style={{ display: hideHints ? "none" : "" }}
          />
        </div>
      )}

      <div className="projects-background-grey">
        <div ref={ref}>
          <div className="projects-row-top margin-t20">
            {auth.selectedView === "CLIENT" && rfpps.length > 0 && (
              <Box className="projects-half-percent-width padding-up-down20">
                <FormControl fullWidth>
                  <InputLabel shrink={true} id="demo-simple-select-label">
                    Select Request
                  </InputLabel>
                  <Select
                    input={
                      <OutlinedInput
                        id="select-multiple-chip"
                        label="Select Request"
                        notched
                      />
                    }
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedRfpp.id || ""}
                    label="Select Request"
                    onChange={(event) => handleRfppSelect(event)}
                  >
                    {rfpps.map((rfpp: any) => {
                      return (
                        <MenuItem key={rfpp.id} value={rfpp.id}>
                          {rfpp.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>
            )}

            {auth.selectedView === "CLIENT" && rfpps.length === 0 && (
              <Box className="projects-half-percent-width padding-up-down20">
                <FormControl fullWidth>
                  <InputLabel shrink={true} id="demo-simple-select-label">
                    Select Request
                  </InputLabel>
                  <Select
                    input={
                      <OutlinedInput
                        id="select-multiple-chip"
                        label="Select Request"
                        notched
                      />
                    }
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={"You don't have any projects yet"}
                    label="SelectedProject"
                  >
                    <MenuItem value={"You don't have any projects yet"}>
                      You don't have any requests yet
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
            )}

            {filteredProjects.length > 0 && auth.selectedView === "CLIENT" && (
              <Box className="projects-half-percent-width padding-up-down20">
                <FormControl fullWidth>
                  <InputLabel shrink={true} id="demo-simple-select-label">
                    Select Contract
                  </InputLabel>
                  <Select
                    input={
                      <OutlinedInput
                        id="select-multiple-chip"
                        label="Select Contract"
                        notched
                      />
                    }
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedProject.id || ""}
                    label="SelectedProject"
                    onChange={(event) => handleProjectSelect(event)}
                  >
                    {filteredProjects.map((project: any) => {
                      return (
                        <MenuItem key={project.id} value={project.id}>
                          {project.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>
            )}
            {filteredProjects.length === 0 &&
              auth.selectedView === "CLIENT" && (
                <Box className="projects-half-percent-width padding-up-down20">
                  <FormControl fullWidth>
                    <InputLabel shrink={true} id="demo-simple-select-label">
                      Select Contract
                    </InputLabel>
                    <Select
                      input={
                        <OutlinedInput
                          id="select-multiple-chip"
                          label="Select Project"
                          notched
                        />
                      }
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={"You don't have any projects yet"}
                      label="SelectedProject"
                    >
                      <MenuItem value={"You don't have any projects yet"}>
                        You don't have any contracts yet
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              )}
            {filteredProjects.length > 0 &&
              auth.selectedView === "DEVELOPER" && (
                <Box className="projects-search-bar-contract padding-up-down20">
                  <FormControl fullWidth>
                    <InputLabel shrink={true} id="demo-simple-select-label">
                      Select Contract
                    </InputLabel>
                    <Select
                      input={
                        <OutlinedInput
                          id="select-multiple-chip"
                          label="Select Contract"
                          notched
                        />
                      }
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedProject.id || ""}
                      label="SelectedProject"
                      onChange={(event) => handleProjectSelect(event)}
                    >
                      {filteredProjects.map((project: any) => {
                        return (
                          <MenuItem key={project.id} value={project.id}>
                            {project.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>
              )}
            {filteredProjects.length === 0 &&
              auth.selectedView === "DEVELOPER" && (
                <Box className="projects-search-bar-contract padding-up-down20">
                  <FormControl fullWidth>
                    <InputLabel shrink={true} id="demo-simple-select-label">
                      Select Contract
                    </InputLabel>
                    <Select
                      input={
                        <OutlinedInput
                          id="select-multiple-chip"
                          label="Select Project"
                          notched
                        />
                      }
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={"You don't have any projects yet"}
                      label="SelectedProject"
                    >
                      <MenuItem value={"You don't have any projects yet"}>
                        You don't have any contracts yet
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              )}
          </div>
          {selectedRfpp &&
            selectedProject.name &&
            updateTimeline &&
            window.innerWidth >= 800 && (
              <div className="separate-area">
                <PhasesTimeline
                  handleClickMilestone={handleClickMilestone}
                  handleClickPhase={handleClickPhase}
                  phases={selectedProject.phases}
                  milestones={selectedProject.milestones}
                  width={width}
                />
              </div>
            )}
          <div className="white-hack">
            {selectedProject && selectedProject.name && (
              <ProjectCard job={selectedProject} hotSkills={hotSkills} />
            )}
            <div className="light-orange margin-t20">
              <div className="row-start wrap ">
                <div
                  className="kpi-progect-nav"
                  style={{
                    borderBottom: !phasesClicked ? "none" : "2px solid #FF6210",
                    color: !phasesClicked ? "#A6A6A6" : "black",
                  }}
                  onClick={handlePhasesTabClick}
                >
                  <div className="row-space-between">
                    <span>Phases </span>
                  </div>
                </div>
                <div
                  className="kpi-progect-nav"
                  style={{
                    borderBottom: !milestonesClicked
                      ? "none"
                      : "2px solid #FF6210",
                    color: !milestonesClicked ? "#A6A6A6" : "black",
                  }}
                  onClick={handleMilestonesTabClick}
                >
                  <div className="row-space-between">
                    <span>Milestones </span>
                  </div>
                </div>
                {auth.selectedView === "DEVELOPER" && (
                  <div
                    className="kpi-progect-nav"
                    style={{
                      borderBottom: !reportsClicked
                        ? "none"
                        : "2px solid #FF6210",
                      color: !reportsClicked ? "#A6A6A6" : "black",
                    }}
                    onClick={handleReportsTabClick}
                  >
                    <div className="row-space-between">
                      <span>Reports </span>
                    </div>
                  </div>
                )}
                <div
                  className="kpi-progect-nav"
                  style={{
                    borderBottom: !timesheetsClicked
                      ? "none"
                      : "2px solid #FF6210",
                    color: !timesheetsClicked ? "#A6A6A6" : "black",
                  }}
                  onClick={handleTimesheetsTabClick}
                >
                  <div className="row-space-between">
                    <span>Timesheets </span>
                  </div>
                </div>
                {showInvoices && (
                  <div
                    className="kpi-progect-nav"
                    style={{
                      borderBottom: !invoicesClicked
                        ? "none"
                        : "2px solid #FF6210",
                      color: !invoicesClicked ? "#A6A6A6" : "black",
                    }}
                    onClick={handleInvoicesTabClick}
                  >
                    <div className="row-space-between">
                      <span>Invoices </span>
                    </div>
                  </div>
                )}
              </div>

              {contractsClicked && (
                <div className="column-start">
                  <div
                    className="kpi-card-leads kpi-margin"
                    style={{
                      border: !acceptedContractsClicked
                        ? "0px solid grey"
                        : "1px solid #FF6210",
                      boxShadow: !acceptedContractsClicked
                        ? "0px 2px 6px rgba(0, 0, 0, 0.16)"
                        : "none",
                    }}
                    onClick={handleAcceptedContractsClick}
                  >
                    <CheckIcon style={{ color: "#fff" }} />
                    <strong className="text-Proxima Nova700-small darkgrey">
                      Accepted Contracts
                    </strong>
                    <span className="grey-text"> {numAcceptedContracts}</span>
                  </div>
                  <div
                    className="kpi-card-leads kpi-margin"
                    style={{
                      boxShadow: !alignmentContractsClicked
                        ? "rgb(179, 177, 177) -5px 5px 5px 1px"
                        : "rgb(179, 177, 177) -1px 1px 5px 1px",
                    }}
                    onClick={handleAlignmentContractsClick}
                  >
                    <div className="row-space-between">
                      <div className="column-center-start">
                        <strong> Contracts in alignment</strong>
                        <strong>{numAlignmentContracts}</strong>
                      </div>
                      <div className="kpi-background-started">
                        <AccessTimeIcon style={{ color: "#fff" }} />
                      </div>
                    </div>
                  </div>

                  <div
                    className="kpi-card-leads kpi-margin"
                    style={{
                      boxShadow: !newContractsClicked
                        ? "rgb(179, 177, 177) -5px 5px 5px 1px"
                        : "rgb(179, 177, 177) -1px 1px 5px 1px",
                    }}
                    onClick={handleNewContractsClick}
                  >
                    <div className="row-space-between">
                      <div className="column-center-start">
                        <strong>New Contracts</strong>
                        <strong>{numNewContracts}</strong>
                      </div>
                      <div className="kpi-background-accepted">
                        <ThumbDownIcon style={{ color: "#fff" }} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {phasesClicked && (
                <div>
                  {window.innerWidth >= 1200 ? (
                    <div className="projects-row-start">
                      <div
                        className="kpi-card-projects kpi-margin"
                        style={{
                          border: !newPhasesClicked
                            ? "0px solid grey"
                            : "1px solid #FF6210",
                          boxShadow: !newPhasesClicked
                            ? "0px 2px 6px rgba(0, 0, 0, 0.16)"
                            : "none",
                        }}
                        onClick={handleNewPhasesClick}
                      >
                        <AddIcon className="margin-kpi-projects req-green" />
                        <strong className="text-Proxima Nova700-small darkgrey nowrap">
                          New phases
                        </strong>
                        <div className="kpi-row">
                          <span className="grey-text-margin">
                            {" "}
                            {"( " + numNewPhases.toString() + " )"}
                          </span>
                        </div>
                      </div>
                      <div
                        className="kpi-card-projects kpi-margin"
                        style={{
                          border: !inProgressPhasesClicked
                            ? "0px solid grey"
                            : "1px solid #FF6210",
                          boxShadow: !inProgressPhasesClicked
                            ? "0px 2px 6px rgba(0, 0, 0, 0.16)"
                            : "none",
                        }}
                        onClick={handleInProgressPhasesClick}
                      >
                        <HourglassEmptyIcon className="margin-kpi-projects req-turquoise" />
                        <strong className="text-Proxima Nova700-small darkgrey nowrap">
                          Phases in progress
                        </strong>
                        <div className="kpi-row">
                          <span className="grey-text-margin">
                            {" "}
                            {"( " + numInProgressPhases.toString() + " )"}
                          </span>
                        </div>
                      </div>
                      <div
                        className="kpi-card-projects kpi-margin"
                        style={{
                          border: !okPhasesClicked
                            ? "0px solid grey"
                            : "1px solid #FF6210",
                          boxShadow: !okPhasesClicked
                            ? "0px 2px 6px rgba(0, 0, 0, 0.16)"
                            : "none",
                        }}
                        onClick={handleOkPhasesClick}
                      >
                        <ThumbUpOffAltIcon className="margin-kpi-projects req-purple" />
                        <strong className="text-Proxima Nova700-small darkgrey nowrap">
                          Ok phases
                        </strong>
                        <div className="kpi-row">
                          <span className="grey-text-margin">
                            {" "}
                            {"( " + numOkPhases.toString() + " )"}
                          </span>
                        </div>
                      </div>
                      <div
                        className="kpi-card-projects"
                        style={{
                          border: !breachedPhasesClicked
                            ? "0px solid grey"
                            : "1px solid #FF6210",
                          boxShadow: !breachedPhasesClicked
                            ? "0px 2px 6px rgba(0, 0, 0, 0.16)"
                            : "none",
                        }}
                        onClick={handleBreachedPhasesClick}
                      >
                        <DoDisturbIcon className="margin-kpi-projects req-red" />
                        <strong className="text-Proxima Nova700-small darkgrey nowrap">
                          Breached phases
                        </strong>
                        <div className="kpi-row">
                          <span className="grey-text-margin">
                            {" "}
                            {"( " + numBreachedPhases.toString() + " )"}
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <Box className="full-percent-width">
                      <FormControl fullWidth>
                        <InputLabel shrink={true} id="demo-simple-select-label">
                          Filter Phases
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={mobileFilterPhases}
                          onChange={(event) => {
                            handleChangeMobileFilterPhases(event.target.value);
                          }}
                          input={
                            <OutlinedInput
                              id="select-multiple-chip"
                              label="Filter Phases"
                              notched
                            />
                          }
                        >
                          <MenuItem value={"ALL"}>
                            <ClearAllIcon className="margin-r3" />
                            All Phases
                          </MenuItem>
                          <MenuItem value={"NEW"}>
                            {" "}
                            <AddIcon className="margin-r3 req-green" />
                            New Phases {"( " + numNewPhases.toString() + " )"}
                          </MenuItem>
                          <MenuItem value={"IN_PROGRESS"}>
                            {" "}
                            <HourglassEmptyIcon className="margin-r3 req-turquoise" />
                            Phases in Progress{" "}
                            {"( " + numInProgressPhases.toString() + " )"}
                          </MenuItem>
                          <MenuItem value={"OK"}>
                            {" "}
                            <ThumbUpOffAltIcon className="margin-r3 req-purple" />
                            OK Phases {"( " + numOkPhases.toString() + " )"}
                          </MenuItem>
                          <MenuItem value={"BREACHED"}>
                            {" "}
                            <DoDisturbIcon className="margin-r3 req-red" />
                            Breached Phases{" "}
                            {"( " + numBreachedPhases.toString() + " )"}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  )}
                </div>
              )}
              {milestonesClicked && (
                <div>
                  {window.innerWidth >= 1316 ? (
                    <div className="projects-row-start wrap">
                      <div
                        className="kpi-card-projects kpi-margin"
                        style={{
                          border: !newMilestonesClicked
                            ? "0px solid grey"
                            : "1px solid #FF6210",
                          boxShadow: !newMilestonesClicked
                            ? "0px 2px 6px rgba(0, 0, 0, 0.16)"
                            : "none",
                        }}
                        onClick={handleNewMilestonesClick}
                      >
                        <AddIcon className="margin-kpi-projects req-green" />
                        <strong className="text-Proxima Nova700-small darkgrey">
                          New Milestones
                        </strong>
                        <span className="grey-text-margin">
                          {" "}
                          {"( " + numNewMilestones.toString() + " )"}
                        </span>
                      </div>

                      <div
                        className="kpi-card-projects kpi-margin"
                        style={{
                          border: !inProgressMilestonesClicked
                            ? "0px solid grey"
                            : "1px solid #FF6210",
                          boxShadow: !inProgressMilestonesClicked
                            ? "0px 2px 6px rgba(0, 0, 0, 0.16)"
                            : "none",
                        }}
                        onClick={handleInProgressMilestonesClick}
                      >
                        <HourglassEmptyIcon className="margin-kpi-projects req-turquoise" />
                        <strong className="text-Proxima Nova700-small darkgrey">
                          Milestones in progress
                        </strong>
                        <span className="grey-text-margin">
                          {" "}
                          {"( " + numInProgressMilestones.toString() + " )"}
                        </span>
                      </div>

                      <div
                        className="kpi-card-projects kpi-margin"
                        style={{
                          border: !okMilestonesClicked
                            ? "0px solid grey"
                            : "1px solid #FF6210",
                          boxShadow: !okMilestonesClicked
                            ? "0px 2px 6px rgba(0, 0, 0, 0.16)"
                            : "none",
                        }}
                        onClick={handleOkMilestonesClick}
                      >
                        <ThumbUpOffAltIcon className="margin-kpi-projects req-purple" />
                        <strong className="text-Proxima Nova700-small darkgrey">
                          Ok Milestones
                        </strong>
                        <span className="grey-text-margin">
                          {" "}
                          {"( " + numOkMilestones.toString() + " )"}
                        </span>
                      </div>
                      <div
                        className="kpi-card-projects kpi-margin"
                        style={{
                          border: !breachedMilestonesClicked
                            ? "0px solid grey"
                            : "1px solid #FF6210",
                          boxShadow: !breachedMilestonesClicked
                            ? "0px 2px 6px rgba(0, 0, 0, 0.16)"
                            : "none",
                        }}
                        onClick={handleBreachedMilestonesClick}
                      >
                        <DoDisturbIcon className="margin-kpi-projects req-red" />
                        <strong className="text-Proxima Nova700-small darkgrey">
                          Breached Milestones
                        </strong>
                        <span className="grey-text-margin">
                          {" "}
                          {"( " + numBreachedMilestones.toString() + " )"}
                        </span>
                      </div>
                    </div>
                  ) : (
                    <Box className="full-percent-width">
                      <FormControl fullWidth>
                        <InputLabel shrink={true} id="demo-simple-select-label">
                          Filter Milestones
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={mobileFilterMilestones}
                          onChange={(event) => {
                            handleChangeMobileFilterMilestones(
                              event.target.value
                            );
                          }}
                          input={
                            <OutlinedInput
                              id="select-multiple-chip"
                              label="Filter Milestones"
                              notched
                            />
                          }
                        >
                          <MenuItem value={"ALL"}>
                            <ClearAllIcon className="margin-r3" />
                            All Milestones
                          </MenuItem>
                          <MenuItem value={"NEW"}>
                            {" "}
                            <AddIcon className="margin-r3 req-green" />
                            New Milestones{" "}
                            {"( " + numNewMilestones.toString() + " )"}
                          </MenuItem>
                          <MenuItem value={"IN_PROGRESS"}>
                            {" "}
                            <HourglassEmptyIcon className="margin-r3 req-turquoise" />
                            Milestones in Progress{" "}
                            {"( " + numInProgressMilestones.toString() + " )"}
                          </MenuItem>
                          <MenuItem value={"OK"}>
                            {" "}
                            <ThumbUpOffAltIcon className="margin-r3 req-purple" />
                            OK Milestones{" "}
                            {"( " + numOkMilestones.toString() + " )"}
                          </MenuItem>
                          <MenuItem value={"BREACHED"}>
                            {" "}
                            <DoDisturbIcon className="margin-r3 req-red" />
                            Breached Milestones{" "}
                            {"( " + numBreachedMilestones.toString() + " )"}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  )}
                </div>
              )}
              {reportsClicked && (
                <div className="reports-row">
                  <div className="report-card">
                    <span className="text-Proxima Nova700 opp-grey">
                      {" "}
                      Weekly Average{" "}
                    </span>
                    {weekReport !== 0 ? (
                      <img
                        className="image-reports"
                        src={
                          weekReport === 1
                            ? require("../../assets/images/angry.png")
                            : weekReport > 1 && weekReport < 2.5
                            ? require("../../assets/images/sad.png")
                            : weekReport >= 2.5 && weekReport < 3.5
                            ? require("../../assets/images/neutral.png")
                            : weekReport >= 3.5 && weekReport < 4.5
                            ? require("../../assets/images/happy.png")
                            : weekReport >= 4.5 &&
                              require("../../assets/images/excited.png")
                        }
                      />
                    ) : (
                      <span className="opp-status">
                        There are no reports for this period
                      </span>
                    )}
                  </div>

                  <div className="report-card">
                    <span className="text-Proxima Nova700 opp-grey">
                      {" "}
                      Monthly Average{" "}
                    </span>

                    {monthReport !== 0 ? (
                      <img
                        className="image-reports"
                        src={
                          monthReport === 1
                            ? require("../../assets/images/angry.png")
                            : monthReport > 1 && monthReport < 2.5
                            ? require("../../assets/images/sad.png")
                            : monthReport >= 2.5 && monthReport < 3.5
                            ? require("../../assets/images/neutral.png")
                            : monthReport >= 3.5 && monthReport < 4.5
                            ? require("../../assets/images/happy.png")
                            : monthReport >= 4.5 &&
                              require("../../assets/images/excited.png")
                        }
                      />
                    ) : (
                      <span className="opp-status">
                        There are no reports for this period
                      </span>
                    )}
                  </div>

                  <div className="report-card">
                    <span className="text-Proxima Nova700 opp-grey">
                      {" "}
                      All Time Average{" "}
                    </span>
                    {allTimeReport !== 0 ? (
                      <img
                        className="image-reports"
                        src={
                          allTimeReport === 1
                            ? require("../../assets/images/angry.png")
                            : allTimeReport > 1 && allTimeReport < 2.5
                            ? require("../../assets/images/sad.png")
                            : allTimeReport >= 2.5 && allTimeReport < 3.5
                            ? require("../../assets/images/neutral.png")
                            : allTimeReport >= 3.5 && allTimeReport < 4.5
                            ? require("../../assets/images/happy.png")
                            : allTimeReport >= 4.5 &&
                              require("../../assets/images/excited.png")
                        }
                      />
                    ) : (
                      <span className="opp-status">
                        There are no reports for this period
                      </span>
                    )}
                  </div>
                </div>
              )}
              {timesheetsClicked && auth.selectedView === "DEVELOPER" && (
                <div>
                  {window.innerWidth >= 1200 ? (
                    <div className="projects-row-start wrap">
                      <div
                        className="kpi-card-projects kpi-margin"
                        style={{
                          border: !okTimesheetsClicked
                            ? "0px solid grey"
                            : "1px solid #FF6210",
                          boxShadow: !okTimesheetsClicked
                            ? "0px 2px 6px rgba(0, 0, 0, 0.16)"
                            : "none",
                        }}
                        onClick={handleOkTimesheetsClick}
                      >
                        <ThumbUpOffAltIcon className="margin-kpi-projects req-purple" />
                        <strong className="text-Proxima Nova700-small darkgrey">
                          Ok Timesheets
                        </strong>
                        <div className="kpi-row">
                          <span className="grey-text-margin">
                            {" "}
                            {"( " + numOkTimesheets.toString() + " )"}
                          </span>
                        </div>
                      </div>

                      <div
                        className="kpi-card-projects kpi-margin"
                        style={{
                          border: !newTimesheetsClicked
                            ? "0px solid grey"
                            : "1px solid #FF6210",
                          boxShadow: !newTimesheetsClicked
                            ? "0px 2px 6px rgba(0, 0, 0, 0.16)"
                            : "none",
                        }}
                        onClick={handleNewTimesheetsClick}
                      >
                        <AddIcon className="margin-kpi-projects req-green" />
                        <strong className="text-Proxima Nova700-small darkgrey">
                          New Timesheets
                        </strong>
                        <div className="kpi-row">
                          <span className="grey-text-margin">
                            {" "}
                            {"( " + numNewTimesheets.toString() + " )"}
                          </span>
                        </div>
                      </div>

                      <div
                        className="kpi-card-projects kpi-margin"
                        style={{
                          border: !rejectedTimesheetsClicked
                            ? "0px solid grey"
                            : "1px solid #FF6210",
                          boxShadow: !rejectedTimesheetsClicked
                            ? "0px 2px 6px rgba(0, 0, 0, 0.16)"
                            : "none",
                        }}
                        onClick={handleRejectedTimesheetsClick}
                      >
                        <DoDisturbIcon className="margin-kpi-projects req-red" />
                        <strong className="text-Proxima Nova700-small darkgrey">
                          Rejected Timesheets
                        </strong>
                        <div className="kpi-row">
                          <span className="grey-text-margin">
                            {" "}
                            {"( " + numRejectedTimesheets.toString() + " )"}
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <Box className="full-percent-width">
                      <FormControl fullWidth>
                        <InputLabel shrink={true} id="demo-simple-select-label">
                          Filter Timesheets
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={mobileFilterTimesheets}
                          onChange={(event) => {
                            handleChangeMobileFilterTimesheets(
                              event.target.value
                            );
                          }}
                          input={
                            <OutlinedInput
                              id="select-multiple-chip"
                              label="Filter Timesheets"
                              notched
                            />
                          }
                        >
                          <MenuItem value={"ALL"}>
                            <ClearAllIcon className="margin-r3" />
                            All Timesheets
                          </MenuItem>
                          <MenuItem value={"OK"}>
                            {" "}
                            <ThumbUpOffAltIcon className="margin-r3 req-purple" />
                            OK Timesheets{" "}
                            {"( " + numOkTimesheets.toString() + " )"}
                          </MenuItem>
                          <MenuItem value={"NEW"}>
                            {" "}
                            <AddIcon className="margin-r3 req-green" />
                            New Timesheets{" "}
                            {"( " + numNewTimesheets.toString() + " )"}
                          </MenuItem>
                          <MenuItem value={"REJECTED"}>
                            {" "}
                            <DoDisturbIcon className="margin-r3 req-red" />
                            Rejected Timesheets{" "}
                            {"( " + numRejectedTimesheets.toString() + " )"}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  )}
                </div>
              )}
              {invoicesClicked && (
                <div>
                  {window.innerWidth >= 1200 ? (
                    <div className="projects-row-start wrap">
                      <div
                        className="kpi-card-projects kpi-margin"
                        style={{
                          border: !paidInvoicesClicked
                            ? "0px solid grey"
                            : "1px solid #FF6210",
                          boxShadow: !paidInvoicesClicked
                            ? "0px 2px 6px rgba(0, 0, 0, 0.16)"
                            : "none",
                        }}
                        onClick={handlePaidInvoicesClick}
                      >
                        <ThumbUpOffAltIcon className="margin-kpi-projects req-purple" />
                        <strong className="text-Proxima Nova700-small darkgrey">
                          Paid Invoices
                        </strong>
                        <span className="grey-text-margin">
                          {" "}
                          {"( " + numPaidInvoices.toString() + " )"}
                        </span>
                      </div>

                      <div
                        className="kpi-card-projects kpi-margin"
                        style={{
                          border: !newInvoicesClicked
                            ? "0px solid grey"
                            : "1px solid #FF6210",
                          boxShadow: !newInvoicesClicked
                            ? "0px 2px 6px rgba(0, 0, 0, 0.16)"
                            : "none",
                        }}
                        onClick={handleNewInvoicesClick}
                      >
                        <AddIcon className="margin-kpi-projects req-green" />
                        <strong className="text-Proxima Nova700-small darkgrey">
                          New Invoices
                        </strong>
                        <span className="grey-text-margin">
                          {" "}
                          {"( " + numNewInvoices.toString() + " )"}
                        </span>
                      </div>
                      <div
                        className="kpi-card-projects kpi-margin"
                        style={{
                          border: !cancelledInvoicesClicked
                            ? "0px solid grey"
                            : "1px solid #FF6210",
                          boxShadow: !cancelledInvoicesClicked
                            ? "0px 2px 6px rgba(0, 0, 0, 0.16)"
                            : "none",
                        }}
                        onClick={handleCancelledInvoicesClick}
                      >
                        <DoDisturbIcon className="margin-kpi-projects req-red" />
                        <strong className="text-Proxima Nova700-small darkgrey">
                          Cancelled Invoices
                        </strong>
                        <span className="grey-text-margin">
                          {" "}
                          {"( " + numCancelledInvoices.toString() + " )"}
                        </span>
                      </div>
                    </div>
                  ) : (
                    <Box className="full-percent-width">
                      <FormControl fullWidth>
                        <InputLabel shrink={true} id="demo-simple-select-label">
                          Filter Invoices
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={mobileFilterInvoices}
                          onChange={(event) => {
                            handleChangeMobileFilterInvoices(
                              event.target.value
                            );
                          }}
                          input={
                            <OutlinedInput
                              id="select-multiple-chip"
                              label="Filter Invoices"
                              notched
                            />
                          }
                        >
                          <MenuItem value={"ALL"}>
                            {" "}
                            <ClearAllIcon className="margin-r3" />
                            All Invoices
                          </MenuItem>
                          <MenuItem value={"PAID"}>
                            {" "}
                            <ThumbUpOffAltIcon className="margin-r3 req-purple" />
                            Paid Invoices{" "}
                            {"( " + numPaidInvoices.toString() + " )"}
                          </MenuItem>
                          <MenuItem value={"NEW"}>
                            {" "}
                            <AddIcon className="margin-r3 req-green" />
                            New Invoices{" "}
                            {"( " + numNewInvoices.toString() + " )"}
                          </MenuItem>
                          <MenuItem value={"CANCELLED"}>
                            {" "}
                            <DoDisturbIcon className="margin-r3 req-red" />
                            Cancelled Invoices{" "}
                            {"( " + numCancelledInvoices.toString() + " )"}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  )}
                </div>
              )}

              {auth.selectedView === "DEVELOPER" && phasesClicked && (
                <button
                  className="text-Proxima Nova700-small blue"
                  onClick={() => setShowAddPhasePopup(true)}
                  disabled={filteredProjects.length === 0}
                  style={{
                    marginBottom: 20,
                    marginTop: 20,
                    opacity: filteredProjects.length === 0 ? 0.4 : 1,
                  }}
                >
                  {" "}
                  + Add New Phase
                </button>
              )}
              {auth.selectedView === "DEVELOPER" && reportsClicked && (
                <button
                  className="text-Proxima Nova700-small blue"
                  disabled={filteredProjects.length === 0}
                  style={{
                    marginBottom: 20,
                    marginTop: 20,
                    opacity: filteredProjects.length === 0 ? 0.4 : 1,
                  }}
                  onClick={() => setShowAddReportPopup(true)}
                >
                  {" "}
                  + Add New Report
                </button>
              )}
              {auth.selectedView === "DEVELOPER" && timesheetsClicked && (
                <button
                  className="text-Proxima Nova700-small blue"
                  disabled={filteredProjects.length === 0}
                  style={{
                    marginBottom: 20,
                    marginTop: 20,
                    opacity: filteredProjects.length === 0 ? 0.4 : 1,
                  }}
                  onClick={() => setShowAddTimesheetPopup(true)}
                >
                  {" "}
                  + Add New Timesheet
                </button>
              )}
              {auth.selectedView === "DEVELOPER" && milestonesClicked && (
                <button
                  className="text-Proxima Nova700-small blue"
                  disabled={filteredProjects.length === 0}
                  style={{
                    marginBottom: 20,
                    marginTop: 20,
                    opacity: filteredProjects.length === 0 ? 0.4 : 1,
                  }}
                  onClick={() => setShowAddMilestonePopup(true)}
                >
                  {" "}
                  + Add New Milestone
                </button>
              )}
              {auth.selectedView === "DEVELOPER" && invoicesClicked && (
                <button
                  className="text-Proxima Nova700-small blue"
                  disabled={filteredProjects.length === 0}
                  style={{
                    marginBottom: 20,
                    marginTop: 20,
                    opacity: filteredProjects.length === 0 ? 0.4 : 1,
                  }}
                  onClick={() => setShowAddInvoicePopup(true)}
                >
                  {" "}
                  + Add New Invoice
                </button>
              )}
              {selectedTimesheet !== null &&
                selectedProject &&
                selectedProject.id &&
                showAddTimesheetPopup && (
                  <AddTimesheet
                    show={showAddTimesheetPopup}
                    onClose={handleCloseAddTimesheet}
                    timesheet={selectedTimesheet}
                    contract_id={selectedProject.id}
                    contract={selectedProject}
                  />
                )}
              {selectedTimesheet === null &&
                selectedProject &&
                selectedProject.id &&
                showAddTimesheetPopup && (
                  <AddTimesheet
                    show={showAddTimesheetPopup}
                    onClose={handleCloseAddTimesheet}
                    contract_id={selectedProject.id}
                    contract={selectedProject}
                  />
                )}
              {selectedProject && selectedProject.id && showAddInvoicePopup && (
                <AddInvoice
                  show={showAddInvoicePopup}
                  onClose={handleCloseAddInvoice}
                  contract_id={selectedProject.id}
                />
              )}
              {showAddPhasePopup &&
                selectedPhaseId !== null &&
                selectedProject &&
                selectedProject.id && (
                  <AddPhase
                    show={showAddPhasePopup}
                    onClose={handleCloseAddPhase}
                    project_id={selectedProject.id}
                    selected_project={selectedProject}
                    phase_id={selectedPhaseId}
                    phases={selectedProject.phases}
                  />
                )}
              {selectedProject && selectedProject.id && showAddReportPopup && (
                <AddReport
                  show={showAddReportPopup}
                  onClose={handleCloseAddReport}
                  project_id={selectedProject.id}
                  selected_project={selectedProject}
                />
              )}
              {showAddPhasePopup &&
                selectedPhaseId === null &&
                selectedProject &&
                selectedProject.id && (
                  <AddPhase
                    show={showAddPhasePopup}
                    onClose={handleCloseAddPhase}
                    selected_project={selectedProject}
                    project_id={selectedProject.id}
                    phases={selectedProject.phases}
                  />
                )}
              {selectedMilestoneId !== null &&
                selectedProject &&
                selectedProject.id &&
                showAddMilestonePopup && (
                  <AddMilestone
                    show={showAddMilestonePopup}
                    onClose={handleCloseAddMilestone}
                    project_id={selectedProject.id}
                    selected_project={selectedProject}
                    milestone_id={selectedMilestoneId}
                    milestones={selectedProject.milestones}
                  />
                )}

              {selectedMilestoneId === null &&
                selectedProject &&
                selectedProject.id &&
                showAddMilestonePopup && (
                  <AddMilestone
                    show={showAddMilestonePopup}
                    onClose={handleCloseAddMilestone}
                    project_id={selectedProject.id}
                    selected_project={selectedProject}
                  />
                )}
            </div>
          </div>
          <div className="separate-area">
            {timesheetsClicked &&
              selectedProject &&
              selectedProject.job &&
              selectedProject.project_days && (
                <div className="grey-background">
                  <div className="row-start margin-r10">
                    <span className="opp-status">
                      Total Project Days: {selectedProject.project_days}
                    </span>
                    {window.innerWidth > 1200 && <div className="ball"></div>}
                    <span className="opp-status">
                      Remaining Project Days:{" "}
                      {selectedProject.project_days -
                        selectedProject.hours_spent / 8}
                    </span>
                  </div>
                </div>
              )}
            {reportsClicked && (
              <div className="opp-boxes column-center ">
                <div className="projects-searchBar">
                  <SearchIcon />
                  <input
                    type={"text"}
                    value={searchReportString}
                    placeholder={"Search"}
                    onChange={(event) =>
                      setSearchReportString(event.target.value)
                    }
                    className="searchInput"
                  />
                </div>
                <div className="projects-row-center padding-up-down20">
                  <label className="escalated-row pointer text-Proxima Nova-small">
                    Escalated
                    <input
                      type="checkbox"
                      className="selectCategory"
                      checked={searchReportEscalate}
                      onChange={() =>
                        setSearchReportEscalate(!searchReportEscalate)
                      }
                    />
                  </label>

                  <Box className="selectProjects padding-up-down">
                    <FormControl fullWidth>
                      <InputLabel shrink={true} id="select-multiple-chip">
                        Rate
                      </InputLabel>
                      <Select
                        input={
                          <OutlinedInput
                            id="select-multiple-chip"
                            label="Rate"
                            notched
                          />
                        }
                        id="select-multiple-chip"
                        value={searchReportRate}
                        onChange={(event) =>
                          setSearchReportRate(event.target.value)
                        }
                      >
                        <MenuItem value={""} disabled>
                          Rate
                        </MenuItem>
                        <MenuItem value={"ANGRY"}>Angry</MenuItem>
                        <MenuItem value={"SAD"}>Sad</MenuItem>
                        <MenuItem value={"NEUTRAL"}>Neytral</MenuItem>
                        <MenuItem value={"HAPPY"}>Happy</MenuItem>
                        <MenuItem value={"EXCITED"}>Excited</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <LocalizationProvider
                    dateAdapter={AdapterMoment}
                    adapterLocale="en-gb"
                  >
                    <Box className="selectProjects padding-up-down">
                      <FormControl fullWidth>
                        <DatePicker
                          inputFormat="DD/MM/YYYY"
                          value={searchReportStartDate || null}
                          onChange={(newValue) => {
                            if (newValue) {
                              setSearchReportStartDate(newValue?.toString());
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              InputLabelProps={{ shrink: true }}
                              label="From Date"
                            />
                          )}
                        />
                      </FormControl>
                    </Box>
                    <Box className="selectProjects padding-up-down">
                      <FormControl fullWidth>
                        <DatePicker
                          inputFormat="DD/MM/YYYY"
                          value={searchReportEndDate || null}
                          minDate={moment(searchReportStartDate)}
                          onChange={(newValue) => {
                            if (newValue) {
                              setSearchReportEndDate(newValue?.toString());
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              InputLabelProps={{ shrink: true }}
                              label="To Date"
                            />
                          )}
                        />
                      </FormControl>
                    </Box>
                  </LocalizationProvider>
                </div>
                <div className="searchButtons padding-up-down20">
                  <button
                    className="button-black-small text-Proxima Nova700-small"
                    onClick={handleSearchReport}
                  >
                    Search
                  </button>
                  <button
                    className="button-white-small text-Proxima Nova700-small"
                    onClick={clearAllSearchReport}
                  >
                    Clear All
                  </button>
                </div>
              </div>
            )}
            {reportsClicked && reports.length > 0 && (
              <div>
                {reports.map((report: any) => {
                  return <ReportCard key={report.id} report={report} />;
                })}
              </div>
            )}
            {contractsClicked && contracts.length > 0 ? (
              <div className=" row-space-between wrap">
                {contracts.map((job: any) => {
                  return <ContractCard key={job.id} job={job} />;
                })}
              </div>
            ) : contractsClicked &&
              contracts.length === 0 &&
              selectedProject &&
              selectedProject.jobs ? (
              <div className=" row-space-between wrap">
                {selectedProject.jobs.map((job: any) => {
                  return <ContractCard key={job.id} job={job} />;
                })}
              </div>
            ) : phasesClicked &&
              phases.length === 0 &&
              selectedProject &&
              selectedProject.phases ? (
              <div className="projects-phase-card-row wrap">
                {selectedProject.phases.map((phase: any) => {
                  const itemProps =
                    showHighlight && phase.id === selectedPhaseId
                      ? { ref: scrollToRef }
                      : {};
                  return (
                    <div
                      className="projects-phaescard-half"
                      key={phase.id}
                      {...itemProps}
                      onClick={() => setShowHighlight(false)}
                    >
                      <PhaseCard
                        highlight={
                          showHighlight && selectedPhaseId === phase.id
                        }
                        key={phase.id}
                        phase={phase}
                        handleEdit={handleEditPhase}
                        getProjects={getProjects}
                      />
                    </div>
                  );
                })}
              </div>
            ) : phasesClicked && phases.length > 0 ? (
              <div className="projects-phase-card-row ">
                {phases.map((phase: any) => {
                  const itemProps =
                    showHighlight && phase.id === selectedPhaseId
                      ? { ref: scrollToRef }
                      : {};
                  return (
                    <div
                      className="projects-phaescard-half"
                      key={phase.id}
                      {...itemProps}
                      onClick={() => setShowHighlight(false)}
                    >
                      <PhaseCard
                        highlight={
                          showHighlight && selectedPhaseId === phase.id
                        }
                        key={phase.id}
                        phase={phase}
                        handleEdit={handleEditPhase}
                        getProjects={getProjects}
                      />
                    </div>
                  );
                })}
              </div>
            ) : milestonesClicked &&
              milestones.length === 0 &&
              selectedProject &&
              selectedProject.milestones ? (
              <div className="projects-phase-card-row  wrap">
                {selectedProject.milestones.map((milestone: any) => {
                  const itemProps =
                    showHighlight && milestone.id === selectedMilestoneId
                      ? { ref: scrollToRef }
                      : {};

                  return (
                    <div
                      className="projects-phaescard-half"
                      key={milestone.id}
                      {...itemProps}
                      onClick={() => setShowHighlight(false)}
                    >
                      <MilestoneCard
                        highlight={
                          showHighlight && selectedMilestoneId === milestone.id
                        }
                        handleEdit={handleEditMilestone}
                        key={milestone.id}
                        milestone={milestone}
                        getProjects={getProjects}
                      />
                    </div>
                  );
                })}
              </div>
            ) : milestonesClicked && milestones.length > 0 ? (
              <div className="projects-phase-card-row ">
                {milestones.map((milestone: any) => {
                  const itemProps =
                    showHighlight && milestone.id === selectedMilestoneId
                      ? { ref: scrollToRef }
                      : {};
                  return (
                    <div
                      className="projects-phaescard-half "
                      key={milestone.id}
                      {...itemProps}
                      onClick={() => setShowHighlight(false)}
                    >
                      <MilestoneCard
                        highlight={
                          showHighlight && selectedMilestoneId === milestone.id
                        }
                        handleEdit={handleEditMilestone}
                        key={milestone.id}
                        milestone={milestone}
                        getProjects={getProjects}
                      />
                    </div>
                  );
                })}
              </div>
            ) : timesheetsClicked &&
              timesheets.length === 0 &&
              selectedProject &&
              selectedProject.timesheets ? (
              <div className=" projects-phase-card-row wrap">
                {selectedProject.timesheets.map((timesheet: any) => {
                  if (auth.selectedView === "CLIENT") {
                    if (timesheet.status === "OK") {
                      const itemProps =
                        auth.selectedSubgroup === "TIMESHEET" &&
                        auth.selectedSubgroupId === timesheet.id
                          ? { ref: scrollToRef }
                          : {};
                      return (
                        <div
                          className="projects-phaescard-half"
                          key={timesheet.id}
                          {...itemProps}
                          onClick={clearSelected}
                        >
                          <TimesheetCard
                            highlight={auth.selectedSubgroupId === timesheet.id}
                            key={timesheet.id}
                            timesheet={timesheet}
                            getProjects={getProjects}
                          />
                        </div>
                      );
                    }
                  } else {
                    const itemProps =
                      auth.selectedSubgroup === "TIMESHEET" &&
                      auth.selectedSubgroupId === timesheet.id
                        ? { ref: scrollToRef }
                        : {};
                    return (
                      <div
                        className="projects-phaescard-half"
                        key={timesheet.id}
                        {...itemProps}
                        onClick={clearSelected}
                      >
                        <TimesheetCard
                          highlight={auth.selectedSubgroupId === timesheet.id}
                          key={timesheet.id}
                          timesheet={timesheet}
                          getProjects={getProjects}
                        />
                      </div>
                    );
                  }
                })}
              </div>
            ) : timesheetsClicked && timesheets.length > 0 ? (
              <div className=" projects-phase-card-row">
                {timesheets.map((timesheet: any) => {
                  const itemProps =
                    auth.selectedSubgroup === "TIMESHEET" &&
                    auth.selectedSubgroupId === timesheet.id
                      ? { ref: scrollToRef }
                      : {};
                  return (
                    <div
                      className="projects-phaescard-half"
                      key={timesheet.id}
                      {...itemProps}
                      onClick={clearSelected}
                    >
                      <TimesheetCard
                        highlight={auth.selectedSubgroupId === timesheet.id}
                        key={timesheet.id}
                        timesheet={timesheet}
                        getProjects={getProjects}
                      />
                    </div>
                  );
                })}
              </div>
            ) : invoicesClicked &&
              invoices.length === 0 &&
              selectedProject &&
              selectedProject.timesheets ? (
              <div className=" row-space-between wrap">
                {selectedProject.invoices.map((invoice: any) => {
                  const itemProps =
                    auth.selectedSubgroup === "INVOICE" &&
                    auth.selectedSubgroupId === invoice.id
                      ? { ref: scrollToRef }
                      : {};
                  return (
                    <div
                      className="half-percent-width"
                      key={invoice.id}
                      {...itemProps}
                      onClick={clearSelected}
                    >
                      <InvoiceCard
                        highlight={auth.selectedSubgroupId === invoice.id}
                        key={invoice.id}
                        invoice={invoice}
                        getProjects={getProjects}
                      />
                    </div>
                  );
                })}
              </div>
            ) : invoicesClicked && invoices.length > 0 ? (
              <div className=" row-space-between wrap">
                {invoices.map((invoice: any) => {
                  const itemProps =
                    auth.selectedSubgroup === "INVOICE" &&
                    auth.selectedSubgroupId === invoice.id
                      ? { ref: scrollToRef }
                      : {};
                  return (
                    <div
                      className="half-percent-width"
                      key={invoice.id}
                      {...itemProps}
                      onClick={clearSelected}
                    >
                      <InvoiceCard
                        highlight={auth.selectedSubgroupId === invoice.id}
                        key={invoice.id}
                        invoice={invoice}
                        getProjects={getProjects}
                      />
                    </div>
                  );
                })}
              </div>
            ) : (
              <br />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectsUser;
