import { useEffect, useState } from "react";
import "./AddUser.css";
import PropTypes from "prop-types";
import axiosService from "../../utils/axios";
import Box from "@mui/material/Box";

import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Switch from "@mui/material/Switch";
import { useAlert } from "react-alert";
import { TextField } from "@mui/material";
import Modal from "@mui/material/Modal";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import UndoIcon from "@mui/icons-material/Undo";
import Tooltip from "@mui/material/Tooltip";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const styles = {
  helper_available: {
    color: "#5db15c",
    fontSize: ".8em",
  },
  helper_unavailable: {
    color: "#c6044a",
    fontSize: ".8em",
  },
};

const AddUser = (props: any) => {
  const [show, setShow] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  // const [password, setPassword] = useState("");
  const alert = useAlert();
  // const [passwordAgain, setPasswordAgain] = useState("");
  const auth = useSelector((state: RootState) => state.auth);
  const [roles, setRoles] = useState({
    dev: false,
    admin: false,
    client: auth.selectedView === "CLIENT" ? true : false,
    lead_provider: false,
    client_admin: false,
    client_user: false,
    client_manager: false,
  });

  const [isUserNameAvailable, setIsUsernameAvailable] = useState<
    boolean | null
  >();

  const [isFirst, setIsFirst] = useState(true);

  const closeHandler = () => {
    setShow(false);
    props.onClose(false);
  };
  useEffect(() => {
    if (isFirst) {
      setIsFirst(false);
    }

    reset();
  }, []);

  useEffect(() => {
    const close = (e: any) => {
      if (e.keyCode === 27) {
        closeHandler();
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);
  useEffect(() => {
    if (username !== "") {
      checkUsername();
    }
  }, [username]);

  const checkUsername = () => {
    axiosService
      .post(`${process.env.REACT_APP_API_URL}/check_username`, {
        username: username,
      })
      .then((res) => {
        setIsUsernameAvailable(res.data);
      })

      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRoles({
      ...roles,
      [event.target.name]: event.target.checked,
    });
  };

  const addUser = () => {
    let body = {
      username: username,
      first_name: firstName,
      last_name: lastName,
      email: email,
      // password: password,
      staff: roles.admin,
      admin: roles.admin,
      dev: roles.dev,
      lead_provider: roles.lead_provider,
      client: roles.client,
      client_admin: roles.client_admin,
      client_user: roles.client_user,
      client_manager: roles.client_manager,
      validated: true,
      add_client_user: auth.selectedView === "CLIENT" ? true : false,
    };
    axiosService
      .post(`${process.env.REACT_APP_API_URL}/add_user`, body)
      .then(() => {
        alert.success("The user was added");
        props.onClose(true);
      })

      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  const reset = () => {
    setFirstName("");
    setLastName("");
    setUsername("");
    // setPassword("");
    setEmail("");
  };

  return (
    // <div
    //   style={{
    //     visibility: show ? "visible" : "hidden",
    //     opacity: show ? "1" : "0",
    //   }}
    //   className={"overlay"}
    // >
    <Modal
      open={show}
      onClose={closeHandler}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={"popup-add-user"}>
        <div className="row-space-between padding-b20">
          <div className="row-start-center">
            <span className="text-Proxima Nova700 ">Add a new User</span>
            <Tooltip title="Reset" placement="bottom" arrow>
              <UndoIcon
                className="add-lead-reload-icon blue pointer"
                onClick={reset}
              />
            </Tooltip>
          </div>
          <span className={"close"} onClick={closeHandler}>
            &times;
          </span>
        </div>

        {/* <button
          className="reset-button padding-up-down align-self-end"
          onClick={reset}
        > Reset</button> */}

        <div className="column-space-between">
          <div className="add-user-row-sb">
            <Box className="add-user-box-width">
              <FormControl fullWidth>
                {/* <InputLabel id="demo-simple-select-label">
                      Start Date
                    </InputLabel> */}
                <TextField
                  InputLabelProps={{ shrink: true }}
                  type="text"
                  label="First Name"
                  id="first-name-add-user"
                  value={firstName}
                  onChange={(event) => setFirstName(event.target.value)}
                />
              </FormControl>
            </Box>
            <Box className="add-user-box-width">
              <FormControl fullWidth>
                {/* <InputLabel id="demo-simple-select-label">
                      Start Date
                    </InputLabel> */}
                <TextField
                  InputLabelProps={{ shrink: true }}
                  type="text"
                  label="Last Name"
                  id="last-name-add-user"
                  value={lastName}
                  onChange={(event) => setLastName(event.target.value)}
                />
              </FormControl>
            </Box>
          </div>
          <div className="add-user-row-sb">
            <Box className="add-user-box-width">
              <FormControl fullWidth>
                {/* <InputLabel id="demo-simple-select-label">
                      Start Date
                    </InputLabel> */}
                <TextField
                  InputLabelProps={{ shrink: true }}
                  type="text"
                  label="Username"
                  id="username-add-user"
                  helperText={
                    isUserNameAvailable
                      ? "The username is available"
                      : isUserNameAvailable === false
                      ? "The username is already used"
                      : ""
                  }
                  value={username}
                  FormHelperTextProps={{
                    style: isUserNameAvailable
                      ? styles.helper_available
                      : styles.helper_unavailable,
                  }}
                  onChange={(event) => setUsername(event.target.value)}
                />
              </FormControl>
            </Box>
            <Box className="add-user-box-width">
              <FormControl fullWidth>
                {/* <InputLabel id="demo-simple-select-label">
                      Start Date
                    </InputLabel> */}
                <TextField
                  InputLabelProps={{ shrink: true }}
                  type="text"
                  label="Email"
                  id="email-add-user"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />
              </FormControl>
            </Box>
          </div>
          {/* <div className="add-user-row-sb">
            <Box className="add-user-box-width">
              <FormControl fullWidth>
             
                <TextField
                  InputLabelProps={{ shrink: true }}
                  type="password"
                  label="Password"
                  id="username-add-user"
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                />
              </FormControl>
            </Box>
            <Box className="add-user-box-width">
              <FormControl fullWidth>
                
                <TextField
                  InputLabelProps={{ shrink: true }}
                  type="password"
                  label="Repeat Password"
                  helperText={
                    passwordAgain !== "" &&
                    passwordAgain !== password &&
                    "The passwords don't match"
                  }
                  FormHelperTextProps={{ style: styles.helper_unavailable }}
                  id="email-add-user"
                  value={passwordAgain}
                  onChange={(event) => setPasswordAgain(event.target.value)}
                />
              </FormControl>
            </Box>
          </div> */}
          <div className="row-space-between padding-normal ">
            {auth.selectedView === "GYLD MANAGER" && (
              <FormControl component="fieldset" variant="standard">
                <FormLabel component="legend">Assign roles</FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={roles.admin}
                        onChange={handleChange}
                        name="admin"
                      />
                    }
                    label="Gyld Manager"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={roles.client}
                        onChange={handleChange}
                        name="client"
                      />
                    }
                    label="Client"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={roles.dev}
                        onChange={handleChange}
                        name="dev"
                      />
                    }
                    label="Dev"
                  />

                  <FormControlLabel
                    control={
                      <Switch
                        checked={roles.lead_provider}
                        onChange={handleChange}
                        name="lead_provider"
                      />
                    }
                    label="Lead Provider"
                  />
                </FormGroup>
              </FormControl>
            )}
            {roles.client && (
              <FormControl component="fieldset" variant="standard">
                <FormLabel component="legend">Assign Client roles</FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={roles.client_admin}
                        onChange={handleChange}
                        name="client_admin"
                      />
                    }
                    label="Client Admin"
                  />

                  <FormControlLabel
                    control={
                      <Switch
                        checked={roles.client_manager}
                        onChange={handleChange}
                        name="client_manager"
                      />
                    }
                    label="Client Manager"
                  />

                  <FormControlLabel
                    control={
                      <Switch
                        checked={roles.client_user}
                        onChange={handleChange}
                        name="client_user"
                      />
                    }
                    label="Client User"
                  />
                </FormGroup>
              </FormControl>
            )}
          </div>
        </div>

        <div
          style={{
            alignSelf: "flex-end",
          }}
        >
          <button
            className="button-white-small text-Proxima Nova700-small margin-r20"
            onClick={() => props.onClose(true)}
          >
            {" "}
            Cancel
          </button>

          <button
            disabled={
              (roles.client &&
                !roles.client_user &&
                !roles.client_manager &&
                !roles.client_admin) ||
              email === "" ||
              firstName === "" ||
              lastName === "" ||
              username === ""
            }
            className="button-black-small-nm text-Proxima Nova700-small"
            onClick={addUser}
          >
            {" "}
            Save User
          </button>
        </div>
      </div>
    </Modal>
  );
};

AddUser.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AddUser;
