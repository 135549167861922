import "./PublicProfile.css";
import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";

import { useAlert } from "react-alert";
import {
  Button,
  Input,
  InputAdornment,
  OutlinedInput,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate, useLocation, Location } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import locationSlice from "../../store/slices/location";
import ImageUploading from "react-images-uploading";
import axiosService from "../../utils/axios";
import Autocomplete from "@mui/material/Autocomplete";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import moment from "moment";
import AddIcon from "@mui/icons-material/Add";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import Modal from "@mui/material/Modal";

const PublicProfile = (props: any) => {
  const [UserName, setUserName] = useState("");
  const [WorkingDays, setWorkingDays] = useState("");
  const [WorkingDaysWeekend, setWorkingDaysWeekend] = useState("");
  const [description, setDescription] = useState("");
  const [Unavailability, setUnavailability] = useState<any[]>([]);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [imageSel, setImageSel] = useState<any[]>([]);
  const [userId, setUserId] = useState<any>();
  const [devSkills, setDevSkills] = useState<any[]>([]);
  const [country, setCountry] = useState("");
  const [languages, setLanguages] = useState<any[]>([]);
  const [user, setUser] = useState<any>();
  const dispatch = useDispatch();

  const alert = useAlert();
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useSelector((state: RootState) => state.auth);
  // useEffect(() => {
  //   dispatch(
  //     locationSlice.actions.setLocation({
  //       path: "/platform/public-profile",
  //     })
  //   );
  //   if (!auth.account) {
  //     navigate("/login");
  //   }
  // }, []);

  useEffect(() => {
    if (location.state) {
      setUserId(location.state);
    }
  }, [location]);

  useEffect(() => {
    if (props.user) {
      setUserId(props.user);
    }
  }, [props]);

  useEffect(() => {
    if (userId) {
      getUserInfo();
    }
  }, [userId]);

  useEffect(() => {
    setUserName("");
    setWorkingDays("");
    setWorkingDaysWeekend("");
    setDescription("");
    setCountry("");
  }, []);

  const getUserInfo = () => {
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_public_profile/` + userId)
      .then((res) => {
        setUser(res.data);
        setUserName(res.data.username);
        setDescription(res.data.description);
        setStartTime(res.data.start_time);
        setLanguages(res.data.languages);
        setEndTime(res.data.end_time);
        setCountry(res.data.country);
        if (res.data.image !== null) {
          setImageSel([
            {
              data_url:
                process.env.REACT_APP_API_URL?.split("/api")[0] +
                res.data.image,
            },
          ]);
        }
        setDevSkills(res.data.dev_skills);

        setWorkingDays(res.data.capa_hours_per_week);
        setWorkingDaysWeekend(res.data.days_weekend);
        let tmp_un: any[] = [];
        res.data.unavailable_dates.forEach((date: any) => {
          let tmp = { from_date: date.from_date, to_date: date.to_date };
          tmp_un.push(tmp);
        });
        setUnavailability(tmp_un);
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };
  // const reset = () => {
  //   setUnavailableStart("");
  // }

  return (
    <div className="pub-profile-scroll">
      <div className="pub-profile-outline">
        <div className="column-start-start">
          <div className="column-start-center">
            <div className="image-item">
              {imageSel && imageSel.length > 0 ? (
                <img
                  className="profile-page-pic"
                  src={imageSel[imageSel.length - 1]["data_url"]}
                  alt=""
                />
              ) : (
                <div className="profile-page-pic no-pic">
                  <span>{UserName.charAt(0)}</span>
                </div>
              )}
              {/* <div className="image-item__btn-wrapper">

                    <button onClick={() => onImageUpdate(index)}>Update</button>
                    <button onClick={() => onImageRemove(index)}>Remove</button>
                  </div> */}
            </div>
            <span className="text-Proxima Nova700 opp-status padding-up-down">
              Username:&nbsp;
              {UserName}
            </span>

            <span className="text-Proxima Nova700 opp-status">
              Country:&nbsp;
              {country}
            </span>
          </div>

          <div className="column-start-start full-percent-height separate-area">
            <span className="heading-lora padding-up-down">Description</span>
            <span className="text-Proxima Nova700 opp-status">
              {description}
            </span>
          </div>
        </div>

        <div className="pub-prof-bottom">
          <div className="pub-prof-left-down">
            <span className="heading-lora padding-up-down">
              Working capacity
            </span>

            <div className="row-space-between padding-up-down wrap">
              <span className="text-Proxima Nova700 opp-status">
                Project hours per week:&nbsp; {WorkingDays}
              </span>
              {startTime && endTime && (
                <span className="text-Proxima Nova700 opp-status">
                  Standard Working hours:&nbsp;
                  {startTime.split(":").slice(0, -1).join(":") +
                    "-" +
                    endTime.split(":").slice(0, -1).join(":")}
                </span>
              )}

              {/* <span className="text-Proxima Nova700 opp-status">
                Number of working days on weekends:
                {WorkingDaysWeekend}
              </span> */}
            </div>
          </div>
          <div className="column-space-between">
            <span className="heading-lora padding-up-down">
              Unavailable Dates
            </span>
            {Unavailability.length === 0 && (
              <span className="text-Proxima Nova700 opp-status">
                No unavailable dates
              </span>
            )}
            {Unavailability.map((unavailable: any) => {
              return (
                <div
                  key={unavailable.from_date}
                  className="column-space-between padding-up-down"
                >
                  <span className="text-Proxima Nova700 opp-status">
                    Start Date:&nbsp;
                    {unavailable.from_date}
                  </span>

                  <span className="text-Proxima Nova700 opp-status">
                    End Date:&nbsp;
                    {unavailable.to_date}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
        <div className="pub-prof-bottom">
          <div className="column-start-start full-percent-height">
            {devSkills.length > 0 && (
              <div className="column-space-between">
                <span className="heading-lora"> Skills</span>
                <div className="public-profile-add-skills">
                  {devSkills.map((skill: any) => {
                    return (
                      <div
                        key={skill.id}
                        className="row-start-no-width margin-right"
                      >
                        <span className="text-Proxima Nova700 opp-status">
                          {skill.skill}:&nbsp;{skill.level}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="pub-prof-bottom">
          <div className="column-start-start full-percent-height">
            {languages.length > 0 && (
              <div className="column-space-between">
                <span className="heading-lora"> Languages</span>
                <div className="public-profile-add-skills">
                  {languages.map((language: any) => {
                    return (
                      <div
                        key={language.language}
                        className="row-start-no-width margin-right"
                      >
                        <span className="text-Proxima Nova700 opp-status">
                          {language.language}:&nbsp;{language.level}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default PublicProfile;
