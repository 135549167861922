import React, { useEffect } from "react";
import "./AlertUnassigned.css";

const AlertUnassigned = (props: any) => {
  return (
    <div
      style={{
        visibility: "visible",
        opacity: "1",
      }}
      className={"overlay "}
    >
      <div className={"popup-delete "}>
        <div className="delete-column">
          <span className="heading-lora">Error!</span>
          <span className="text-Proxima Nova lightgrey">
            The selected contracts need to have the same client to be added in
            the same project!
          </span>
          <div className="row-start">
            <button
              className="yes-button Proxima Nova700-small"
              onClick={props.closeAlert}
            >
              Got It
            </button>
          </div>
          {/* <div className="row-start">
            <button className="check-button"></button>
            <span className="text-Proxima Nova lightgrey">
              Do not show this msg again
            </span>
          </div> */}
        </div>
        <img
          onClick={() => props.setFoundDifClients(false)}
          className="close-icon"
          src={require("../../assets/images/close.png")}
        />
      </div>
    </div>
  );
};

export default AlertUnassigned;
