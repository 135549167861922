import React, { useState, useEffect } from "react";
import * as d3 from "d3";

const PhasesTimeline = (props: any) => {
  const [foundLastPhase, setFoundLastPhase] = useState(false);
  const [foundCurrentPhase, setFoundCurrentPhase] = useState(false);
  const [foundNextPhase, setFoundNextPhase] = useState(false);
  const [thereIsPrevious, setThereIsPrevious] = useState(false);
  const [thereIsNext, setThereIsNext] = useState(false);
  const [lastPhase, setLastPhase] = useState<any>({});
  const [currentPhase, setCurrentPhase] = useState<any>({});
  const [nextPhase, setNextPhase] = useState<any>({});
  const [foundLastMilestone, setFoundLastMilestone] = useState(false);
  const [foundCurrentMilestone, setFoundCurrentMilestone] = useState(false);
  const [foundNextMilestone, setFoundNextMilestone] = useState(false);
  const [lastMilestone, setLastMilestone] = useState<any>({});
  const [currentMilestone, setCurrentMilestone] = useState<any>({});
  const [nextMilestone, setNextMilestone] = useState<any>({});
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [width, setWidth] = useState(0);

  const height = 200;

  const x_scale = d3
    .scaleTime()
    .domain([startDate, endDate])
    .range([85, width - 85]);

  useEffect(() => {
    setThereIsNext(false);
    setThereIsPrevious(false);
  }, [props]);

  useEffect(() => {
    setWidth(props.width);

    if (props.phases?.length > 0) {
      let tmpStartDate = new Date(props.phases[0].start_date);
      let tmpEndDate = new Date(props.phases[0].end_date);
      let phaseResult = findPhases(props.phases);

      setFoundLastPhase(phaseResult.foundLast);
      setFoundCurrentPhase(phaseResult.foundCurrent);
      setFoundNextPhase(phaseResult.foundNext);
      setLastPhase(phaseResult.foundLast ? phaseResult.tmp_last_ok : {});
      setCurrentPhase(phaseResult.foundCurrent ? phaseResult.tmp_current : {});
      setNextPhase(phaseResult.foundNext ? phaseResult.tmp_next : {});
      if (!thereIsPrevious) {
        setThereIsPrevious(phaseResult.isPrev);
      }
      if (!thereIsNext) {
        setThereIsNext(phaseResult.isNext);
      }
      if (phaseResult.phaseStartDate < tmpStartDate) {
        tmpStartDate = phaseResult.phaseStartDate;
      }
      if (phaseResult.phaseEndDate > tmpEndDate) {
        tmpEndDate = phaseResult.phaseEndDate;
      }

      if (props.milestones?.length > 0) {
        let milestoneResult = findMilestones(props.milestones);

        setFoundLastMilestone(milestoneResult.foundLast);
        setFoundCurrentMilestone(milestoneResult.foundCurrent);
        setFoundNextMilestone(milestoneResult.foundNext);
        setLastMilestone(
          milestoneResult.tmp_last_ok ? milestoneResult.tmp_last_ok : {}
        );
        setCurrentMilestone(
          milestoneResult.foundCurrent ? milestoneResult.tmp_current : {}
        );
        setNextMilestone(
          milestoneResult.foundNext ? milestoneResult.tmp_next : {}
        );
        if (milestoneResult.milestoneStartDate < tmpStartDate) {
          tmpStartDate = milestoneResult.milestoneStartDate;
        }
        if (milestoneResult.milestoneEndDate > tmpEndDate) {
          tmpEndDate = milestoneResult.milestoneEndDate;
        }
        if (!thereIsPrevious) {
          setThereIsPrevious(milestoneResult.isPrev);
        }
        if (!thereIsNext) {
          setThereIsNext(milestoneResult.isNext);
        }
      }

      setStartDate(tmpStartDate);
      setEndDate(tmpEndDate);
    }
  }, [props]);

  const findPhases = (phases: any) => {
    let tmp_last_ok: any = {};
    let foundLast = false;
    let tmp_current: any = {};
    let foundCurrent = false;
    let tmp_next: any = {};
    let foundNext = false;
    let isPrev = false;
    let isNext = false;
    let phaseStartDate = new Date();
    let phaseEndDate = new Date();
    phases.forEach((phase: any, index: number) => {
      if (phase.status === "OK") {
        tmp_last_ok = phase;
        foundLast = true;
        if (index === 0) {
          isPrev = false;
        } else {
          isPrev = true;
        }
        if (new Date(phase.start_date) < new Date()) {
          phaseStartDate = new Date(phase.start_date);
        }
        if (new Date(phase.end_date) > new Date()) {
          phaseEndDate = new Date(phase.end_date);
        }

        if (index + 1 <= phases.length - 1) {
          tmp_current = phases[index + 1];
          if (tmp_current.status !== "OK") {
            foundCurrent = true;
            if (new Date(tmp_current.start_date) < phaseStartDate) {
              phaseStartDate = new Date(tmp_current.start_date);
            }
            if (new Date(tmp_current.end_date) > phaseEndDate) {
              phaseEndDate = new Date(tmp_current.end_date);
            }
            if (tmp_current.name === tmp_next?.name) {
              tmp_next = {};
              foundNext = false;
            }
          }
        }

        if (index + 2 <= phases.length - 1) {
          tmp_next = phases[index + 2];
          if (tmp_next.status !== "OK") {
            foundNext = true;
            if (index + 2 >= phases.length - 1) {
              isNext = false;
            } else {
              isNext = true;
            }
            if (new Date(tmp_next.start_date) < phaseStartDate) {
              phaseStartDate = new Date(tmp_next.start_date);
            }
            if (new Date(tmp_next.end_date) > phaseEndDate) {
              phaseEndDate = new Date(tmp_next.end_date);
            }
          }
        } else {
          isNext = false;
        }
      }
    });
    if (!foundLast) {
      isPrev = false;
      tmp_current = phases[0];
      foundCurrent = true;
      if (new Date(tmp_current.start_date) < phaseStartDate) {
        phaseStartDate = new Date(tmp_current.start_date);
      }
      if (new Date(tmp_current.end_date) > phaseEndDate) {
        phaseEndDate = new Date(tmp_current.end_date);
      }
      if (phases.length > 1) {
        tmp_next = phases[1];
        foundNext = true;
        if (phases.length > 2) {
          isNext = true;
        } else {
          isNext = false;
        }
        if (new Date(tmp_next.start_date) < phaseStartDate) {
          phaseStartDate = new Date(tmp_next.start_date);
        }
        if (new Date(tmp_next.end_date) > phaseEndDate) {
          phaseEndDate = new Date(tmp_next.end_date);
        }
      } else {
        isNext = false;
      }
    }

    return {
      foundLast,
      foundCurrent,
      foundNext,
      tmp_last_ok,
      tmp_current,
      tmp_next,
      phaseStartDate,
      phaseEndDate,
      isPrev,
      isNext,
    };
  };

  const findMilestones = (milestones: any) => {
    let tmp_last_ok: any = {};
    let foundLast = false;
    let tmp_current: any = {};
    let foundCurrent = false;
    let tmp_next: any = {};
    let foundNext = false;
    let isPrev = false;
    let isNext = false;
    let milestoneStartDate = new Date();
    let milestoneEndDate = new Date();
    milestones.forEach((milestone: any, index: number) => {
      if (milestone.status === "OK") {
        tmp_last_ok = milestone;
        foundLast = true;
        if (new Date(milestone.date) < new Date()) {
          milestoneStartDate = new Date(milestone.date);
        }
        if (new Date(milestone.date) > new Date()) {
          milestoneEndDate = new Date(milestone.date);
        }

        if (index === 0) {
          isPrev = false;
        } else {
          isPrev = true;
        }
        if (index + 1 <= milestones.length - 1) {
          tmp_current = milestones[index + 1];
          if (tmp_current.status !== "OK") {
            foundCurrent = true;
            if (new Date(tmp_current.date) < milestoneStartDate) {
              milestoneStartDate = new Date(tmp_current.date);
            }
            if (new Date(tmp_current.date) > milestoneEndDate) {
              milestoneEndDate = new Date(tmp_current.date);
            }
            if (tmp_current.name === tmp_next?.name) {
              tmp_next = {};
              foundNext = false;
            }
          }
        }
        if (index + 2 <= milestones.length - 1) {
          tmp_next = milestones[index + 2];
          if (tmp_next.status !== "OK") {
            foundNext = true;
            if (index + 2 >= milestones.length - 1) {
              isNext = false;
            } else {
              isNext = true;
            }
            if (new Date(tmp_next.date) < milestoneStartDate) {
              milestoneStartDate = new Date(tmp_next.date);
            }
            if (new Date(tmp_next.date) > milestoneEndDate) {
              milestoneEndDate = new Date(tmp_next.date);
            }
          }
        } else {
          isNext = false;
        }
      }
    });
    if (!foundLast) {
      isPrev = false;
      tmp_current = milestones[0];
      foundCurrent = true;
      if (new Date(tmp_current.date) < milestoneStartDate) {
        milestoneStartDate = new Date(tmp_current.date);
      }
      if (new Date(tmp_current.date) > milestoneEndDate) {
        milestoneEndDate = new Date(tmp_current.date);
      }
      if (milestones.length > 1) {
        tmp_next = milestones[1];
        foundNext = true;
        if (milestones.length > 2) {
          isNext = true;
        } else {
          isNext = false;
        }
        if (new Date(tmp_next.date) < milestoneStartDate) {
          milestoneStartDate = new Date(tmp_next.date);
        }
        if (new Date(tmp_next.date) > milestoneEndDate) {
          milestoneEndDate = new Date(tmp_next.date);
        }
      } else {
        isNext = false;
      }
    }

    return {
      foundLast,
      foundCurrent,
      foundNext,
      tmp_last_ok,
      tmp_current,
      tmp_next,
      milestoneStartDate,
      milestoneEndDate,
      isPrev,
      isNext,
    };
  };

  // const calculateTotalStartDate = (phases: any) => {
  //   let startDate = new Date(phases[0].start_date);
  //   phases.forEach((phase: any) => {
  //     let tmp_date = new Date(phase.start_date);
  //     if (tmp_date < startDate) {
  //       startDate = tmp_date;
  //     }
  //   });
  //   return startDate;
  // };
  // const calculateTotalEndDate = (phases: any) => {
  //   let endDate = new Date(phases[0].end_date);
  //   phases.forEach((phase: any) => {
  //     let tmp_date = new Date(phase.end_date);
  //     if (tmp_date > endDate) {
  //       endDate = tmp_date;
  //     }
  //   });
  //   return endDate;
  // };

  return (
    <div className="row-center">
      {props.phases.length > 0 && (
        <svg width={`${width}px`} height={`${height}px`}>
          {thereIsPrevious && (
            <g className="cursor-default" onClick={undefined}>
              <circle cx={52} cy={115} r={5} fill="lightgrey" />
              <circle cx={64} cy={115} r={5} fill="lightgrey" />
              <circle cx={78} cy={115} r={5} fill="lightgrey" />
            </g>
          )}
          {thereIsNext && (
            <g className="cursor-default" onClick={undefined}>
              <circle cx={width - 20} cy={115} r={5} fill="lightgrey" />
              <circle cx={width - 32} cy={115} r={5} fill="lightgrey" />
              <circle cx={width - 44} cy={115} r={5} fill="lightgrey" />
            </g>
          )}
          {foundLastPhase && (
            <g
              className="pointer"
              onClick={() => props.handleClickPhase(lastPhase)}
            >
              {lastPhase.status === "OK" ? (
                <rect
                  rx={8}
                  ry={8}
                  x={x_scale(new Date(lastPhase.start_date))}
                  y={100}
                  width={
                    x_scale(new Date(lastPhase.end_date)) -
                    x_scale(new Date(lastPhase.start_date))
                  }
                  height={35}
                  style={{
                    fill: "#01c501",
                    opacity: 0.5,
                  }}
                />
              ) : lastPhase.status === "NEW" ||
                lastPhase.status === "IN_PROGRESS" ? (
                <rect
                  rx={8}
                  ry={8}
                  x={x_scale(new Date(lastPhase.start_date))}
                  y={100}
                  width={
                    x_scale(new Date(lastPhase.end_date)) -
                    x_scale(new Date(lastPhase.start_date))
                  }
                  height={35}
                  style={{
                    fill: "#04a6ff",
                    opacity: 0.5,
                  }}
                />
              ) : (
                <rect
                  rx={8}
                  ry={8}
                  x={x_scale(new Date(lastPhase.start_date))}
                  y={100}
                  width={
                    x_scale(new Date(lastPhase.end_date)) -
                    x_scale(new Date(lastPhase.start_date))
                  }
                  height={35}
                  style={{
                    fill: "red",
                    opacity: 0.5,
                  }}
                />
              )}

              <foreignObject
                x={x_scale(new Date(lastPhase.start_date)) - 25}
                y={120}
                width={
                  x_scale(new Date(lastPhase.end_date)) -
                  x_scale(new Date(lastPhase.start_date)) +
                  50
                }
                height="70"
              >
                <p
                  className="text-Proxima Nova700 row-center full-percent-height"
                  style={{ textAlign: "center", padding: 5, opacity: 0.5 }}
                  data-xmlns="http://www.w3.org/1999/xhtml"
                >
                  {" "}
                  {lastPhase.name}
                </p>
              </foreignObject>
            </g>
          )}
          {foundCurrentPhase && (
            <g
              className="pointer"
              onClick={() => props.handleClickPhase(currentPhase)}
            >
              {currentPhase.status === "OK" ? (
                <rect
                  rx={8}
                  ry={8}
                  x={x_scale(new Date(currentPhase.start_date))}
                  y={100}
                  width={
                    x_scale(new Date(currentPhase.end_date)) -
                    x_scale(new Date(currentPhase.start_date))
                  }
                  height={35}
                  style={{
                    fill: "#01c501",
                    opacity: 1,
                  }}
                />
              ) : currentPhase.status === "NEW" ||
                currentPhase.status === "IN_PROGRESS" ? (
                <rect
                  rx={8}
                  ry={8}
                  x={x_scale(new Date(currentPhase.start_date))}
                  y={100}
                  width={
                    x_scale(new Date(currentPhase.end_date)) -
                    x_scale(new Date(currentPhase.start_date))
                  }
                  height={35}
                  style={{
                    fill: "#04a6ff",
                    opacity: 1,
                  }}
                />
              ) : (
                <rect
                  rx={8}
                  ry={8}
                  x={x_scale(new Date(currentPhase.start_date))}
                  y={100}
                  width={
                    x_scale(new Date(currentPhase.end_date)) -
                    x_scale(new Date(currentPhase.start_date))
                  }
                  height={35}
                  style={{
                    fill: "red",
                    opacity: 1,
                  }}
                />
              )}
              <foreignObject
                x={x_scale(new Date(currentPhase.start_date)) - 25}
                y={120}
                width={
                  x_scale(new Date(currentPhase.end_date)) -
                  x_scale(new Date(currentPhase.start_date)) +
                  50
                }
                height="70"
              >
                <p
                  className="text-Proxima Nova700 row-center full-percent-height "
                  style={{ textAlign: "center", padding: 5 }}
                  data-xmlns="http://www.w3.org/1999/xhtml"
                >
                  {" "}
                  {currentPhase.name}
                </p>
              </foreignObject>
            </g>
          )}
          {foundNextPhase && (
            <g
              className="pointer"
              onClick={() => props.handleClickPhase(nextPhase)}
            >
              {nextPhase.status === "OK" ? (
                <rect
                  rx={8}
                  ry={8}
                  x={x_scale(new Date(nextPhase.start_date))}
                  y={100}
                  width={
                    x_scale(new Date(nextPhase.end_date)) -
                    x_scale(new Date(nextPhase.start_date))
                  }
                  height={35}
                  style={{
                    fill: "#01c501",
                    opacity: 0.5,
                  }}
                />
              ) : nextPhase.status === "NEW" ||
                nextPhase.status === "IN_PROGRESS" ? (
                <rect
                  rx={8}
                  ry={8}
                  x={x_scale(new Date(nextPhase.start_date))}
                  y={100}
                  width={
                    x_scale(new Date(nextPhase.end_date)) -
                    x_scale(new Date(nextPhase.start_date))
                  }
                  height={35}
                  style={{
                    fill: "#04a6ff",
                    opacity: 0.5,
                  }}
                />
              ) : (
                <rect
                  rx={8}
                  ry={8}
                  x={x_scale(new Date(nextPhase.start_date))}
                  y={100}
                  width={
                    x_scale(new Date(nextPhase.end_date)) -
                    x_scale(new Date(nextPhase.start_date))
                  }
                  height={35}
                  style={{
                    fill: "red",
                    opacity: 0.5,
                  }}
                />
              )}
              <foreignObject
                x={x_scale(new Date(nextPhase.start_date)) - 25}
                y={120}
                width={
                  x_scale(new Date(nextPhase.end_date)) -
                  x_scale(new Date(nextPhase.start_date)) +
                  50
                }
                height="70"
              >
                <p
                  className="text-Proxima Nova700 row-center full-percent-height"
                  style={{ textAlign: "center", padding: 5, opacity: 0.5 }}
                  data-xmlns="http://www.w3.org/1999/xhtml"
                >
                  {" "}
                  {nextPhase.name}
                </p>
              </foreignObject>
            </g>
          )}
          <filter id="constantOpacity">
            <feComponentTransfer>
              <feFuncA type="table" tableValues="0 .5 .5" />
            </feComponentTransfer>
          </filter>
          {foundLastMilestone && (
            <g
              className="pointer"
              onClick={() => props.handleClickMilestone(lastMilestone)}
              filter="url(#constantOpacity)"
            >
              {lastMilestone.status === "OK" ? (
                <circle
                  cx={x_scale(new Date(lastMilestone.date))}
                  cy={70}
                  r={13}
                  style={{
                    fill: "#01c501",
                    opacity: 0.5,
                  }}
                />
              ) : lastMilestone.status === "NEW" ||
                lastMilestone.status === "IN_PROGRESS" ? (
                <circle
                  cx={x_scale(new Date(lastMilestone.date))}
                  cy={70}
                  r={13}
                  style={{
                    fill: "#04a6ff",
                    opacity: 1,
                  }}
                />
              ) : (
                <circle
                  cx={x_scale(new Date(lastMilestone.date))}
                  cy={70}
                  r={13}
                  style={{
                    fill: "red",
                    opacity: 0.5,
                  }}
                />
              )}{" "}
              <circle
                cx={x_scale(new Date(lastMilestone.date))}
                cy={70}
                r={5}
                style={{
                  fill: "white",
                  opacity: 1,
                }}
              />
              {lastMilestone.status === "OK" ? (
                <polygon
                  points={`${x_scale(new Date(lastMilestone.date)) - 11},77,${
                    x_scale(new Date(lastMilestone.date)) + 11
                  },77,${
                    (x_scale(new Date(lastMilestone.date)) -
                      11 +
                      x_scale(new Date(lastMilestone.date)) +
                      11) /
                    2
                  },100`}
                  fill={"#01c501"}
                  opacity={0.5}
                />
              ) : lastMilestone.status === "NEW" ||
                lastMilestone.status === "IN_PROGRESS" ? (
                <polygon
                  points={`${x_scale(new Date(lastMilestone.date)) - 11},77,${
                    x_scale(new Date(lastMilestone.date)) + 11
                  },77,${
                    (x_scale(new Date(lastMilestone.date)) -
                      11 +
                      x_scale(new Date(lastMilestone.date)) +
                      11) /
                    2
                  },100`}
                  fill={"#04a6ff"}
                  opacity={0.5}
                />
              ) : (
                <polygon
                  points={`${x_scale(new Date(lastMilestone.date)) - 11},77,${
                    x_scale(new Date(lastMilestone.date)) + 11
                  },77,${
                    (x_scale(new Date(lastMilestone.date)) -
                      11 +
                      x_scale(new Date(lastMilestone.date)) +
                      11) /
                    2
                  },100`}
                  fill={"red"}
                  opacity={0.5}
                />
              )}
              <foreignObject
                x={x_scale(new Date(lastMilestone.date)) - 85}
                y={-10}
                width="170"
                height="70"
              >
                <p
                  className="text-Proxima Nova700"
                  style={{
                    textAlign: "center",
                    padding: 5,
                    opacity: 0.5,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    height: "100%",
                  }}
                  data-xmlns="http://www.w3.org/1999/xhtml"
                >
                  {" "}
                  {lastMilestone.name}
                </p>
              </foreignObject>
            </g>
          )}
          {foundCurrentMilestone && (
            <g
              className="pointer"
              onClick={() => props.handleClickMilestone(currentMilestone)}
            >
              {currentMilestone.status === "OK" ? (
                <circle
                  cx={x_scale(new Date(currentMilestone.date))}
                  cy={70}
                  r={13}
                  style={{
                    fill: "#01c501",
                    opacity: 1,
                  }}
                />
              ) : currentMilestone.status === "NEW" ||
                currentMilestone.status === "IN_PROGRESS" ? (
                <circle
                  cx={x_scale(new Date(currentMilestone.date))}
                  cy={70}
                  r={13}
                  style={{
                    fill: "#04a6ff",
                    opacity: 1,
                  }}
                />
              ) : (
                <circle
                  cx={x_scale(new Date(currentMilestone.date))}
                  cy={70}
                  r={13}
                  style={{
                    fill: "red",
                    opacity: 1,
                  }}
                />
              )}{" "}
              <circle
                cx={x_scale(new Date(currentMilestone.date))}
                cy={70}
                r={5}
                style={{
                  fill: "white",
                  opacity: 1,
                }}
              />
              {currentMilestone.status === "OK" ? (
                <polygon
                  points={`${
                    x_scale(new Date(currentMilestone.date)) - 11
                  },77,${x_scale(new Date(currentMilestone.date)) + 11},77,${
                    (x_scale(new Date(currentMilestone.date)) -
                      11 +
                      x_scale(new Date(currentMilestone.date)) +
                      11) /
                    2
                  },100`}
                  fill={"#01c501"}
                  opacity={1}
                />
              ) : currentMilestone.status === "NEW" ||
                currentMilestone.status === "IN_PROGRESS" ? (
                <polygon
                  points={`${
                    x_scale(new Date(currentMilestone.date)) - 11
                  },77,${x_scale(new Date(currentMilestone.date)) + 11},77,${
                    (x_scale(new Date(currentMilestone.date)) -
                      11 +
                      x_scale(new Date(currentMilestone.date)) +
                      11) /
                    2
                  },100`}
                  fill={"#04a6ff"}
                  opacity={1}
                />
              ) : (
                <polygon
                  points={`${
                    x_scale(new Date(currentMilestone.date)) - 11
                  },77,${x_scale(new Date(currentMilestone.date)) + 11},77,${
                    (x_scale(new Date(currentMilestone.date)) -
                      11 +
                      x_scale(new Date(currentMilestone.date)) +
                      11) /
                    2
                  },100`}
                  fill={"red"}
                  opacity={1}
                />
              )}
              <foreignObject
                x={x_scale(new Date(currentMilestone.date)) - 85}
                y={-10}
                width="170"
                height="70"
              >
                <p
                  className="text-Proxima Nova700"
                  style={{
                    textAlign: "center",
                    padding: 5,
                    opacity: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",

                    height: "100%",
                  }}
                  data-xmlns="http://www.w3.org/1999/xhtml"
                >
                  {" "}
                  {currentMilestone.name}
                </p>
              </foreignObject>
            </g>
          )}
          {foundNextMilestone && (
            <g
              className="pointer"
              onClick={() => props.handleClickMilestone(nextMilestone)}
              filter="url(#constantOpacity)"
            >
              {nextMilestone.status === "OK" ? (
                <circle
                  cx={x_scale(new Date(nextMilestone.date))}
                  cy={70}
                  r={13}
                  style={{
                    fill: "#01c501",
                    opacity: 0.5,
                  }}
                />
              ) : nextMilestone.status === "NEW" ||
                nextMilestone.status === "IN_PROGRESS" ? (
                <circle
                  cx={x_scale(new Date(nextMilestone.date))}
                  cy={70}
                  r={13}
                  style={{
                    fill: "#04a6ff",
                    opacity: 0.5,
                  }}
                />
              ) : (
                <circle
                  cx={x_scale(new Date(nextMilestone.date))}
                  cy={70}
                  r={13}
                  style={{
                    fill: "red",
                    opacity: 0.5,
                  }}
                />
              )}{" "}
              <circle
                cx={x_scale(new Date(nextMilestone.date))}
                cy={70}
                r={5}
                style={{
                  fill: "white",
                  opacity: 1,
                }}
              />
              {nextMilestone.status === "OK" ? (
                <polygon
                  points={`${x_scale(new Date(nextMilestone.date)) - 11},77,${
                    x_scale(new Date(nextMilestone.date)) + 11
                  },77,${
                    (x_scale(new Date(nextMilestone.date)) -
                      11 +
                      x_scale(new Date(nextMilestone.date)) +
                      11) /
                    2
                  },100`}
                  fill={"#01c501"}
                  opacity={0.5}
                />
              ) : nextMilestone.status === "NEW" ||
                nextMilestone.status === "IN_PROGRESS" ? (
                <polygon
                  points={`${x_scale(new Date(nextMilestone.date)) - 11},77,${
                    x_scale(new Date(nextMilestone.date)) + 11
                  },77,${
                    (x_scale(new Date(nextMilestone.date)) -
                      11 +
                      x_scale(new Date(nextMilestone.date)) +
                      11) /
                    2
                  },100`}
                  fill={"#04a6ff"}
                  opacity={0.5}
                />
              ) : (
                <polygon
                  points={`${x_scale(new Date(nextMilestone.date)) - 11},77,${
                    x_scale(new Date(nextMilestone.date)) + 11
                  },77,${
                    (x_scale(new Date(nextMilestone.date)) -
                      11 +
                      x_scale(new Date(nextMilestone.date)) +
                      11) /
                    2
                  },100`}
                  fill={"red"}
                  opacity={0.5}
                />
              )}
              <foreignObject
                x={x_scale(new Date(nextMilestone.date)) - 85}
                y={-10}
                width="170"
                height="70"
              >
                <p
                  className="text-Proxima Nova700"
                  style={{
                    textAlign: "center",
                    padding: 5,
                    opacity: 0.5,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    height: "100%",
                  }}
                  data-xmlns="http://www.w3.org/1999/xhtml"
                >
                  {" "}
                  {nextMilestone.name}
                </p>
              </foreignObject>
            </g>
          )}
          <polygon
            points={`${x_scale(new Date()) - 10},100,${
              x_scale(new Date()) + 10
            },100,${
              (x_scale(new Date()) - 10 + x_scale(new Date()) + 10) / 2
            },135`}
            fill={"#626da1"}
            opacity={1}
          />
        </svg>
      )}
    </div>
  );
};

export default PhasesTimeline;
