import React, { useEffect, useState } from "react";
import "./PricingConfigurator.css";
import locationSlice from "../../store/slices/location";
import { Link } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import { useDispatch } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import { Input, TextareaAutosize, TextField } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import moment from "moment";
import axiosService from "../../utils/axios";
import DeleteIcon from "@mui/icons-material/Delete";
import CachedIcon from "@mui/icons-material/Cached";
import Chip from "@mui/material/Chip";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import Tooltip from "@mui/material/Tooltip";
import UndoIcon from "@mui/icons-material/Undo";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "moment/locale/en-gb";

import { useAlert } from "react-alert";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const PricingConfigurator = () => {
  const [nameOfClient, setNameOfClient] = useState("");
  const [totalCost, setTotalCost] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [customerType, setCustomerType] = useState("");
  const [industry, setIndustry] = useState("");
  const [duration, setDuration] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [description, setDescription] = useState("");
  const dispatch = useDispatch();
  const [inputValueSkill, setInputValueSkill] = useState("");
  const [skills, setSkills] = useState<Array<string>>([]);
  const [devSkills, setDevSkills] = useState<string[]>([]);
  const [hotSkills, setHotSkills] = useState<any[]>([]);
  const alert = useAlert();
  const [devHours, setDevHours] = useState("");
  const [devLevel, setDevLevel] = useState("");
  const [devDuration, setDevDuration] = useState(0);
  const [devNumber, setDevNumber] = useState(0);
  const [devStartDate, setDevStartDate] = useState("");
  const [selectedDevs, setSelectedDevs] = useState<any[]>([]);
  const [errorMessageSkills, setErrorMessageSkills] = useState("");

  const junior: number = parseInt(
    process.env.JUNIOR_PRICE ? process.env.JUNIOR_PRICE : "30"
  );
  const mid: number = parseInt(
    process.env.MID_PRICE ? process.env.MID_PRICE : "40"
  );
  const senior: number = parseInt(
    process.env.SENIOR_PRICE ? process.env.SENIOR_PRICE : "50"
  );

  useEffect(() => {
    getSkills();
  }, []);

  useEffect(() => {
    if (devSkills && devSkills.length > 6) {
      setErrorMessageSkills("You can choose up to 6 skills");
    }
  }, [devSkills]);

  useEffect(() => {
    if (devSkills && devSkills.length <= 6 && errorMessageSkills) {
      setErrorMessageSkills("");
    }
  }, [devSkills, errorMessageSkills]);

  const handleChangeDevSkills = (skill: string) => {
    if (devSkills.includes(skill)) {
      setDevSkills(
        devSkills.filter((name: string) => {
          return name !== skill;
        })
      );
    } else {
      setDevSkills([...devSkills, skill]);
    }
  };
  const getSkills = () => {
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_skills`)
      .then((res) => {
        let tmpSkills: any[] = [];
        let tmpHotSkills: any[] = [];
        res.data.forEach((data: any) => {
          tmpSkills.push(data.name);
          if (data.is_hot_skill) {
            tmpHotSkills.push(data);
          }
        });
        setSkills(tmpSkills);
        setHotSkills(tmpHotSkills);
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };

  const removeSelectedDev = (index: number) => {
    let tmp = [...selectedDevs];
    tmp.splice(index, 1);
    setTotalCost(totalCost - selectedDevs[index].cost);
    setSelectedDevs(tmp);
  };

  const reset = () => {
    setSelectedDevs([]);
    setDevSkills([]);
    setDevDuration(0);
    setDevLevel("");
    setDevNumber(0);
    setDevStartDate("");
    setCustomerEmail("");
    setCustomerType("");
    setNameOfClient("");
    setStartDate("");
    setTotalCost(0);
    setIndustry("");
    setDuration("");
    setDescription("");
  };

  const handleAddDeveloper = () => {
    let cost = 0;
    let daily = 0;
    let extra = 0;
    devSkills.forEach((skill: string) => {
      hotSkills.forEach((hotSkill: any) => {
        if (hotSkill.name === skill) {
          extra += hotSkill.price_markup;
        }
      });
    });

    if (devLevel === "JUNIOR") {
      if (devHours === "FULL_TIME") {
        if (devDuration !== null) {
          cost = devNumber * 8 * devDuration * (junior + extra);
          daily = devNumber * 8 * (junior + extra);
        }
      } else if (devHours === "PART_TIME") {
        if (devDuration !== null) {
          cost = devNumber * 4 * devDuration * (junior + extra);
          daily = devNumber * 4 * (junior + extra);
        }
      }
    } else if (devLevel === "MID") {
      if (devHours === "FULL_TIME") {
        if (devDuration !== null) {
          cost = devNumber * 8 * devDuration * (mid + extra);
          daily = devNumber * 8 * (mid + extra);
        }
      } else if (devHours === "PART_TIME") {
        if (devDuration !== null) {
          cost = devNumber * 4 * devDuration * (mid + extra);
          daily = devNumber * 4 * (mid + extra);
        }
      }
    } else if (devLevel === "SENIOR") {
      if (devHours === "FULL_TIME") {
        if (devDuration !== null) {
          cost = devNumber * 8 * devDuration * (senior + extra);
          daily = devNumber * 8 * (senior + extra);
        }
      } else if (devHours === "PART_TIME") {
        if (devDuration !== null) {
          cost = devNumber * 4 * devDuration * (senior + extra);
          daily = devNumber * 4 * (senior + extra);
        }
      }
    }

    let tmp = {
      skills: devSkills,
      duration: devDuration,
      developer_level: devLevel,
      number_of_devs: devNumber,
      start_date: devStartDate,
      dev_hours: devHours,
      cost: cost,
      daily: daily,
      extra: extra,
    };
    setTotalCost(totalCost + cost);
    let tmpSelectedDevs = selectedDevs;
    tmpSelectedDevs.push(tmp);
    setSelectedDevs(tmpSelectedDevs);
    setDevSkills([]);
    setDevDuration(0);
    setDevLevel("");
    setDevNumber(0);
    setDevStartDate("");
    setDevHours("");
  };
  return (
    <div>
      {window.innerWidth >= 800 ? (
        <div className={"configurator-out"}>
          <div className="row-start padding-b20">
            <span className="text-Proxima Nova700">Project Configurator</span>
            <Tooltip title="Reset" placement="bottom" arrow>
              <UndoIcon
                className="add-lead-reload-icon blue pointer"
                onClick={reset}
              />
            </Tooltip>
          </div>
          <div className={"configurator-grey-bottom"}>
            <div className="configurator-bottom-left">
              <span className={"text-Proxima Nova700-small padding-b20"}>
                Specifications of the Developers
              </span>

              <Box className="config-box-width-big margin-up-down">
                <FormControl fullWidth>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    type="number"
                    label="Number of Developers"
                    InputProps={{ inputProps: { min: 0 } }}
                    id="demo-simple-select"
                    value={devNumber}
                    onChange={(event) =>
                      setDevNumber(parseInt(event.target.value))
                    }
                  />
                </FormControl>
              </Box>
              <Box className="config-box-width-big margin-up-down">
                <FormControl fullWidth>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    type="number"
                    InputProps={{ inputProps: { min: 0 } }}
                    id="demo-simple-select-duration"
                    label=" Project Days"
                    value={devDuration}
                    onChange={(event) =>
                      setDevDuration(parseInt(event.target.value))
                    }
                  />
                </FormControl>
              </Box>

              <Box className="config-box-width-big margin-up-down">
                <FormControl fullWidth>
                  <InputLabel shrink={true} id="demo-simple-select-label">
                    Availability Needed
                  </InputLabel>
                  <Select
                    input={
                      <OutlinedInput
                        id="select-multiple-chip"
                        label="Availability Needed"
                        notched
                      />
                    }
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={devHours || ""}
                    label="SelectedProject"
                    onChange={(event) => setDevHours(event.target.value)}
                  >
                    <MenuItem value={"FULL_TIME"}>Full Time</MenuItem>
                    <MenuItem value={"PART_TIME"}>Part Time</MenuItem>
                  </Select>
                </FormControl>
              </Box>

              {/* <span className={"text-Proxima Nova700-small padding-t7p"}>
            What skills do you need the developer to have?
          </span> */}

              <Box className="config-box-width-big margin-up-down">
                <FormControl fullWidth>
                  <InputLabel shrink={true} id="demo-simple-select-label">
                    Developer Level
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={devLevel || ""}
                    input={
                      <OutlinedInput
                        id="select-multiple-chip"
                        label="Developer Level"
                        notched
                      />
                    }
                    label="SelectedProject"
                    onChange={(event) => setDevLevel(event.target.value)}
                  >
                    <MenuItem value={"JUNIOR"}>Junior</MenuItem>
                    <MenuItem value={"MID"}>Mid</MenuItem>
                    <MenuItem value={"SENIOR"}>Senior</MenuItem>
                  </Select>
                </FormControl>
              </Box>

              {/* <Box className="config-box-width-big margin-up-down">
                <FormControl fullWidth>
                  <InputLabel shrink={true} id="demo-simple-select-label">
                    Skills
                  </InputLabel>
                  <Select
                    input={
                      <OutlinedInput
                        id="select-multiple-chip"
                        label="Skills"
                        notched
                      />
                    }
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={devSkills}
                    renderValue={(selected) => selected.join(", ")}
                    MenuProps={MenuProps}
                  >
                    <div className="skills-select-menu">
                      {skills.map((name: any) => (
                        <MenuItem
                          key={name}
                          value={name}
                          onClick={() => handleChangeDevSkills(name)}
                        >
                          <Checkbox checked={devSkills.indexOf(name) > -1} />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </div>
                  </Select>
                </FormControl>
              </Box> */}
              <LocalizationProvider
                dateAdapter={AdapterMoment}
                adapterLocale="en-gb"
              >
                <Box className="config-box-width-big margin-up-down">
                  <FormControl fullWidth>
                    <DatePicker
                      inputFormat="DD/MM/YYYY"
                      value={devStartDate || null}
                      onChange={(newValue) => {
                        if (newValue) {
                          setDevStartDate(newValue?.toString());
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputLabelProps={{ shrink: true }}
                          label="Dev Start Date"
                        />
                      )}
                    />
                  </FormControl>
                </Box>
              </LocalizationProvider>
              <Box className="margin-t10 config-box-width-big ">
                <FormControl fullWidth>
                  <Autocomplete
                    sx={{ minHeight: 70 }}
                    multiple
                    clearIcon={null}
                    popupIcon={null}
                    disableCloseOnSelect={true}
                    id="multiple-limit-tags"
                    options={skills}
                    getOptionLabel={(option) => option}
                    value={[]}
                    disablePortal={true}
                    onChange={(event: any, newValue: string[]) => {
                      if (
                        devSkills.length <= 6 ||
                        devSkills.includes(newValue[0])
                      ) {
                        handleChangeDevSkills(newValue[0]);
                      }
                    }}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 7 }}
                          checked={devSkills.includes(option)}
                        />
                        {option}
                        {hotSkills.map((hotSkill: any) => {
                          if (hotSkill.name === option) {
                            return (
                              <WhatshotIcon
                                key={hotSkill.name}
                                style={{
                                  color: "#ff6210",
                                  fontSize: 18,
                                  position: "relative",
                                  bottom: 3,
                                }}
                              />
                            );
                          }
                        })}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={devSkills?.length > 6}
                        helperText={errorMessageSkills}
                        label="Choose Developer Skills"
                        InputLabelProps={{ shrink: true }}
                      />
                    )}

                    // sx={{ maxHeight: "43px" }}
                  />
                </FormControl>
              </Box>
              <div className="skill-chips">
                {devSkills.map((skill: any) => {
                  return (
                    <>
                      <Chip
                        size="small"
                        key={skill}
                        label={skill}
                        onDelete={(newValue: string[]) => {
                          handleChangeDevSkills(skill);
                        }}
                      />
                    </>
                  );
                })}
              </div>

              {/* <Box className="config-box-width-big margin-up-down">
                <FormControl fullWidth>
                  <Autocomplete
                    multiple
                    size="small"
                    id="multiple-limit-tags"
                    options={skills}
                    getOptionLabel={(option) => option}
                    value={devSkills}
                    disablePortal={true}
                    onChange={(event: any, newValue: string[]) => {
                      setDevSkills(newValue);
                    }}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option}
                        {hotSkills.map((hotSkill: any) => {
                          if (hotSkill.name === option) {
                            return (
                              <WhatshotIcon
                                key={hotSkill.name}
                                style={{
                                  color: "#ff6210",
                                  marginBottom: 5,
                                }}
                              />
                            );
                          }
                        })}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Choose Developer Skills"
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                    renderTags={(value, getTagProps) => {
                      const numTags: number = value.length;
                      const limitTags = 1;

                      return (
                        <>
                          {value.slice(0, limitTags).map((option, index) => (
                            <Chip
                              {...getTagProps({ index })}
                              size="small"
                              key={option}
                              label={option}
                            />
                          ))}

                          {numTags > limitTags && ` +${numTags - limitTags}`}
                        </>
                      );
                    }}
                    sx={{ maxHeight: "43px" }}
                  />
                </FormControl>
              </Box> */}
              {/* 
              <Autocomplete
                className="config-box-width-big margin-up-down"
                id="country-select-demo"
                options={skills}
                getOptionLabel={(option) => option}
                value={devSkills}
                multiple
                limitTags={2}
                clearIcon={null}
                onChange={(event: any, newValue: string[]) => {
                  setDevSkills(newValue);
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Choose Developer Skills"
                    value={devSkills.join(", ")}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{
                      ...params.inputProps,
                      shrink: true,
                      defaultValue: devSkills.join(", "),
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
              /> */}

              <button
                className="text-Proxima Nova700-small orange margin-up-down"
                onClick={handleAddDeveloper}
                style={{
                  opacity:
                    devNumber === 0 ||
                    devHours === "" ||
                    devLevel === "" ||
                    devStartDate === "" ||
                    devDuration === 0 ||
                    devSkills.length === 0
                      ? 0.4
                      : 1,
                }}
                disabled={
                  devNumber === 0 ||
                  devHours === "" ||
                  devLevel === "" ||
                  devStartDate === "" ||
                  devDuration === 0 ||
                  devSkills.length === 0
                }
              >
                + Add developer and get estimate
              </button>
            </div>

            <div className={"configurator-bottom-right"}>
              <span className="text-Proxima Nova700 align-self-center">
                Resources:
              </span>
              <div className="configurator-column-list-scroll">
                {selectedDevs.map((dev: any, i: number) => {
                  return (
                    <div key={i} className="configurator-list-row-sb">
                      <div className="column-start-start">
                        <div className="row-start text-Proxima Nova-small">
                          <span className="text-Proxima Nova700-small margin-b5">
                            {dev.number_of_devs +
                              " " +
                              dev.developer_level +
                              " Developer" +
                              (parseInt(dev.number_of_devs) > 1 ? "s" : "") +
                              " for " +
                              dev.duration +
                              " Day" +
                              (parseInt(dev.duration) > 1 ? "s" : "") +
                              ", starting on " +
                              moment(dev.start_date).format("D MMM yy")}
                          </span>
                        </div>

                        <div className="row-start margin-b5">
                          <span className="text-Proxima Nova-small">
                            Skills:
                          </span>
                          <div className="row-start-start wrap">
                            {dev.skills.map((skill: string, j: number) => {
                              return (
                                <span
                                  key={skill}
                                  className="text-Proxima Nova-small darkgrey"
                                >
                                  &nbsp;{skill}{" "}
                                  {hotSkills.map((hotSkill: any) => {
                                    if (hotSkill.name === skill) {
                                      return (
                                        <WhatshotIcon
                                          key={hotSkill.name}
                                          style={{
                                            color: "#ff6210",
                                            fontSize: 18,
                                            position: "relative",
                                            bottom: 3,
                                          }}
                                        />
                                      );
                                    }
                                  })}
                                  {j < dev.skills.length - 1 ? " ," : ""}
                                </span>
                              );
                            })}
                          </div>
                        </div>

                        <div className="row-start">
                          <span className="text-Proxima Nova-small padding-r10">
                            Daily Cost:
                          </span>
                          <span className="text-Proxima Nova-small darkgrey">
                            {dev.number_of_devs +
                              " Developer" +
                              (parseInt(dev.number_of_devs) > 1 ? "s x" : " x")}
                            {dev.extra !== 0 ? " ( " : ""}
                            {dev.developer_level === "JUNIOR"
                              ? junior
                              : dev.developer_level === "MID"
                              ? mid
                              : senior}
                            €&nbsp;
                            {dev.extra !== 0
                              ? "+ " + dev.extra.toString() + "€ )"
                              : ""}{" "}
                            x&nbsp;
                            {dev.dev_hours === "FULL_TIME" ? 8 : 4}
                            &nbsp;hours&nbsp;={dev.daily}€
                          </span>{" "}
                        </div>

                        <div className="row-start text-Proxima Nova700-small padding-t1p">
                          <span className="text-Proxima Nova700-small padding-r10">
                            Cost:
                          </span>
                          <span className="text-Proxima Nova700-small">
                            {" "}
                            {dev.cost} €
                          </span>
                        </div>
                      </div>
                      <DeleteIcon
                        onClick={() => removeSelectedDev(i)}
                        style={{
                          cursor: "pointer",
                          color: "black",
                          alignSelf: "flex-end",
                        }}
                      />
                    </div>
                  );
                })}
                {selectedDevs.length === 0 && (
                  <div className="column-center  ">
                    <span className="text-Proxima Nova darkgrey text-align-left padding-normal">
                      Enter the number and specifications of the developers you
                      need and calculate your project's estimated cost.
                    </span>

                    <img
                      className="calculator-image"
                      src={require("../../assets/images/calculator-new.png")}
                      alt="developing vector"
                    />
                  </div>
                )}
              </div>
              {totalCost > 0 && (
                <span className="pricing-total-cost">
                  TOTAL COST: {totalCost} €
                </span>
              )}
            </div>
          </div>
          <div className="contact-row">
            <button className="button-black-small text-Proxima Nova700-small">
              <Link className=" signUpLink" to="/contact">
                Contact us
              </Link>
            </button>
          </div>
        </div>
      ) : (
        <div className="configurator-out">
          <div className="row-start padding-b20">
            <span className="text-Proxima Nova700">Project Configurator</span>
            <Tooltip title="Reset" placement="bottom" arrow>
              <UndoIcon
                className="add-lead-reload-icon blue pointer"
                onClick={reset}
              />
            </Tooltip>
          </div>
          <div className={"mob-configurator-grey-bottom"}>
            <div className="mob-configurator-bottom-left">
              <span className={"text-Proxima Nova700-small padding-b20"}>
                Specifications of the Developers
              </span>

              <div className="mob-config-row-space-between">
                <Box className="mob-config-box-width-big margin-up-down">
                  <FormControl fullWidth>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      type="number"
                      InputProps={{ inputProps: { min: 1 } }}
                      label="Number of Developers"
                      id="demo-simple-select"
                      value={devNumber}
                      onChange={(event) =>
                        setDevNumber(parseInt(event.target.value))
                      }
                    />
                  </FormControl>
                </Box>

                <Box className="mob-config-box-width-big margin-up-down">
                  <FormControl fullWidth>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      type="number"
                      InputProps={{ inputProps: { min: 1 } }}
                      id="demo-simple-select-duration"
                      label=" Project Days"
                      value={devDuration}
                      onChange={(event) =>
                        setDevDuration(parseInt(event.target.value))
                      }
                    />
                  </FormControl>
                </Box>
              </div>
              <div className="mob-config-row-space-between">
                <Box className="mob-config-box-width-big margin-up-down">
                  <FormControl fullWidth>
                    <InputLabel shrink={true} id="demo-simple-select-label">
                      Availability Needed
                    </InputLabel>
                    <Select
                      input={
                        <OutlinedInput
                          id="select-multiple-chip"
                          label="Availability Needed"
                          notched
                        />
                      }
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={devHours || ""}
                      label="SelectedProject"
                      onChange={(event) => setDevHours(event.target.value)}
                    >
                      <MenuItem value={"FULL_TIME"}>Full Time</MenuItem>
                      <MenuItem value={"PART_TIME"}>Part Time</MenuItem>
                    </Select>
                  </FormControl>
                </Box>

                {/* <span className={"text-Proxima Nova700-small padding-t7p"}>
            What skills do you need the developer to have?
          </span> */}

                <Box className="mob-config-box-width-big margin-up-down">
                  <FormControl fullWidth>
                    <InputLabel shrink={true} id="demo-simple-select-label">
                      Developer Level
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={devLevel || ""}
                      input={
                        <OutlinedInput
                          id="select-multiple-chip"
                          label="Developer Level"
                          notched
                        />
                      }
                      label="SelectedProject"
                      onChange={(event) => setDevLevel(event.target.value)}
                    >
                      <MenuItem value={"JUNIOR"}>Junior</MenuItem>
                      <MenuItem value={"MID"}>Mid</MenuItem>
                      <MenuItem value={"SENIOR"}>Senior</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </div>
              <div className="mob-config-row-space-between">
                <Box className="mob-config-box-width-big margin-up-down">
                  <FormControl fullWidth>
                    <InputLabel shrink={true} id="demo-simple-select-label">
                      Skills
                    </InputLabel>
                    <Select
                      input={
                        <OutlinedInput
                          id="select-multiple-chip"
                          label="Skills"
                          notched
                        />
                      }
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={devSkills}
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                    >
                      <div className="skills-select-menu">
                        {skills.map((name: any) => (
                          <MenuItem
                            key={name}
                            value={name}
                            onClick={() => handleChangeDevSkills(name)}
                          >
                            <Checkbox checked={devSkills.indexOf(name) > -1} />
                            <ListItemText primary={name} />
                          </MenuItem>
                        ))}
                      </div>
                    </Select>
                  </FormControl>
                </Box>
                <LocalizationProvider
                  dateAdapter={AdapterMoment}
                  adapterLocale="en-gb"
                >
                  <Box className="mob-config-box-width-big margin-up-down">
                    <FormControl fullWidth>
                      <DatePicker
                        inputFormat="DD/MM/YYYY"
                        value={devStartDate || null}
                        onChange={(newValue) => {
                          if (newValue) {
                            setDevStartDate(newValue?.toString());
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputLabelProps={{ shrink: true }}
                            label="Dev Start Date"
                          />
                        )}
                      />
                    </FormControl>
                  </Box>
                </LocalizationProvider>
                {/* <Box className="mob-config-box-width-big margin-up-down">
                  <FormControl fullWidth>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      type="date"
                      label="Dev Start Date"
                      InputProps={{
                        inputComponent: InputComponentDatePicker,
                      }}
                      id="demo-simple-select"
                      value={devStartDate}
                      onChange={(event) => setDevStartDate(event.target.value)}
                    />
                  </FormControl>
                </Box> */}
              </div>

              <button
                className="text-Proxima Nova700-small orange margin-up-down"
                onClick={handleAddDeveloper}
                style={{
                  alignSelf: "flex-start",
                  opacity:
                    devNumber === 0 ||
                    devHours === "" ||
                    devLevel === "" ||
                    devStartDate === "" ||
                    devDuration === 0 ||
                    devSkills.length === 0
                      ? 0.4
                      : 1,
                }}
                disabled={
                  devNumber === 0 ||
                  devHours === "" ||
                  devLevel === "" ||
                  devStartDate === "" ||
                  devDuration === 0 ||
                  devSkills.length === 0
                }
              >
                + Add developer and get estimate
              </button>
            </div>

            <div className={"mob-configurator-bottom-right"}>
              <span className="text-Proxima Nova700 align-self-center">
                Resources:
              </span>
              <div className="configurator-column-list-scroll">
                {selectedDevs.map((dev: any, i: number) => {
                  return (
                    <div key={i} className="mob-configurator-list-row-sb">
                      <div className="column-start-start">
                        <div className="row-start text-Proxima Nova-small">
                          <span className="text-Proxima Nova700-small">
                            {dev.number_of_devs +
                              " " +
                              dev.developer_level +
                              " Developer" +
                              (parseInt(dev.number_of_devs) > 1 ? "s" : "") +
                              " for " +
                              dev.duration +
                              " Day" +
                              (parseInt(dev.duration) > 1 ? "s" : "") +
                              ", starting on " +
                              moment(dev.start_date).format("D MMM yy")}
                          </span>
                        </div>
                        <div className="row-start wrap darkgrey">
                          <span className="text-Proxima Nova-small">
                            Skills:
                          </span>
                          {dev.skills.map((skill: string) => {
                            return (
                              <span
                                key={skill}
                                className="text-Proxima Nova-small"
                              >
                                &nbsp;{skill}{" "}
                                {hotSkills.map((hotSkill: any) => {
                                  if (hotSkill.name === skill) {
                                    return (
                                      <WhatshotIcon
                                        key={hotSkill.name}
                                        style={{
                                          color: "#ff6210",
                                          marginBottom: 5,
                                        }}
                                      />
                                    );
                                  }
                                })}
                              </span>
                            );
                          })}
                        </div>

                        <div className="row-start text-Proxima Nova700-small padding-t1p">
                          <span className="text-Proxima Nova-small padding-r10">
                            Daily Cost:
                          </span>
                          <span className="text-Proxima Nova-small">
                            {" "}
                            {dev.daily} €
                          </span>
                        </div>

                        <div className="row-start text-Proxima Nova700-small padding-t1p">
                          <span className="text-Proxima Nova700-small padding-r10">
                            Cost:
                          </span>
                          <span className="text-Proxima Nova700-small">
                            {" "}
                            {dev.cost} €
                          </span>
                        </div>
                      </div>
                      <DeleteIcon
                        onClick={() => removeSelectedDev(i)}
                        style={{
                          cursor: "pointer",
                          color: "black",
                          alignSelf: "flex-end",
                        }}
                      />
                    </div>
                  );
                })}
              </div>
              {totalCost > 0 && (
                <span className="pricing-total-cost">
                  TOTAL COST: {totalCost} €
                </span>
              )}
            </div>
          </div>
          <div className="mob-contact-row">
            <button className="button-black-small text-Proxima Nova700-small">
              <Link className=" signUpLink" to="/contact">
                Contact us
              </Link>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PricingConfigurator;
