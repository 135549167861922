import { useEffect, useState } from "react";
import "./InviteCandidates.css";
import PropTypes from "prop-types";
import axiosService from "../../utils/axios";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Input, TextareaAutosize, TextField } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAlert } from "react-alert";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import PublicProfile from "../../screens/public-profile/PublicProfile";
import Modal from "@mui/material/Modal";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const InviteCandidates = (props: any) => {
  const [appliedUsers, setAppliedUsers] = useState<any[]>([]);
  const [show, setShow] = useState(false);
  const alert = useAlert();
  const [showProfile, setShowProfile] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>();
  const [invitedDevs, setInvitedDevs] = useState<any[]>([]);
  const [availableUsers, setAvailableUsers] = useState<any[]>([]);
  const [isFirst, setIsFirst] = useState(true);
  const [selectedUsers, setSelectedUsers] = useState<any[]>([]);
  const auth = useSelector((state: RootState) => state.auth);

  const closeHandler = (e: any) => {
    setShow(false);
    props.onClose(false);
  };
  useEffect(() => {
    if (isFirst) {
      getAppliedUsers();
      getInvitedUsers();
      getAvailableUsers();
      setIsFirst(false);
    }

    setSelectedUsers([]);
  }, [props]);

  useEffect(() => {
    const close = (e: any) => {
      if (e.keyCode === 27) {
        closeHandler(true);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  const getAppliedUsers = () => {
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_applied_devs/` + props.job_id)
      .then((res) => {
        setAppliedUsers(res.data);
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };
  const getInvitedUsers = () => {
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_invited_devs/` + props.job_id)
      .then((res) => {
        setInvitedDevs(res.data);
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };
  const getAvailableUsers = () => {
    axiosService
      .get(
        `${process.env.REACT_APP_API_URL}/get_available_devs/` + props.job_id
      )
      .then((res) => {
        setAvailableUsers(res.data);
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };
  const inviteCandidates = () => {
    let tmp_proposed: number[] = [];
    availableUsers.forEach((user: any) => {
      if (selectedUsers.includes(user.username)) {
        tmp_proposed.push(user.id);
      }
    });
    let body = {
      invited_candidates: tmp_proposed,
      job_id: props.job_id,
    };
    axiosService
      .post(`${process.env.REACT_APP_API_URL}/add_user_opportunity`, body)
      .then((res) => {
        alert.success("The invite was sent");
        props.onClose(true);
      })

      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  const handleChangeProposedDevs = (username: string) => {
    if (selectedUsers.indexOf(username) > -1) {
      setSelectedUsers(
        selectedUsers.filter((user: any) => {
          return user !== username;
        })
      );
    } else {
      setSelectedUsers([...selectedUsers, username]);
    }
  };

  const showPublicProfile = (user: any) => {
    setShowProfile(true);
    setSelectedUser(user.id);
  };
  const closeProfile = () => {
    setShowProfile(false);
    setSelectedUser(null);
  };
  return (
    // <div
    //   style={{
    //     visibility: show ? "visible" : "hidden",
    //     opacity: show ? "1" : "0",
    //   }}
    //   className={"overlay"}
    // >
    <Modal
      open={show}
      onClose={closeHandler}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      {!showProfile ? (
        <div className={"popup-invite-candi"}>
          <div className="row-space-between-center padding-invite-candi-top">
            <span className="heading-lora padding-up-down">
              Invite Candidates
            </span>
            <span className={"close"} onClick={closeHandler}>
              &times;
            </span>
          </div>

          <span style={{ alignSelf: "center" }}>Job # {props.job_id}</span>
          <div className="column-space-between invite-candi-scroll">
            <div className="column-start-start ">
              {invitedDevs.map((user: any) => (
                <div className="userRfpp-row-with-line">
                  <div className="row-start-center">
                    {(!props.selected_candidates ||
                      props.selected_candidates.length === 0) && (
                      <Checkbox disabled checked={true} />
                    )}

                    {user.image.includes("media") ? (
                      <img
                        src={
                          process.env.REACT_APP_API_URL?.split("/api")[0] +
                          user.image
                        }
                        className="profile-img"
                      />
                    ) : (
                      <div className="profile-img no-pic">
                        <span>{user.username.charAt(0)}</span>
                      </div>
                    )}

                    <span className="text-Proxima Nova700 darkgrey padding-normal">
                      {user.username}
                    </span>
                  </div>
                  <ChevronRightIcon
                    className="pointer"
                    onClick={() => showPublicProfile(user)}
                  />
                </div>
              ))}
              {availableUsers.map((user: any) => (
                <div className="userRfpp-row-with-line">
                  <div className="row-start-center">
                    {(!props.selected_candidates ||
                      props.selected_candidates.length === 0) && (
                      <Checkbox
                        onChange={() => handleChangeProposedDevs(user.username)}
                        checked={selectedUsers.indexOf(user.username) > -1}
                      />
                    )}

                    {user.image.includes("media") ? (
                      <img
                        src={
                          process.env.REACT_APP_API_URL?.split("/api")[0] +
                          user.image
                        }
                        className="profile-img"
                      />
                    ) : (
                      <div className="profile-img no-pic">
                        <span>{user.username.charAt(0)}</span>
                      </div>
                    )}

                    <span className="text-Proxima Nova700 darkgrey padding-normal">
                      {user.username}
                    </span>
                  </div>
                  {/* <Link
                        to={{ pathname: "/platform/public-profile" }}
                        state={user.id}
                      >
                        <ChevronRightIcon />
                      </Link> */}
                  <ChevronRightIcon
                    onClick={() => showPublicProfile(user)}
                    className="pointer"
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="row-flex-end-end margin-t20 padding-invite-candi-top">
            <button
              className="button-white-small margin-r20 text-Proxima Nova700-small"
              onClick={() => props.onClose(true)}
            >
              {" "}
              Cancel
            </button>
            <button
              className="button-black-small-nm text-Proxima Nova700-small"
              onClick={inviteCandidates}
            >
              {" "}
              Invite Candidates
            </button>
          </div>
        </div>
      ) : (
        <div className={"invite-candi-to-pub-prof margin-b50"}>
          <div className="row-space-between-center">
            <ChevronLeftIcon onClick={closeProfile} className="pointer" />
            <span className={"close"} onClick={closeHandler}>
              &times;
            </span>
          </div>
          <PublicProfile user={selectedUser} />
        </div>
      )}
    </Modal>
  );
};

InviteCandidates.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  job_id: PropTypes.number.isRequired,
};

export default InviteCandidates;
