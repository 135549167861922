import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "./BlogList.css";
import moment from "moment";
import locationSlice from "../../store/slices/location";
import { useDispatch } from "react-redux";

interface Blog {
  id: number;
  created: string;
  title: string;
  file_url: string;
  image_preview_url?: string;
  main_image_url?: string;
}

const BlogList: React.FC = () => {
  const [blogs, setBlogs] = useState<Blog[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(locationSlice.actions.setLocation({ path: "/blogs" }));
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/get_blogs`)
      .then((response) => {
        setBlogs(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the blogs!", error);
      });
  }, []);

  return (
    <div className="container">
      <h1 className="home-title">Welcome to Gyld's Developer Blog</h1>
      <p
        className="text-Proxima Nova darkgrey text-align-left "
        style={{ marginBottom: 12 }}
      >
        Dive into the world of web development with insights, experiences, and
        stories from our passionate developers. Stay updated with the latest
        trends, learn new skills, and get inspired by the challenges we overcome
        every day.
      </p>
      <ul className="blog-list">
        {blogs.map((blog) => (
          <li key={blog.id} className="blog-item">
            {blog.image_preview_url && (
              <Link to={`/blogs/${blog.id}`} className="blog-link">
                <img
                  src={
                    process.env.REACT_APP_API_URL?.split("/api")[0] +
                    blog.image_preview_url
                  }
                  alt={blog.title}
                  className="blog-image-preview"
                />
              </Link>
            )}
            <Link to={`/blogs/${blog.id}`} className="blog-link">
              <span className=" home-card-text-plain lightgrey padding-up-down">
                {blog.title}
              </span>
            </Link>
            <span className="small-Proxima Nova-grey align-self-start">
              {moment(blog.created).format("MMMM Do, YYYY")}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default BlogList;
