import React, { useState, useEffect } from "react";
import Moment from "moment";
import "./PhaseCard.css";
import axiosService from "../../utils/axios";
import { useAlert } from "react-alert";
import Tooltip from "@mui/material/Tooltip";
import EditIcon from "@mui/icons-material/Edit";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import DeleteIcon from "@mui/icons-material/Delete";

function PhaseCard(props: any) {
  const auth = useSelector((state: RootState) => state.auth);

  const alert = useAlert();

  const deletePhase = () => {
    axiosService
      .delete(`${process.env.REACT_APP_API_URL}/delete_phase/` + props.phase.id)
      .then((res) => {
        props.getProjects();
        alert.success("The phase was deleted.");
      })

      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };
  return (
    <div
      className={
        props.highlight
          ? "opp-box-phases-half full-percent-width highlight-selected"
          : "opp-box-phases-half full-percent-width"
      }
    >
      <span className="text-Proxima Nova700">{props.phase.name}</span>

      <div className="opp-box-row">
        <span
          style={{
            color: props.phase.status === "BREACHED" ? "#c47878" : "#767fad",
          }}
          className="opp-status"
        >
          Status:{" "}
          {props.phase.status
            .toLowerCase()
            .replace(/_/g, " ")
            .charAt(0)
            .toUpperCase() +
            props.phase.status.toLowerCase().replace(/_/g, " ").slice(1)}
        </span>
        {window.innerWidth > 1200 && <div className="ball"></div>}
        <span className="opp-status">
          {" "}
          {Moment(props.phase.start_date).format("D MMM yy")} -{" "}
          {Moment(props.phase.end_date).format("D MMM yy")}
        </span>
      </div>

      <span className="text-Proxima Nova-small opp-grey">
        {props.phase.description}
      </span>
      <span className="opp-status">Challenges: {props.phase.challenges}</span>
      <span className="opp-status">
        Completion Rate: {props.phase.completion} %
      </span>

      <span className="opp-status"></span>
      {auth.selectedView === "DEVELOPER" && (
        <div className="opp-box-end-end">
          <div className="circle-left">
            <Tooltip title="Edit" placement="bottom" arrow>
              <EditIcon
                onClick={() => props.handleEdit(props.phase.id)}
                style={{ color: "#626DA1", cursor: "pointer" }}
              />
            </Tooltip>
          </div>
          <div className="circle-right">
            <Tooltip title="Delete" placement="bottom" arrow>
              <DeleteIcon
                onClick={deletePhase}
                style={{
                  color: "#626DA1",
                  cursor: "pointer",
                }}
              />
            </Tooltip>
          </div>
        </div>
      )}
    </div>
  );
}

export default PhaseCard;
