import { useEffect, useState } from "react";
import "./AddClient.css";
import PropTypes from "prop-types";
import axiosService from "../../utils/axios";
import Box from "@mui/material/Box";
import { useAlert } from "react-alert";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Switch from "@mui/material/Switch";
import Modal from "@mui/material/Modal";

import {
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";

import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const styles = {
  helper_available: {
    color: "#5db15c",
    fontSize: ".8em",
  },
  helper_unavailable: {
    color: "#c6044a",
    fontSize: ".8em",
  },
};

const AddClient = (props: any) => {
  const [show, setShow] = useState(false);
  const [users, setUsers] = useState<any[]>([]);
  const [adminUser, setAdminUser] = useState("");

  const alert = useAlert();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");

  const [isUserNameAvailable, setIsUsernameAvailable] = useState<
    boolean | null
  >();

  const [isFirst, setIsFirst] = useState(true);

  const auth = useSelector((state: RootState) => state.auth);

  const closeHandler = () => {
    setShow(false);
    props.onClose(false);
  };

  useEffect(() => {
    const close = (e: any) => {
      if (e.keyCode === 27) {
        closeHandler();
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  useEffect(() => {
    if (isFirst) {
      setIsFirst(false);
    }
    getUsers();
    reset();
  }, []);
  useEffect(() => {
    if (username !== "") {
      checkUsername();
    }
  }, [username]);

  const getUsers = () => {
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_users`)
      .then((res) => {
        setUsers(res.data);
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };

  const checkUsername = () => {
    axiosService
      .post(`${process.env.REACT_APP_API_URL}/check_username_client`, {
        username: username,
      })
      .then((res) => {
        setIsUsernameAvailable(res.data);
      })

      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };

  const addClient = () => {
    let tmp_admin = 0;
    users.forEach((user: any) => {
      if (user.username === adminUser) {
        tmp_admin = user.id;
      }
    });
    if (tmp_admin !== 0) {
      let body = {
        admin_user: tmp_admin,
        username: username,
        name: name,
        email_contact: email,
      };
      axiosService
        .post(`${process.env.REACT_APP_API_URL}/add_client`, body)
        .then(() => {
          alert.success("The Client was added");
          props.onClose(true);
        })

        .catch((err) => {
          if (err.response?.status !== 500) {
            if (err.response) {
              Object.keys(err.response.data).forEach(function (key, index) {
                alert.error(key + ":" + err.response.data[key]);
              });
            } else {
              alert.error(err.message);
            }
          }

          console.log(err);
        });
    }
  };

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  const reset = () => {
    setName("");
    setUsername("");
    setAdminUser("");
    setEmail("");
  };

  return (
    <Modal
      open={show}
      onClose={closeHandler}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={"popup-add-client"}>
        <div className="row-space-between-center">
          <h2>Add a new Client</h2>
          <span className={"close"} onClick={closeHandler}>
            &times;
          </span>
        </div>
        <div className={"content "}>
          <button className="reset-button" onClick={reset}>
            Reset
          </button>
          <div className="row-start full-percent-height">
            <div className="column-flex-space-between half-percent-width padding-normal">
              <div className="row-space-between padding-normal">
                <Box className="margin-r20" sx={{ minWidth: 200 }}>
                  <FormControl fullWidth>
                    {/* <InputLabel id="demo-simple-select-label">
                      Start Date
                    </InputLabel> */}
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      label="Name"
                      id="first-name-add-user"
                      value={name}
                      onChange={(event) => setName(event.target.value)}
                    />
                  </FormControl>
                </Box>
                <Box sx={{ minWidth: 200 }}>
                  <FormControl fullWidth>
                    {/* <InputLabel id="demo-simple-select-label">
                      Start Date
                    </InputLabel> */}
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      label="Email"
                      id="last-name-add-user"
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                    />
                  </FormControl>
                </Box>
              </div>
              <div className="row-space-between padding-normal">
                <Box className="margin-r20" sx={{ minWidth: 200 }}>
                  <FormControl fullWidth>
                    {/* <InputLabel id="demo-simple-select-label">
                      Start Date
                    </InputLabel> */}
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      label="Username"
                      id="username-add-user"
                      helperText={
                        isUserNameAvailable
                          ? "The username is available"
                          : isUserNameAvailable === false
                          ? "The username is already used"
                          : ""
                      }
                      value={username}
                      FormHelperTextProps={{
                        style: isUserNameAvailable
                          ? styles.helper_available
                          : styles.helper_unavailable,
                      }}
                      onChange={(event) => setUsername(event.target.value)}
                    />
                  </FormControl>
                </Box>
                <Box sx={{ minWidth: 200 }}>
                  <FormControl fullWidth>
                    <InputLabel shrink={true} id="demo-simple-select-label">
                      Admin User
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={adminUser}
                      input={
                        <OutlinedInput
                          id="select-multiple-chip"
                          label="Admin User"
                          notched
                        />
                      }
                      label="SelectedProject"
                      onChange={(event) => setAdminUser(event.target.value)}
                    >
                      {users &&
                        users.length > 0 &&
                        users.map((user: any) => {
                          return (
                            <MenuItem key={user.id} value={user.username}>
                              {user.username}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Box>
              </div>
            </div>
          </div>
        </div>

        <div className="add-phase-row-flex-end">
          <button
            className="button-white-small text-Proxima Nova700-small margin-r20"
            onClick={() => props.onClose(true)}
          >
            {" "}
            Cancel
          </button>

          <button
            className="button-black-small-nm text-Proxima Nova700-small"
            onClick={addClient}
          >
            {" "}
            Save Client
          </button>
        </div>
      </div>
    </Modal>
  );
};

AddClient.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AddClient;
