import React, { useState, useEffect } from "react";
import "./Privacy.css";
import Box from "@mui/material/Box";
import locationSlice from "../../store/slices/location";
import { useDispatch } from "react-redux";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const Privacy = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(locationSlice.actions.setLocation({ path: "/privacy-policy" }));
  }, []);

  return (
    <div className="container">
      <div className="column-start-center">
        <div
          className="dev-row-space-between-center full-percent-width"
          style={{ paddingBottom: 20 }}
        >
          <div className="dev-leftSideHeader">
            <strong className="home-title">Privacy Policy</strong>
          </div>
        </div>
        <p className="text-Proxima Nova darkgrey margin-b40">
          Effective Date: 01/05/2022 <br />
          <br />
          The Gyld ("we," "our," "us") is committed to protecting your privacy.
          This Privacy Policy explains how we collect, use, and safeguard your
          information when you visit our website gyldhub.com (the "Site").{" "}
          <br /> Please read this Privacy Policy carefully. If you do not agree
          with the terms of this privacy policy, please do not access the site.
          <br />
          <br />
          <b> 1. Information We Collect When you visit our Site</b>
          <br />
          We may collect the following information: Personal Information: When
          you contact us through our contact form, we collect your first name,
          last name, and email address.
          <br />
          <br /> <b>2. Use of Your Information </b> <br />
          We use the information we collect in the following ways: To respond to
          your inquiries and provide you with support. To communicate with you
          about our services and updates. To improve our website and user
          experience. <br />
          <br /> <b>3. Google Analytics</b> <br /> We use Google Analytics to
          collect information about your use of the Site. Google Analytics
          collects information such as how often users visit the Site, what
          pages they visit, and what other sites they used prior to coming to
          the Site. We use the information from Google Analytics to understand
          and improve our Site's performance. Google Analytics collects only the
          IP address assigned to you on the date you visit the Site, rather than
          your name or other identifying information. We do not combine the
          information collected through the use of Google Analytics with
          personally identifiable information. Google’s ability to use and share
          information collected by Google Analytics about your visits to this
          Site is restricted by the Google Analytics Terms of Use and the Google
          Privacy Policy. You can prevent Google Analytics from recognizing you
          on return visits to this Site by disabling cookies on your browser.{" "}
          <br />
          <br />
          <b> 4. Data Security</b> We take reasonable measures to protect the
          information you provide to us through the Site. However, please be
          aware that no security measures are perfect or impenetrable, and we
          cannot guarantee the security of your data. <br />
          <br />
          <b> 5. Your Rights </b> <br /> You have the right to access, correct,
          or delete your personal information that we hold. If you wish to
          exercise these rights, please contact us at office@gyld.me. <br />
          <br />
          <b>6. Changes to This Privacy Policy </b> We may update this Privacy
          Policy from time to time. Any changes will be effective immediately
          upon posting the updated policy on the Site. We encourage you to
          review this Privacy Policy periodically to stay informed about how we
          are protecting your information. <br />
          <br />
          <b> 7. Contact Us </b> <br />
          If you have any questions or concerns about this Privacy Policy,
          please contact us at office@gyld.me.
        </p>
      </div>
    </div>
  );
};

export default Privacy;
