import React, { useEffect } from "react";
import locationSlice from "../../store/slices/location";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Carousel from "react-material-ui-carousel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import "./Home.css";
// import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useTheme } from "styled-components";

const Home = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(locationSlice.actions.setLocation({ path: "/" }));
  }, []);

  const clients = ["paddington", "enercity", "matterz", "newdeal", "cleopatra"];

  const theme = useTheme();
  const isMobile = useMediaQuery("600px");
  const isTablet = useMediaQuery("1000px");

  const chunkSize = isTablet ? (isMobile ? 1 : 2) : 3;

  function groupIntoChunks(array: any, chunkSize: any) {
    const output: any = [];
    let currentChunk: any = [];

    array.forEach((item: any, index: number) => {
      currentChunk.push(item);

      if ((index + 1) % chunkSize === 0 || index === array.length - 1) {
        output.push(currentChunk);
        currentChunk = [];
      }
    });

    return output;
  }

  return (
    <div className="container">
      {/* {window.innerWidth > 800 && ( */}
      <Carousel
        height={window.innerWidth < 800 ? 870 : 600}
        interval={7000}
        className="carousel"
      >
        <div className="headerBar">
          <div className="leftSideHeader">
            <strong className="home-title">
              Our Developers, Your Advantage
            </strong>
            <span className="home-subtitle lightgrey padding-up-down">
              Meet the passionate coders behind your success
            </span>
            <span className="text-Proxima Nova darkgrey text-align-left">
              At Gyld, our developers are the heart of our company. They bring
              their enthusiasm for coding and expertise to every project,
              ensuring exceptional results. Experience our transparent pricing
              and dedicated team.{" "}
            </span>
            <div className="carousel-buttons">
              <button className="button-black-small text-Proxima Nova700-small nowrap">
                <Link className=" signUpLink" to="/contact">
                  Contact us{" "}
                </Link>
              </button>
            </div>
          </div>
          <img
            className="image"
            src={require("../../assets/images/carousel1.png")}
            alt="developing vector"
          />
        </div>
        <div className="headerBar">
          <div className="leftSideHeader">
            <strong className="home-title">Seamless Team Collaboration </strong>
            <span className="home-subtitle lightgrey padding-up-down">
              Quality assured by a cohesive team
            </span>
            <span className="text-Proxima Nova darkgrey text-align-left">
              Our developers excel in both individual skills and team
              collaboration. Each member is rigorously vetted and continuously
              supported, guaranteeing you the best performance on your projects.
            </span>
            <div className="carousel-buttons">
              <button className="button-black-small text-Proxima Nova700-small nowrap">
                <Link className=" signUpLink" to="/contact">
                  Contact us
                </Link>
              </button>
            </div>
          </div>
          <img
            className="image"
            src={require("../../assets/images/carousel2.png")}
            alt="developing vector"
          />
        </div>
        <div className="headerBar">
          <div className="leftSideHeader">
            <strong className="home-title">Holistic Project Management</strong>
            <span className="home-subtitle lightgrey padding-up-down">
              We handle everything, you reap the benefits
            </span>
            <span className="text-Proxima Nova darkgrey text-align-left">
              Gyld manages all aspects of your project, from contracts to
              invoicing and payments. We provide mediation and support in
              emergencies, ensuring you can focus solely on your project’s
              success.
            </span>
            <div className="carousel-buttons">
              <button className="button-black-small text-Proxima Nova700-small nowrap">
                <Link className=" signUpLink" to="/contact">
                  Contact us
                </Link>
              </button>
            </div>
          </div>
          <img
            className="image"
            src={require("../../assets/images/carousel3.png")}
            alt="developing vector"
          />
        </div>
        <div className="headerBar">
          <div className="leftSideHeader">
            <strong className="home-title">Agility and Reliability</strong>
            <span className="home-subtitle lightgrey padding-up-down">
              Swift adaptation to your needs
            </span>
            <span className="text-Proxima Nova darkgrey text-align-left">
              Our team is prepared for any changes that might occur. Gyld
              ensures quick replacements and seamless transitions, keeping your
              project on track without interruptions.
            </span>
            <div className="carousel-buttons">
              <button className="button-black-small text-Proxima Nova700-small nowrap">
                <Link className=" signUpLink" to="/contact">
                  Contact us
                </Link>
              </button>
            </div>
          </div>
          <img
            className="image"
            src={require("../../assets/images/carousel4.png")}
            alt="developing vector"
          />
        </div>
      </Carousel>

      <div className="column-flex-start">
        <div className="home-card-out">
          <div className="home-card-two">
            <div className="home-card margin-right">
              <img
                className="card-image"
                src={require("../../assets/images/blue-waves.png")}
                alt="developing vector"
              />
              <div className="home-card-inner">
                <strong className="home-card-title">
                  Take the hassle out of hiring
                </strong>

                <span className="text-Proxima Nova lightgrey">
                  Let us know your project requirements, and we’ll connect you
                  with the ideal developers. We provide immediate cost estimates
                  and honest feedback to ensure the best fit.
                </span>
                <span className="home-card-text lightgrey">
                  Your satisfaction is our priority, and we strive to meet your
                  needs with integrity.
                </span>
              </div>
            </div>

            <div className="home-card">
              <img
                className="card-image"
                src={require("../../assets/images/blue-waves.png")}
                alt="developing vector"
              />
              <div className="home-card-inner">
                <strong className="home-card-title">
                  Expertly Matched Developers
                </strong>
                <span className="text-Proxima Nova lightgrey">
                  We handpick developers who are the perfect fit for your
                  project. Our rigorous selection process ensures you get the
                  best talent tailored to your specific needs.
                </span>
                <span className="home-card-text lightgrey">
                  Our dedicated team guarantees the best match for your
                  project's unique requirements.
                </span>
              </div>
            </div>
          </div>

          <div className="home-card-two">
            <div className="home-card margin-right">
              <img
                className="card-image"
                src={require("../../assets/images/blue-waves.png")}
                alt="developing vector"
              />
              <div className="home-card-inner">
                <strong className="home-card-title">
                  Comprehensive Project Oversight
                </strong>
                <span className="text-Proxima Nova lightgrey">
                  Gyld provides end-to-end project management. We handle
                  everything from planning to execution, ensuring your project
                  runs smoothly and efficiently.
                </span>
                <span className="home-card-text lightgrey">
                  Our comprehensive oversight ensures that your project is
                  managed with precision and care.{" "}
                </span>
              </div>
            </div>

            <div className="home-card">
              <img
                className="card-image"
                src={require("../../assets/images/blue-waves.png")}
                alt="developing vector"
              />
              <div className="home-card-inner">
                <strong className="home-card-title">Earn with Referrals</strong>
                <span className="text-Proxima Nova lightgrey">
                  Join our Lead Share program and earn rewards for providing
                  qualified leads. Whether you're a client, developer, or
                  business connector, your referrals are valuable to us.
                </span>
                <span className="home-card-text lightgrey">
                  Help us grow our network and earn rewards for your valuable
                  referrals.
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="what-gyld">
          <img
            className="what-image"
            src={require("../../assets/images/img4.png")}
            alt="developing vector"
          />
          <div className="right-side-what-gyld">
            <strong className="home-title margin-b20">What’s The Gyld?</strong>
            <div className="what-column-space-between">
              <div>
                <div className="roww">
                  <CheckCircleIcon className="green-check-icon" />
                  <div className="roww-pad">
                    <span className="what-subtitle">
                      Developer-Centric Network
                    </span>
                  </div>
                </div>
              </div>
              <span className="what-text">
                Our strength lies in our team of exceptional developers who work
                seamlessly together to deliver outstanding results.
              </span>

              <div>
                <CheckCircleIcon className="green-check-icon" />
                <span className="what-subtitle">Continuous Support</span>
              </div>
              <span className="what-text">
                Our developers benefit from ongoing mentorship and a robust
                support system.
              </span>
              <div>
                <CheckCircleIcon className="green-check-icon" />
                <span className="what-subtitle">Managed Excellence</span>
              </div>
              <span className="what-text">
                Gyld managers take care of all logistical details, ensuring your
                project is executed smoothly and efficiently.
              </span>
            </div>
            {/* <div className="what-gyld-end">
              <span className="home-text padding-r10">
                Think it‘s a match? &nbsp;
              </span>
              <button className="button-black-small text-Proxima Nova700-small nowrap">
                <Link className=" signUpLink" to="/contact">
                  Request Sign Up
                </Link>
              </button>
            </div> */}
          </div>
        </div>

        <div className="cta">
          <div className="left-side-cta">
            <strong className="home-title">Get a project rebate!</strong>
            <span className="home-subtitle lightgrey padding-up-down">
              Participate in our lead share program to be rewarded a finder‘s
              fee or project rebate.
            </span>
            <button className="button-black-small text-Proxima Nova700-small nowrap">
              <Link className=" signUpLink" to="/contact">
                Request Sign Up
              </Link>
            </button>
          </div>
          <img
            className="cta-image"
            src={require("../../assets/images/img1-1.png")}
            alt="developing vector"
          />
        </div>
      </div>

      {window.innerWidth > 1000 ? (
        <div className="clients-section-desktop margin-t30">
          <div className="row-space-between-center">
            <a
              href="https://paddington.co/ubillit-cloud-billing/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="client-logo"
                alt="paddington"
                src={require("../../assets/images/paddington.png")}
              ></img>
            </a>

            <a
              href="https://www.enercity.de/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="client-logo"
                alt="enercity"
                src={require("../../assets/images/enercity.png")}
              ></img>
            </a>
            <a
              href="https://www.orbito.travel/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="client-logo"
                alt="orbito"
                src={require("../../assets/images/orbito.png")}
              ></img>{" "}
            </a>
            <a
              href="https://price.newdeal.gr/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="client-logo"
                alt="newdeal-logo"
                src={require("../../assets/images/newdeal.png")}
              ></img>
            </a>
            <a
              href="https://thecleopatra.club"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="client-logo-md"
                alt="cleopatra-logo"
                src={require("../../assets/images/cleopatra-coin.png")}
              ></img>
            </a>
            <img
              className="client-logo-sm"
              alt="matterz"
              src={require("../../assets/images/matterz.png")}
            ></img>
          </div>
          <div className="clients-left margin-t30 margin-b30">
            <p className="text-Proxima Nova darkgrey padding-up-down">
              {" "}
              Join the ranks of our satisfied clients and experience the
              difference that our dedicated team of developers can make for your
              business. Our portfolio spans various industries, reflecting our
              adaptability and expertise in tackling unique challenges. From
              startups to established enterprises, we've partnered with clients
              to deliver innovative and scalable solutions that drive growth and
              success.
            </p>
            <p className="text-Proxima Nova darkgrey padding-up-down">
              At The Gyld, we believe in building lasting relationships founded
              on trust, transparency, and exceptional performance. Our clients
              appreciate our collaborative approach, attention to detail, and
              commitment to exceeding expectations. Let’s create something
              extraordinary together and transform your vision into reality with
              the power of cutting-edge technology and a passionate team.
            </p>
          </div>
        </div>
      ) : (
        <div className="clients-section margin-b20">
          <Carousel
            height={150}
            interval={7000}
            className="carousel-client"
            indicators={false}
          >
            <div className="carousel-inner">
              <a
                href="https://paddington.co/ubillit-cloud-billing/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="client-logo"
                  alt="paddington"
                  src={require("../../assets/images/paddington.png")}
                ></img>
              </a>
            </div>
            <div className="carousel-inner">
              <a
                href="https://www.enercity.de/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="client-logo"
                  alt="enercity"
                  src={require("../../assets/images/enercity.png")}
                ></img>{" "}
              </a>
            </div>

            <div className="carousel-inner">
              <a
                href="https://www.orbito.travel/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="client-logo"
                  alt="orbito"
                  src={require("../../assets/images/orbito.png")}
                ></img>{" "}
              </a>
            </div>
            <div className="carousel-inner">
              <a
                href="https://price.newdeal.gr/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="client-logo"
                  alt="newdeal-logo"
                  src={require("../../assets/images/newdeal.png")}
                ></img>{" "}
              </a>
            </div>
            <div className="carousel-inner">
              <a
                href="https://thecleopatra.club"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="client-logo-sm"
                  alt="cleopatra-logo"
                  src={require("../../assets/images/cleopatra-coin.png")}
                ></img>
              </a>
            </div>
            <div className="carousel-inner">
              <img
                className="client-logo-sm"
                alt="matterz"
                src={require("../../assets/images/matterz.png")}
              ></img>
            </div>
          </Carousel>
          <div className="clients-left">
            <span className="text-Proxima Nova darkgrey padding-up-down">
              {" "}
              Join the ranks of our satisfied clients and experience the
              difference that our dedicated team of developers can make for your
              business. Let's create something extraordinary together.
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
