import { useEffect, useState } from "react";
import "./AddPhase.css";
import PropTypes from "prop-types";
import axiosService from "../../utils/axios";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Input, TextareaAutosize, TextField } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "moment/locale/en-gb";

import { useAlert } from "react-alert";
import Modal from "@mui/material/Modal";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AddPhase = (props: any) => {
  const [show, setShow] = useState(false);
  const [nameOfPhase, setNameOfPhase] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [predecessors, setPredecessors] = useState<Array<number>>([]);
  const [status, setStatus] = useState("");
  const [completion, setCompletion] = useState(0);

  const [description, setDescription] = useState("");
  const [challenges, setChallenges] = useState("");

  const [isFirst, setIsFirst] = useState(true);
  const alert = useAlert();

  const auth = useSelector((state: RootState) => state.auth);

  const closeHandler = (e: any) => {
    setShow(false);
    props.onClose(false);
  };

  useEffect(() => {
    const close = (e: any) => {
      if (e.keyCode === 27) {
        closeHandler(true);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);
  useEffect(() => {
    if (isFirst) {
      setIsFirst(false);
    }
    if (props.phase_id) {
      props.phases.forEach((phase: any) => {
        if (props.phase_id === phase.id) {
          setNameOfPhase(phase.name);
          setStartDate(phase.start_date);
          setEndDate(phase.end_date);
          setDescription(phase.description);
          setStatus(phase.status);
          setCompletion(phase.completion);
          setPredecessors(phase.predecessors);
        }
      });
    } else {
      setNameOfPhase("");
      setStartDate("");
      setEndDate("");
      setDescription("");
      setStatus("");
      setCompletion(0);
      setPredecessors([]);
    }
  }, []);

  const addPhase = () => {
    if (props.phase_id) {
      let body = {
        name: nameOfPhase,
        description: description,
        status: status,
        predecessors: predecessors,
        completion: completion,
        start_date: moment(startDate).format("YYYY-MM-DD").toString(),
        end_date: moment(endDate).format("YYYY-MM-DD").toString(),
        challenges: challenges,
        project_id: props.project_id,
        id: props.phase_id,
      };
      axiosService
        .post(`${process.env.REACT_APP_API_URL}/edit_phase`, body)
        .then((res) => {
          alert.success("The phase was updated");
          props.onClose(true);
        })

        .catch((err) => {
          if (err.response?.status !== 500) {
            if (err.response) {
              Object.keys(err.response.data).forEach(function (key, index) {
                alert.error(key + ":" + err.response.data[key]);
              });
            } else {
              alert.error(err.message);
            }
          }

          console.log(err);
        });
    } else {
      let body = {
        name: nameOfPhase,
        description: description,
        status: status,
        predecessors: predecessors,
        completion: completion,
        start_date: moment(startDate).format("YYYY-MM-DD").toString(),
        end_date: moment(endDate).format("YYYY-MM-DD").toString(),
        challenges: challenges,
        project_id: props.project_id,
      };
      axiosService
        .post(`${process.env.REACT_APP_API_URL}/add_phase`, body)
        .then((res) => {
          alert.success("The Phase was added");
          props.onClose(true);
        })

        .catch((err) => {
          if (err.response?.status !== 500) {
            if (err.response) {
              Object.keys(err.response.data).forEach(function (key, index) {
                alert.error(key + ":" + err.response.data[key]);
              });
            } else {
              alert.error(err.message);
            }
          }

          console.log(err);
        });
    }
  };

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  const handleChangePredeccesors = (
    event: SelectChangeEvent<typeof predecessors>
  ) => {
    const {
      target: { value },
    } = event;

    let tmp: number[] = [];
    if (typeof value === "string") {
      value.split(",").forEach((val: string) => {
        tmp.push(parseInt(val));
      });
    } else {
      tmp = value;
    }
    setPredecessors(tmp);
  };

  return (
    // <div
    //   style={{
    //     visibility: show ? "visible" : "hidden",
    //     opacity: show ? "1" : "0",
    //   }}
    //   className={"overlay"}
    // >
    <Modal
      open={show}
      onClose={closeHandler}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={"popup-phase"}>
        <div className={"phase-scroll"}>
          <div className="row-space-between-center">
            <span className="text-Proxima Nova700">Phase</span>
            <span className={"close"} onClick={closeHandler}>
              &times;
            </span>
          </div>

          <Box className="add-phase-full-width padding-up-down">
            <FormControl fullWidth>
              <TextField
                InputLabelProps={{ shrink: true }}
                label="Name of Phase"
                id="outlined-basic"
                type="text"
                value={nameOfPhase}
                onChange={(event) => setNameOfPhase(event.target.value)}
              />
            </FormControl>
          </Box>

          <div className="row-space-between padding-up-down">
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              adapterLocale="en-gb"
            >
              <Box className="add-phase-half-width">
                <FormControl fullWidth>
                  <DatePicker
                    inputFormat="DD/MM/YYYY"
                    value={startDate || null}
                    onChange={(newValue) => {
                      if (newValue) {
                        setStartDate(newValue?.toString());
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{ shrink: true }}
                        label="Start Date"
                      />
                    )}
                  />
                </FormControl>
              </Box>
              <Box className="add-phase-half-width">
                <FormControl fullWidth>
                  <DatePicker
                    inputFormat="DD/MM/YYYY"
                    value={endDate || null}
                    minDate={moment(startDate)}
                    onChange={(newValue) => {
                      if (newValue) {
                        setEndDate(newValue?.toString());
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{ shrink: true }}
                        label="End Date"
                      />
                    )}
                  />
                </FormControl>
              </Box>
            </LocalizationProvider>
            {/* <Box className="add-phase-half-width">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label"></InputLabel>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  type="date"
                  InputProps={{
                    inputComponent: InputComponentDatePicker,
                  }}
                  label="Start Date"
                  id="demo-simple-select"
                  value={startDate || ""}
                  onChange={(event) => setStartDate(event.target.value)}
                />
              </FormControl>
            </Box>
            <Box className="add-phase-half-width">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label"></InputLabel>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  type="date"
                  label="End Date"
                  id="demo-simple-select"
                  value={endDate}
                  InputProps={{
                    inputComponent: InputComponentDatePicker,
                    inputProps: { min: startDate },
                  }}
                  onChange={(event) => setEndDate(event.target.value)}
                />
              </FormControl>
            </Box> */}
          </div>

          <Box className="add-phase-full-width padding-up-down">
            <FormControl fullWidth>
              <InputLabel shrink={true} id="demo-simple-select-label">
                Predecessors
              </InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={predecessors}
                onChange={handleChangePredeccesors}
                input={
                  <OutlinedInput
                    id="select-multiple-chip"
                    label="Predecessors"
                    notched
                  />
                }
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
              >
                {props.phases.map((phase: any) => (
                  <MenuItem key={phase.id} value={phase.id}>
                    <Checkbox checked={predecessors.indexOf(phase.id) > -1} />
                    <ListItemText primary={phase.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box className="add-phase-full-width padding-up-down">
            <FormControl fullWidth>
              <TextField
                id="outlined-basic"
                multiline
                minRows={3}
                maxRows={5}
                InputLabelProps={{ shrink: true }}
                label="Description"
                value={description || ""}
                onChange={(event) => setDescription(event.target.value)}
              />
            </FormControl>
          </Box>

          <Box className="add-phase-full-width padding-up-down">
            <FormControl fullWidth>
              <TextField
                id="outlined-basic"
                multiline
                minRows={3}
                maxRows={5}
                InputLabelProps={{ shrink: true }}
                label="Challenges"
                value={challenges || ""}
                onChange={(event) => setChallenges(event.target.value)}
              />
            </FormControl>
          </Box>

          <div className="row-space-between padding-up-down">
            <Box className="add-phase-half-width">
              <FormControl fullWidth>
                <InputLabel shrink={true} id="demo-simple-select-label">
                  Status
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={status || ""}
                  input={
                    <OutlinedInput
                      id="select-multiple-chip"
                      label="Status"
                      notched
                    />
                  }
                  label="SelectedProject"
                  onChange={(event) => setStatus(event.target.value)}
                >
                  <MenuItem value={"OK"}>OK</MenuItem>
                  <MenuItem value={"BREACHED"}>Breached</MenuItem>
                  <MenuItem value={"NEW"}>New</MenuItem>
                  <MenuItem value={"IN_PROGRESS"}>In Progress</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box className="add-phase-half-width">
              <FormControl fullWidth>
                <InputLabel shrink={true} id="demo-simple-select-label">
                  Completion Rate
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={completion}
                  label="SelectedProject"
                  input={
                    <OutlinedInput
                      id="select-multiple-chip"
                      label="Completion Rate"
                      notched
                    />
                  }
                  onChange={(event) =>
                    setCompletion(
                      typeof event.target.value === "string"
                        ? parseInt(event.target.value)
                        : event.target.value
                    )
                  }
                >
                  <MenuItem value={100}>100%</MenuItem>
                  <MenuItem value={75}>75%</MenuItem>
                  <MenuItem value={50}>50%</MenuItem>
                  <MenuItem value={25}>25%</MenuItem>
                  <MenuItem value={0}>0%</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </div>
          <div className="add-phase-row-flex-end padding-up-down">
            <button
              className="button-white-small text-Proxima Nova700-small margin-r20"
              onClick={() => props.onClose(true)}
            >
              {" "}
              Cancel
            </button>
            <button
              className="button-black-small-nm text-Proxima Nova700-small"
              onClick={addPhase}
            >
              {" "}
              Save phase
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

AddPhase.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  phase_id: PropTypes.number,
  project_id: PropTypes.number.isRequired,
  phases: PropTypes.array,
  selected_project: PropTypes.object,
};

export default AddPhase;
