import React, { useState, useEffect } from "react";

import "./RequestCard.css";

import axiosService from "../../utils/axios";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import LeadCard from "../lead-card/LeadCard";

import AddLead from "../add-lead/AddLead";
import AddUsersRfpp from "../add-users-rfpp/AddUsersRfpp";
import Tooltip from "@mui/material/Tooltip";
import AddRequest from "../add-request/AddRequest";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
const RequestCard = (props: any) => {
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [showAddLeadPopup, setShowAddLeadPopup] = useState(false);
  const [selectedLead, setSelectedLead] = useState<any>({});
  const [selectedRequest, setSelectedRequest] = useState<any>({});
  const [showAddUsersPopup, setShowAddUsersPopup] = useState(false);
  const [showAddRequestPopup, setShowAddRequestPopup] = useState(false);

  const acceptLead = (id: number) => {
    changeStatusLead(id, "ACCEPTED");
  };
  const rejectLead = (id: number) => {
    changeStatusLead(id, "REJECTED");
  };

  const handleCloseAddUsersPopup = () => {
    setShowAddUsersPopup(false);
    props.getRequests();
  };
  const handleCloseAddRequestPopup = () => {
    setShowAddRequestPopup(false);
    props.getRequests();
  };
  const changeStatusLead = (id: number, status: string) => {
    let body = {
      id: id,
      status: status,
    };
    axiosService
      .post(`${process.env.REACT_APP_API_URL}/change_lead_status`, body)
      .then((res) => {
        setAlertMessage("The lead status has been changed");
        setOpenSuccess(true);
        props.getRequests();
      })
      .catch((err) => {
        setAlertMessage(err.message);
        setOpenError(true);
      });
  };
  const editLead = (lead: any) => {
    setSelectedLead(lead);
    setShowAddLeadPopup(true);
  };

  const closeLeadPopup = () => {
    setShowAddLeadPopup(false);
    setSelectedLead({});
    props.getRequest();
  };

  return (
    <div className="card-request ">
      {selectedLead && selectedLead.name
        ? showAddLeadPopup && (
            <AddLead
              show={showAddLeadPopup}
              onClose={closeLeadPopup}
              lead={selectedLead}
            />
          )
        : showAddLeadPopup && (
            <AddLead show={showAddLeadPopup} onClose={closeLeadPopup} />
          )}
      {showAddUsersPopup && (
        <AddUsersRfpp
          show={showAddUsersPopup}
          onClose={handleCloseAddUsersPopup}
          request={props.request}
        />
      )}
      {showAddRequestPopup && (
        <AddRequest
          show={showAddRequestPopup}
          onClose={handleCloseAddRequestPopup}
          request={props.request}
        />
      )}
      <div className=" requests-background-grey1">
        <div className="row-start-no-width wrap">
          <span className="text-Proxima Nova700 opp-grey margin-r20">
            {props.request.name}
          </span>
          <span
            className="orange-text pointer max-content-width align-self-center margin-right-small"
            onClick={() => setShowAddRequestPopup(true)}
          >
            View Details
          </span>
        </div>
        {/* {props.request.client && props.request.client.id && (
          <div className="row-center">
            <h1 className="font bold title ">
              <b>Client id:</b>
              {props.request.client.id}
            </h1>
            &nbsp; &nbsp;
            <h1 className="font bold title ">
              <b>Client Username:</b>
              {props.request.client.username}
            </h1>
          </div>
        )} */}
        <div className="req-card-headerIcons">
          <span className="opp-status">
            Status:{" "}
            {props.request.status.charAt(0).toUpperCase() +
              props.request.status.slice(1).toLowerCase()}
          </span>
          <Tooltip title="Assign Users" placement="bottom" arrow>
            <GroupAddIcon
              fontSize="medium"
              className="nav-link nav-icon pointer"
              onClick={() => setShowAddUsersPopup(true)}
            />
          </Tooltip>
          {props.request.file !== "" && (
            <Tooltip title="Download Attachment" placement="bottom" arrow>
              <a
                target="_blank"
                href={
                  process.env.REACT_APP_API_URL?.split("/api")[0] +
                  props.request?.file
                }
                download
              >
                <DescriptionOutlinedIcon
                  fontSize="medium"
                  style={{ color: "#626DA1" }}
                />
              </a>
            </Tooltip>
          )}
        </div>
      </div>
      <div className="requests-background-grey2">
        {props.request.leads &&
          props.request.leads.map((lead: any, i: number) => {
            return (
              <div
                key={lead.id}
                style={{
                  borderBottomLeftRadius: 24,
                  borderBottomRightRadius: 24,
                  borderTopLeftRadius: i > 0 ? 24 : 0,
                  borderTopRightRadius: i > 0 ? 24 : 0,
                  marginTop: i > 0 ? 10 : 0,
                  backgroundColor: "white",
                  boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.12)",
                }}
              >
                <LeadCard
                  key={lead.id}
                  lead={lead}
                  hotSkills={props.hotSkills}
                  editLead={editLead}
                  acceptLead={acceptLead}
                  rejectLead={rejectLead}
                  createJob={props.createJob}
                />
              </div>
            );
          })}
      </div>
      {/* <div className="footer">
        <button className="secondary" style={{props.request}}>
          <span className="font">I want to learn</span>
        </button>
        <button className="secondary">
          <span className="font">I want to help</span>
        </button>
        <button className="primary">
          <span className="font">I want to do it</span>
        </button>
      </div> */}
    </div>
  );
};

export default RequestCard;
