import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import authSlice from "../../store/slices/auth";
import "./Header.css";
import MenuIcon from "@mui/icons-material/Menu";
import axiosService from "../../utils/axios";
import { useNavigate } from "react-router";
import NotificationsIcon from "@mui/icons-material/Notifications";
import locationSlice from "../../store/slices/location";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import LogoutIcon from "@mui/icons-material/Logout";
import DeveloperModeIcon from "@mui/icons-material/DeveloperMode";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import SettingsIcon from "@mui/icons-material/Settings";
import Tooltip from "@mui/material/Tooltip";
import { ReactComponent as GyldLogo } from "../../assets/images/GyldHUB.svg";
import { useAlert } from "react-alert";
import {
  ListItemIcon,
  ListItemText,
  MenuList,
  Paper,
  Typography,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import Moment from "moment";

function Header(props: any) {
  const [notifications, setNotifications] = React.useState<Array<any>>([]);
  const [unreadNotifications, setUnreadNotifications] = React.useState<
    Array<any>
  >([]);
  const alert = useAlert();
  const [openNotifications, setOpenNotifications] = useState(false);
  const auth = useSelector((state: RootState) => state.auth);
  const path = useSelector((state: RootState) => state.location);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const menuRef = useRef<HTMLDivElement>(null);
  const [UserName, setUserName] = useState("");
  const [image, setImage] = React.useState("");
  const [clientAdmin, setClientAdmin] = React.useState(false);
  const [availableViews, setAvailableViews] = useState<string[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorPic, setAnchorPic] = React.useState<null | HTMLElement>(null);
  const [anchorNot, setAnchorNot] = React.useState<null | HTMLElement>(null);
  const [selectedGroup, setSelectedGroup] = useState<string | null>("");
  const [selectedGroupId, setSelectedGroupId] = useState<number | null>(0);

  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const getUserInfo = () => {
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_user_info`)
      .then((res) => {
        setImage(
          process.env.REACT_APP_API_URL?.split("/api")[0] + res.data.image
        );
        setClientAdmin(res.data.client_admin);
        setUserName(res.data.username);
        let tmp_skills: any[] = [];
        if (res.data.dev_skills.length > 0) {
          res.data.dev_skills.forEach((skill: any) => {
            tmp_skills.push(skill.skill);
          });
          if (tmp_skills !== auth.skills) {
            dispatch(authSlice.actions.setSkills(tmp_skills));
          }
        }
        if (res.data.expert !== auth.expert) {
          dispatch(authSlice.actions.setExpert(res.data.expert));
        }
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }
      });
  };
  const handleClose = (view: string) => {
    setAnchorEl(null);
    if (typeof view === "string") {
      dispatch(authSlice.actions.setSelectedView(view));
      navigate("/platform");
    }
  };
  const handleNotificationClick = async (notification: any) => {
    if (notification.subgroup) {
      dispatch(authSlice.actions.setSelectedSubgroup(notification.subgroup));
      dispatch(
        authSlice.actions.setSelectedSubgroupId(notification.subgroup_id)
      );
    }
    if (notification.group) {
      let result2 = dispatch(
        authSlice.actions.setSelectedGroupId(notification.group_id)
      );
      let result1 = dispatch(
        authSlice.actions.setSelectedGroup(notification.group)
      );

      setSelectedGroupId(result2.payload);
      setSelectedGroup(result1.payload);
    }
  };

  useEffect(() => {
    switch (selectedGroup) {
      case "LEAD":
        if (path.location != "/platform/leads") {
          navigate("/platform/leads");
        } else {
          navigate(0);
        }

        break;
      case "CONTRACT":
        if (path.location != "/platform/projects") {
          navigate("/platform/projects");
        } else {
          navigate(0);
        }

        break;
      case "PROJECT":
        if (path.location != "/platform/projects") {
          navigate("/platform/projects");
        } else {
          navigate(0);
        }
        break;
      case "REQUEST":
        if (path.location != "/platform/requests") {
          navigate("/platform/requests");
        } else {
          navigate(0);
        }
        break;
      case "OPPORTUNITY":
        if (path.location != "/platform/opportunities") {
          navigate("/platform/opportunities");
        } else {
          navigate(0);
        }

        break;
      case "JOB":
        if (path.location != "/platform/jobs") {
          navigate("/platform/jobs");
        } else {
          navigate(0);
        }

        break;

      case "PAYMENT":
        if (auth.selectedView === "GYLD MANAGER") {
          navigate("/platform/payments");
          break;
        } else {
          navigate("/platform");
          break;
        }
    }
  }, [selectedGroup, selectedGroupId]);

  const openPic = Boolean(anchorPic);
  const openNot = Boolean(anchorNot);
  const handleClickPic = (event: any) => {
    setAnchorPic(event.currentTarget);
  };
  const handleClosePic = (view: string) => {
    setAnchorPic(null);
  };
  const handleClickNot = (event: any) => {
    setAnchorNot(event.currentTarget);
    handleOpenNotifications();
  };
  const handleCloseNot = (view: string) => {
    setAnchorNot(null);
  };

  useEffect(() => {
    let tmp = [];
    if (auth.dev && auth.selectedView !== "DEVELOPER") {
      tmp.push("DEVELOPER");
    }
    if (auth.client && auth.selectedView !== "CLIENT") {
      tmp.push("CLIENT");
    }
    if (auth.staff && auth.selectedView !== "STAFF") {
      tmp.push("STAFF");
    }
    if (auth.lead_provider && auth.selectedView !== "LEAD PROVIDER") {
      tmp.push("LEAD PROVIDER");
    }
    if (auth.admin && auth.selectedView !== "GYLD MANAGER") {
      tmp.push("GYLD MANAGER");
    }
    setAvailableViews(tmp);
  }, [auth]);

  useEffect(() => {
    if (path?.location && path.location.includes("platform")) {
      getUserInfo();
    }
  }, [path]);

  useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", handleOutsideClicks);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleOutsideClicks);
    };
  }, [openNotifications]);

  const handleOutsideClicks = (event: MouseEvent) => {
    if (
      openNotifications &&
      menuRef &&
      menuRef.current &&
      !menuRef.current.contains(event.target as HTMLDivElement)
    ) {
      setOpenNotifications(false);
    }
  };

  useEffect(() => {
    if (auth.account && path?.location && path.location.includes("platform")) {
      getNotifications();

      const timer = setInterval(() => getNotifications(), 15000);
      return () => clearInterval(timer);
    }
  }, [auth]);

  const getNotifications = () => {
    axiosService
      .get(
        `${process.env.REACT_APP_API_URL}/get_user_notifications/` +
          auth.account
      )
      .then((res: any) => {
        if (res.data) {
          setNotifications(res.data);
          let tmp_unread: any[] = [];
          res.data.forEach((notification: any) => {
            if (!notification.read) {
              tmp_unread.push(notification);
            }
          });
          setUnreadNotifications(tmp_unread);
        } else {
          setNotifications([]);
        }
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };

  const handleOpenNotifications = () => {
    let tmp_ids: any[] = [];
    if (unreadNotifications.length > 0) {
      unreadNotifications.forEach((notification: any) => {
        tmp_ids.push(notification.id);
      });

      let body = {
        ids: tmp_ids,
      };
      axiosService
        .post(`${process.env.REACT_APP_API_URL}/read_notification`, body)
        .then((res: any) => {
          getNotifications();
        })
        .catch((err) => {
          console.log(err);
        });
    }
    setOpenNotifications(!openNotifications);
  };
  const handleLogout = () => {
    dispatch(authSlice.actions.setLogout());
    navigate("/");
  };

  return (
    <div className="navigationBar">
      <Link className=" logo" to="/">
        <GyldLogo />
        {/* <span className="home-text">the</span>
        <strong className="font-Proxima Nova">Gyld</strong> */}
      </Link>

      {path?.location &&
        !path.location.includes("platform") &&
        window.innerWidth >= 800 && (
          <div className="rightSide">
            <button
              className="button nav"
              style={{
                borderBottom:
                  path.location === "/" ? "4px solid lightGrey" : "none",
                marginTop: path.location === "/" ? 4 : 0,
              }}
            >
              <Link className="link" to="/">
                Home
              </Link>
            </button>
            <button
              className="button nav"
              style={{
                borderBottom:
                  path.location === "/pricing" ? "4px solid lightGrey" : "none",
                marginTop: path.location === "/pricing" ? 4 : 0,
              }}
            >
              <Link className="link" to="/pricing">
                Pricing
              </Link>
            </button>
            <button
              className="button nav"
              style={{
                borderBottom:
                  path.location === "/developers"
                    ? "4px solid lightGrey"
                    : "none",
                marginTop: path.location === "/developers" ? 4 : 0,
              }}
            >
              <Link className="link" to="/developers">
                Developers
              </Link>
            </button>
            <button
              className="button nav"
              style={{
                borderBottom:
                  path.location === "/blogs" ? "4px solid lightGrey" : "none",
                marginTop: path.location === "/blogs" ? 4 : 0,
              }}
            >
              <Link className="link" to="/blogs">
                Blogs
              </Link>
            </button>
            {/* <button
              className="button nav"
              style={{
                borderBottom:
                  path.location === "/about" ? "4px solid lightGrey" : "none",
                marginTop: path.location === "/about" ? 4 : 0,
              }}
            >
              <Link className="link" to="/about">
                About
              </Link>
            </button> */}

            <button
              className="button nav"
              style={{
                borderBottom:
                  path.location === "/about" ? "4px solid lightGrey" : "none",
                marginTop: path.location === "/about" ? 4 : 0,
                marginRight: 8,
              }}
            >
              <Link className="link" to="/about">
                About
              </Link>
            </button>

            {auth.account && (
              <button className="button signUp">
                <Link className="link signUpLink" to="/platform">
                  Platform
                </Link>
              </button>
            )}
            {!auth.account && (
              <button className="button signUp">
                <Link className="link signUpLink" to="/login">
                  Platform
                </Link>
              </button>
            )}
          </div>
        )}

      {path?.location && path.location.includes("platform") && (
        <div className="rightSideDevelopers">
          <div className="selected-view pointer">
            {availableViews.length > 0 && (
              <div className="row-start">
                <span onClick={handleClick}>{auth.selectedView}</span>
                <ArrowDropDownIcon onClick={handleClick} />
              </div>
            )}
          </div>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {availableViews.map((view: string) => {
              if (view !== "STAFF") {
                return (
                  <MenuList key={view}>
                    <MenuItem onClick={() => handleClose(view)}>
                      <div className="row-start">
                        <ListItemIcon>
                          {view === "DEVELOPER" ? (
                            <DeveloperModeIcon
                              sx={{ fontSize: 22 }}
                              className="icon-color"
                            />
                          ) : view === "LEAD PROVIDER" ? (
                            <PersonSearchIcon
                              sx={{ fontSize: 23 }}
                              className="icon-color"
                            />
                          ) : view === "GYLD MANAGER" ? (
                            <ManageAccountsIcon
                              sx={{ fontSize: 23 }}
                              className="icon-color"
                            />
                          ) : (
                            view === "CLIENT" && (
                              <BusinessCenterIcon
                                sx={{ fontSize: 23 }}
                                className="icon-color"
                              />
                            )
                          )}
                        </ListItemIcon>

                        <span className="text-Proxima Nova700-small darkgrey">
                          {view !== "STAFF" ? view : ""}
                        </span>
                      </div>
                    </MenuItem>
                  </MenuList>
                );
              }
            })}
          </Menu>

          <div onClick={handleClickNot}>
            <Tooltip title="Notifications" placement="bottom" arrow>
              <NotificationsIcon className="header-icon-color" />
            </Tooltip>

            {unreadNotifications.length > 0 && (
              <div className="notification-alert">
                <span>{unreadNotifications.length}</span>
              </div>
            )}
          </div>
          {openNot && (
            <div className="notifications-open" ref={menuRef}>
              {/* <Notifications
                openNotifications={openNotifications}
                notifications={notifications}
                filteredNotifications={[]}
                close={() => setOpenNotifications(false)}
                anchorEl={menuRef}
              /> */}
              <Menu
                id="basic-menu"
                anchorEl={anchorNot}
                open={openNot}
                onClick={() => handleCloseNot("")}
                onClose={handleCloseNot}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <span className="text-Proxima Nova700 darkgrey padding-normal">
                  Notifications
                </span>
                {(!notifications || notifications.length === 0) && (
                  <span className="text-Proxima Nova700-small margin-t20">
                    There are no notifications
                  </span>
                )}
                <div className="notification-body">
                  {notifications &&
                    notifications.map((notification: any) => {
                      return (
                        <MenuList key={notification.id} sx={{ maxWidth: 400 }}>
                          <MenuItem
                            onClick={() =>
                              handleNotificationClick(notification)
                            }
                          >
                            {/* <Link
                              className="link-menu"
                              to={
                                notification.group === "JOB"
                                  ? "/platform/jobs"
                                  : notification.group === "LEAD"
                                  ? "/platform/leads"
                                  : notification.group === "OPPORTUNITY"
                                  ? "/platform/opportunities"
                                  : notification.group === "PROJECT"
                                  ? "/platform/projects"
                                  : notification.group === "REQUEST"
                                  ? "/platform/requests"
                                  : "/platform"
                              }
                            > */}
                            <div className="row-start full-percent-width">
                              <ListItemIcon>
                                <NotificationsIcon
                                  style={{
                                    color:
                                      notification.type === "SUCCESS"
                                        ? "#8ec68e"
                                        : notification.type === "ALERT"
                                        ? "#e0e070"
                                        : notification.type === "INFO"
                                        ? "#afacac"
                                        : "red",
                                  }}
                                />
                              </ListItemIcon>
                              <div className="column-start">
                                <strong className="text-Proxima Nova700-small darkgrey display-linebreak">
                                  {notification.description}
                                </strong>
                                <Typography className="grey-text">
                                  {Moment(notification.createdAt).format(
                                    "MMMM Do YYYY, h:mm:ss a"
                                  )}
                                </Typography>
                              </div>
                            </div>
                            {/* </Link> */}
                          </MenuItem>
                        </MenuList>
                      );
                    })}
                </div>
              </Menu>
            </div>
          )}

          {/* {auth.account && (
            <button onClick={handleLogout} className="button nav">
              <Link className="link" to="/developers">
                Log Out
              </Link>
            </button>
          )} */}

          {image.includes("media") ? (
            <img
              onClick={handleClickPic}
              src={image}
              className="profile-img margin-l10"
            />
          ) : (
            <div
              onClick={handleClickPic}
              className="profile-img no-pic margin-l10"
            >
              <span>{UserName.charAt(0)}</span>
            </div>
          )}

          <Menu
            sx={{ minWidth: 500, borderRadius: 24, outline: "none" }}
            id="basic-menu"
            anchorEl={anchorPic}
            open={openPic}
            onClick={() => handleClosePic("")}
            onClose={handleClosePic}
          >
            {/* <Paper sx={{ width: 320, maxWidth: "100%" }}> */}
            <MenuList>
              <MenuItem>
                <Link className="link-menu" to="/platform/profile">
                  <div className="row-start">
                    <ListItemIcon>
                      <PersonIcon
                        sx={{ fontSize: 25 }}
                        className="icon-color"
                      />
                    </ListItemIcon>
                    <div className="column-start">
                      <strong className="text-Proxima Nova700-small darkgrey">
                        Profile
                      </strong>
                      <Typography className="grey-text">
                        Edit your profile info
                      </Typography>
                    </div>
                  </div>
                </Link>
              </MenuItem>
              {clientAdmin && (
                <MenuItem>
                  <Link
                    className="link-menu"
                    to="/platform/organization-profile"
                  >
                    <div className="row-start">
                      <ListItemIcon>
                        <SettingsIcon
                          sx={{ fontSize: 25 }}
                          className="icon-color"
                        />
                      </ListItemIcon>
                      <div className="column-start">
                        <strong className="text-Proxima Nova700-small darkgrey">
                          Organization Settings
                        </strong>
                        <Typography className="grey-text">
                          Change your organization settings
                        </Typography>
                      </div>
                    </div>
                  </Link>
                </MenuItem>
              )}
              <MenuItem onClick={handleLogout}>
                <div className="row-start">
                  <ListItemIcon>
                    <LogoutIcon sx={{ fontSize: 24 }} className="icon-color" />
                  </ListItemIcon>
                  <div className="column-start">
                    <strong className="text-Proxima Nova700-small darkgrey">
                      Logout
                    </strong>
                    <Typography className="grey-text">
                      Logout from the Platform
                    </Typography>
                  </div>
                </div>
              </MenuItem>
            </MenuList>
            {/* </Paper> */}
          </Menu>
        </div>
      )}
      {
        // path?.location?.split("/")[2] !== "platform" &&
        window.innerWidth < 800 && (
          <div id="right">
            <MenuIcon
              onClick={props.handleSidebar}
              style={{ color: "grey" }}
              className="pointer"
            />
          </div>
        )
      }
    </div>
  );
}

export default Header;
