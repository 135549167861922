import React, { useState, useEffect } from "react";
import Moment from "moment";
import "./JobCard.css";
import EuroIcon from "@mui/icons-material/Euro";
import BusinessIcon from "@mui/icons-material/Business";
import ComputerIcon from "@mui/icons-material/Computer";
import AvTimerIcon from "@mui/icons-material/AvTimer";
import WhatshotIcon from "@mui/icons-material/Whatshot";

function JobCardClient(props: any) {
  const [readMore, setReadMore] = useState(false);

  return (
    <div
      className={
        props.highlight
          ? !readMore
            ? "opp-boxes highlight-selected"
            : "opp-boxes-readmore  highlight-selected"
          : !readMore
          ? "opp-boxes "
          : "opp-boxes-readmore  "
      }
    >
      <span className="text-Proxima Nova700">{props.job.name}</span>

      <div className="opp-box-row">
        <span className="opp-status">
          Status:{" "}
          {props.job.status === "HAS_APPLICANTS" ? "PENDING" : props.job.status}
        </span>
        {window.innerWidth > 1200 && <div className="ball"></div>}
        <span className="opp-status">Field: {props.job.industry}</span>
        {window.innerWidth > 1200 && <div className="ball"></div>}
        <span className="opp-status">Client: {props.job.client_type}</span>
        {window.innerWidth > 1200 && <div className="ball"></div>}
        <span className="opp-status">
          {" "}
          {Moment(props.job.start_date).format("D MMM yy")} -{" "}
          {Moment(props.job.end_date).format("D MMM yy")}
        </span>
        {window.innerWidth > 1200 && <div className="ball"></div>}
        <span className="opp-status">
          {props.job.job_type
            .toLowerCase()
            .replace(/_/g, " ")
            .charAt(0)
            .toUpperCase() +
            props.job.job_type.toLowerCase().replace(/_/g, " ").slice(1)}
        </span>
      </div>
      <div className="margin-t20 margin-b20">
        <span className="text-Proxima Nova  ">
          {!readMore
            ? [...props.job.description].slice(0, 400)
            : props.job.description}
        </span>
        {[...props.job.description].length > 400 && (
          <span className="text-Proxima Nova-small ">...</span>
        )}
        {[...props.job.description].length > 400 && (
          <span
            className="text-Proxima Nova-small orange-text pointer margin-l10"
            onClick={() => setReadMore(!readMore)}
          >
            {readMore ? "Read Less" : "Read More"}
          </span>
        )}
      </div>
      <div className="opp-box-row-buttons">
        {props.job.skills.map((skill: string) => {
          return (
            <span key={skill} className="opp-box-button">
              {skill}
              {props.hotSkills?.map((hotSkill: any) => {
                if (hotSkill.name === skill) {
                  return (
                    <WhatshotIcon
                      key={hotSkill.name}
                      style={{
                        color: "#ff6210",
                        marginBottom: 5,
                      }}
                    />
                  );
                }
              })}
            </span>
          );
        })}
      </div>

      <div
        className={
          [...props.job.description].length > 400
            ? "job-card-row-space-between margin-b10n"
            : "job-card-row-space-between"
        }
      >
        <div className="opp-box-row-end">
          <div className="opp-margin">
            <span className="text-Proxima Nova-small">Developer Level: </span>
            <span className="text-Proxima Nova-small opp-lightgrey">
              {props.job.developer_level}
            </span>
          </div>
          <div className="opp-margin">
            <span className="text-Proxima Nova-small">Developers needed: </span>
            <span className="text-Proxima Nova-small opp-lightgrey">
              {props.job.num_developers}
            </span>
          </div>

          {props.job.status === "PENDING" &&
            ((props.job.proposed_devs && props.job.proposed_devs.length > 0) ||
              (props.job.suggested_proposed_devs &&
                props.job.suggested_proposed_devs.length > 0)) && (
              <div className="opp-margin">
                <span className="text-Proxima Nova-small">
                  Proposed Developers:{" "}
                </span>
                <span className="text-Proxima Nova-small opp-lightgrey">
                  {props.job.proposed_devs.length +
                    props.job.suggested_proposed_devs.length}
                </span>
              </div>
            )}
          {props.job.status === "PENDING" &&
            ((props.job.proposed_groups &&
              props.job.proposed_groups.length > 0) ||
              (props.job.suggested_proposed_groups &&
                props.job.suggested_proposed_groups.length > 0)) && (
              <div className="opp-margin">
                <span className="text-Proxima Nova-small">
                  Proposed Groups:{" "}
                </span>
                <span className="text-Proxima Nova-small opp-lightgrey">
                  {props.job.proposed_groups.length +
                    props.job.suggested_proposed_groups.length}
                </span>
              </div>
            )}

          {props.job.status === "ACCEPTED" &&
            props.job.accepted_devs &&
            props.job.accepted_devs.length > 0 && (
              <div className="opp-margin">
                <span className="text-Proxima Nova-small">
                  Accepted Developers:{" "}
                </span>
                <span className="text-Proxima Nova-small opp-lightgrey">
                  {props.job.accepted_devs.length}
                </span>
              </div>
            )}

          {props.job.status === "ACCEPTED" &&
            props.job.accepted_groups &&
            props.job.accepted_groups.length > 0 && (
              <div className="opp-margin">
                <span className="text-Proxima Nova-small">
                  Accepted Groups:{" "}
                </span>
                <span className="text-Proxima Nova-small opp-lightgrey">
                  {props.job.accepted_groups.length}
                </span>
              </div>
            )}
        </div>
        <div className="opp-box-text-end ">
          {props.job.status === "PENDING" &&
            ((props.job.proposed_devs && props.job.proposed_devs.length > 0) ||
              (props.job.suggested_proposed_devs &&
                props.job.suggested_proposed_devs.length > 0) ||
              (props.job.proposed_groups &&
                props.job.proposed_groups.length > 0) ||
              (props.job.suggested_proposed_groups &&
                props.job.suggested_proposed_groups.length > 0)) && (
              <span
                className="text-Proxima Nova-700 orange-text pointer max-content-width"
                onClick={() => props.selectCandidates(props.job)}
              >
                Select Candidates
              </span>
            )}
          {props.job.status === "ACCEPTED" &&
            ((props.job.accepted_devs && props.job.accepted_devs.length > 0) ||
              (props.job.accepted_groups &&
                props.job.accepted_groups.length > 0)) && (
              <span
                className="text-Proxima Nova-700 orange-text pointer max-content-width"
                onClick={() => props.selectCandidates(props.job)}
              >
                View Selected Candidates
              </span>
            )}
        </div>
      </div>
    </div>
  );
}

export default JobCardClient;
