import React, { useState, useEffect } from "react";
import Moment from "moment";
import axiosService from "../../utils/axios";
import { useAlert } from "react-alert";
import DeleteIcon from "@mui/icons-material/Delete";
import "./MilestoneCard.css";
import EditIcon from "@mui/icons-material/Edit";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import Tooltip from "@mui/material/Tooltip";

function MilestoneCard(props: any) {
  const auth = useSelector((state: RootState) => state.auth);
  const alert = useAlert();

  const deleteMilestone = () => {
    axiosService
      .delete(
        `${process.env.REACT_APP_API_URL}/delete_milestone/` +
          props.milestone.id
      )
      .then((res) => {
        props.getProjects();
        alert.success("The milestone was deleted.");
      })

      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };
  return (
    <div
      className={
        props.highlight
          ? "opp-box-phases-half full-percent-width highlight-selected"
          : "opp-box-phases-half full-percent-width"
      }
    >
      <div className="row-space-between">
        <span className="text-Proxima Nova700">{props.milestone.name}</span>
      </div>
      <div className="opp-box-row">
        <span
          style={{
            color:
              props.milestone.status === "BREACHED" ? "#c47878" : "#767fad",
          }}
          className="opp-status"
        >
          Status:{" "}
          {props.milestone.status
            .toLowerCase()
            .replace(/_/g, " ")
            .charAt(0)
            .toUpperCase() +
            props.milestone.status.toLowerCase().replace(/_/g, " ").slice(1)}
        </span>
        {window.innerWidth > 1200 && <div className="ball"></div>}
        <span className="opp-status">
          {" "}
          {Moment(props.milestone.date).format("D MMM yy")}
        </span>

        {/* <span className="opp-status">
          Responsible:{" "}
          {props.milestone.responsible.map((resp: any, i: number) => {
            return (
              <span key={resp.username}>
                {resp.username +
                  (i < props.milestone.responsible.length - 1 ? "," : "")}
              </span>
            );
          })}
        </span> */}
      </div>

      <span className="text-Proxima Nova-small opp-grey">
        {props.milestone.description}
      </span>

      <span className="opp-status"></span>
      {auth.selectedView === "DEVELOPER" && (
        <div className="opp-box-end-end">
          <div className="circle-left">
            <Tooltip title="Edit" placement="bottom" arrow>
              <EditIcon
                onClick={() => props.handleEdit(props.milestone.id)}
                style={{ color: "#626DA1", cursor: "pointer" }}
              />
            </Tooltip>
          </div>
          <div className="circle-right">
            <Tooltip title="Delete" placement="bottom" arrow>
              <DeleteIcon
                onClick={deleteMilestone}
                style={{
                  color: "#626DA1",
                  cursor: "pointer",
                }}
              />
            </Tooltip>
          </div>
        </div>
      )}
    </div>
  );
}

export default MilestoneCard;
