import { useEffect, useState } from "react";
import "./AddProject.css";
import PropTypes from "prop-types";
import axiosService from "../../utils/axios";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import FormControlLabel from "@mui/material/FormControlLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Input, TextareaAutosize, TextField } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAlert } from "react-alert";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AddProject = (props: any) => {
  const [show, setShow] = useState(false);
  const [clients, setClients] = useState<any[]>([]);
  const [selectedClient, setSelectedClient] = useState(0);
  const [selectedRfpp, setSelectedRfpp] = useState<number | string>(0);
  const [rfpps, setRfpps] = useState<any[]>([]);

  const [status, setStatus] = useState("");
  const [selectedContracts, setSelectedContracts] = useState<any[]>([]);
  const [selectedUnassignedContracts, setSelectedUnassignedContracts] =
    useState<any[]>([]);
  const [jobs, setJobs] = useState<any[]>([]);
  const [isFirst, setIsFirst] = useState(true);
  const [selectedDevs, setSelectedDevs] = useState<any[]>([]);
  const auth = useSelector((state: RootState) => state.auth);
  const [unassignedContracts, setUnassignedContracts] = useState<any[]>([]);

  const [name, setName] = useState("");
  const alert = useAlert();
  const [rfppId, setRfppId] = useState<number>();
  const [clientId, setClientId] = useState<number>();
  const [devs, setDevs] = useState<any[]>([]);

  const closeHandler = (e: any) => {
    setShow(false);
    props.onClose(false);
  };
  useEffect(() => {
    if (isFirst) {
      setIsFirst(false);
    }

    setName("");
  }, []);

  useEffect(() => {
    const close = (e: any) => {
      if (e.keyCode === 27) {
        closeHandler(true);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  const getUnassignedContractsFromSameClient = () => {
    axiosService
      .get(
        `${process.env.REACT_APP_API_URL}/get_unassigned_contracts_same_client/` +
          props.project.client.client_id
      )
      .then((res) => {
        let tmp_c: number[] = [];

        setUnassignedContracts(res.data);
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };
  useEffect(() => {
    if (!props.project) {
      setSelectedClient(props.contracts[0].client.id);
      let tmp_devs: any[] = [];
      let tmp_rfpp = 0;
      let tmp_con: number[] = [];
      props.contracts.forEach((contract: any) => {
        tmp_con.push(contract.id);
        if (!tmp_devs.includes(contract.dev.id)) {
          tmp_devs.push(contract.dev.id);
        }
        tmp_rfpp = contract.job.rfpp;
      });
      if (tmp_rfpp !== 0) {
        setSelectedRfpp(tmp_rfpp);
      }
      setSelectedContracts(tmp_con);
      setSelectedDevs(tmp_devs);
    } else {
      getUnassignedContractsFromSameClient();
      setSelectedClient(props.project.client.client_id);
      setSelectedRfpp(props.project.rfpp);
      setSelectedDevs(props.project.dev_ids);
      let tmp_c: number[] = [];
      props.project.contracts.forEach((contract: any) => {
        tmp_c.push(contract.id);
      });
      setSelectedContracts(tmp_c);
      setName(props.project.name);
      setStatus(props.project.status);
    }
  }, [props]);

  const addProject = () => {
    if (props.project) {
      let tmp_devs = selectedDevs;
      unassignedContracts.forEach((contract: any) => {
        if (
          !tmp_devs.includes(contract.dev.id) &&
          selectedUnassignedContracts.includes(contract.dev.id)
        ) {
          tmp_devs.push(contract.dev.id);
        }
      });
      let body = {
        id: props.project.id,
        contracts: [...selectedContracts, ...selectedUnassignedContracts],
        client: selectedClient,
        dev_ids: tmp_devs,
        name: name,
        status: status,
        rfpp: selectedRfpp !== 0 ? selectedRfpp : "",
      };

      axiosService
        .post(`${process.env.REACT_APP_API_URL}/edit_project`, body)
        .then((res) => {
          alert.success("The Project was updated");
          props.onClose(true);
        })

        .catch((err) => {
          if (err.response?.status !== 500) {
            if (err.response) {
              Object.keys(err.response.data).forEach(function (key, index) {
                alert.error(key + ":" + err.response.data[key]);
              });
            } else {
              alert.error(err.message);
            }
          }

          console.log(err);
        });
    } else {
      let body = {
        contracts: selectedContracts,
        client: selectedClient,
        dev_ids: selectedDevs,
        name: "Project:" + name,
        status: status,
        rfpp: selectedRfpp !== 0 ? selectedRfpp : "",
      };

      axiosService
        .post(`${process.env.REACT_APP_API_URL}/add_project`, body)
        .then((res) => {
          alert.success("The Project was added");
          props.onClose(true);
        })

        .catch((err) => {
          if (err.response?.status !== 500) {
            if (err.response) {
              Object.keys(err.response.data).forEach(function (key, index) {
                alert.error(key + ":" + err.response.data[key]);
              });
            } else {
              alert.error(err.message);
            }
          }

          console.log(err);
        });
    }
  };

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  const reset = () => {
    setSelectedDevs([]);

    setName("");
  };
  const handleChangeSelectedContracts = (
    event: SelectChangeEvent<typeof selectedContracts>
  ) => {
    const {
      target: { value },
    } = event;
    setSelectedUnassignedContracts(
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <div
      style={{
        visibility: show ? "visible" : "hidden",
        opacity: show ? "1" : "0",
      }}
      className={"overlay"}
    >
      <div className={"popup-job "}>
        <div className="row-space-between full-percent-height">
          <div className="row-start">
            <h2 className="padding-normal">
              {props.project ? "Edit Project" : "Add a Project"}
            </h2>{" "}
            <button className="reset-button padding-normal" onClick={reset}>
              Reset
            </button>
          </div>

          <span className={"close"} onClick={closeHandler}>
            &times;
          </span>
        </div>
        <div className={"content "}>
          <div className="row-start full-percent-height">
            <div className="column-flex-space-between full-percent-width padding-normal">
              <div className="row-space-between  padding-normal ">
                <Box sx={{ minWidth: 200 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Contracts
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedContracts}
                      label="Contracts"
                      multiple
                      disabled
                    >
                      {selectedContracts.map((contract: number) => {
                        return (
                          <MenuItem key={contract} value={contract}>
                            {contract}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>
                <Box sx={{ minWidth: 200 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Client
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedClient || ""}
                      disabled
                      label="Client"
                    >
                      <MenuItem value={selectedClient}>
                        {selectedClient}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Box sx={{ minWidth: 200 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">RFPP</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedRfpp}
                      label="RFPP"
                      disabled
                    >
                      <MenuItem value={selectedRfpp}>{selectedRfpp}</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </div>
              <div className="row-space-between padding-normal ">
                <Box sx={{ width: 655 }}>
                  <FormControl fullWidth>
                    <TextField
                      label=" Name"
                      id="outlined-basic"
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      value={name}
                      onChange={(event) => setName(event.target.value)}
                    />
                  </FormControl>
                </Box>
              </div>
              {!props.project ? (
                <div className="row-space-between padding-normal">
                  <Box sx={{ minWidth: 655 }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Status
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={status}
                        label="SelectedProject"
                        onChange={(event) => setStatus(event.target.value)}
                      >
                        <MenuItem value={"STARTED"}>Started</MenuItem>
                        <MenuItem value={"NEW"}>New</MenuItem>
                        <MenuItem value={"PENDING"}>Pending</MenuItem>
                        <MenuItem value={"CANCELLED"}>Cancelled</MenuItem>
                        <MenuItem value={"FINISHED"}>Finished</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </div>
              ) : (
                <div className="row-space-between padding-normal">
                  <Box sx={{ minWidth: 300 }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Status
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={status}
                        label="SelectedProject"
                        onChange={(event) => setStatus(event.target.value)}
                      >
                        <MenuItem value={"STARTED"}>Started</MenuItem>
                        <MenuItem value={"NEW"}>New</MenuItem>
                        <MenuItem value={"PENDING"}>Pending</MenuItem>
                        <MenuItem value={"CANCELLED"}>Cancelled</MenuItem>
                        <MenuItem value={"FINISHED"}>Finished</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Box sx={{ minWidth: 300 }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Unassigned Contracts
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedUnassignedContracts}
                        label="Unasigned Contracts"
                        multiple
                        onChange={handleChangeSelectedContracts}
                      >
                        {unassignedContracts.map((contract: any) => {
                          return (
                            <MenuItem key={contract.id} value={contract.id}>
                              {contract.id}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                </div>
              )}
            </div>
          </div>
        </div>

        <div
          style={{
            alignSelf: "flex-end",
          }}
        >
          <button className="button nav" onClick={() => props.onClose(true)}>
            {" "}
            Cancel
          </button>
          <button
            className="button-black-small-nm text-Proxima Nova700-small"
            style={{ color: "white" }}
            onClick={addProject}
          >
            {props.project ? "Edit Project" : "Create Project"}
          </button>
        </div>
      </div>
    </div>
  );
};

AddProject.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  contracts: PropTypes.array.isRequired,
  project: PropTypes.object,
};

export default AddProject;
