import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import axiosService from "../../utils/axios";
import authSlice from "../../store/slices/auth";

import LeadCard from "../../components/lead-card/LeadCard";
import locationSlice from "../../store/slices/location";
import "./Leads.css";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import PlatformNavigation from "../../components/platform-navigation/PlatformNavigation";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import PendingIcon from "@mui/icons-material/Pending";
import CheckIcon from "@mui/icons-material/Check";
import HelpIcon from "@mui/icons-material/Help";
import AddLead from "../../components/add-lead/AddLead";
import HintsHidden from "../../components/hints-hidden/HintsHidden";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import Tooltip from "@mui/material/Tooltip";
import ForwardIcon from "@mui/icons-material/Forward";
import StartIcon from "@mui/icons-material/Start";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import RunningWithErrorsIcon from "@mui/icons-material/RunningWithErrors";
import pic1 from "../../assets/images/back-try-blue-orange.png";
import { useAlert } from "react-alert";
import LeadInvoices from "../../components/lead-invoices/LeadInvoices";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { OutlinedInput, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ClearAllIcon from "@mui/icons-material/ClearAll";

function LeadsUser() {
  const [leads, setLeads] = useState([]);
  const [filteredLeads, setFilteredLeads] = useState<Array<any>>([]);
  const alert = useAlert();
  const [checkedClicked, setCheckedClicked] = useState(false);
  const [acceptedClicked, setAcceptedClicked] = useState(false);
  const [rejectedClicked, setRejectedClicked] = useState(false);
  const [startedClicked, setStartedClicked] = useState(false);
  const [finishedClicked, setFinishedClicked] = useState(false);
  const [numChecked, setNumChecked] = useState(0);
  const [numAccepted, setNumAccepted] = useState(0);
  const [numRejected, setNumRejected] = useState(0);
  const [numStarted, setNumStarted] = useState(0);
  const [numFinished, setNumFinished] = useState(0);
  const [isFirst, setIsFirst] = useState(true);
  const [hideHints, setHideHints] = useState(false);
  const [showAddLeadPopup, setShowAddLeadPopup] = useState(false);
  const [mobileFilter, setMobileFilter] = useState("");
  const [hotSkills, setHotSkills] = useState<any[]>([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state: RootState) => state.auth);
  const scrollToRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    console.log(auth);
    if (isFirst) {
      if (auth.selectedView === "CLIENT") {
        getLeadsClient();
      } else {
        getLeads();
      }
      getSkills();

      setIsFirst(false);
    }
    dispatch(locationSlice.actions.setLocation({ path: "/platform/leads" }));
    if (!auth.account) {
      navigate("/login");
    }
  }, []);
  const getSkills = () => {
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_skills`)
      .then((res) => {
        let tmpHotSkills: any[] = [];
        res.data.forEach((data: any) => {
          if (data.is_hot_skill) {
            tmpHotSkills.push(data);
          }
        });

        setHotSkills(tmpHotSkills);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (auth.selectedGroup === "LEAD") {
      checkScrollTo();
    }
  });

  const checkScrollTo = () => {
    if (scrollToRef.current?.offsetTop) {
      window.scrollTo({
        top: scrollToRef.current?.offsetTop - 100,
        behavior: "smooth",
      });
      setTimeout(function () {
        clearSelected();
      }, 2000);
    }
  };

  // useLayoutEffect(() => {
  //   return () => {
  //     clearSelected();
  //   };
  // }, []);

  const clearSelected = () => {
    dispatch(authSlice.actions.setSelectedSubgroup(null));
    dispatch(authSlice.actions.setSelectedSubgroupId(null));
    dispatch(authSlice.actions.setSelectedGroup(null));
    dispatch(authSlice.actions.setSelectedGroupId(null));
  };

  const getLeads = () => {
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_user_leads/` + auth.account)
      .then((res) => {
        setLeads(res.data);
        if (filteredLeads.length > 0) {
          let tmpFiltered: any[] = [];
          filteredLeads.forEach((filter: any) => {
            res.data.forEach((lead: any) => {
              if (
                lead.status === filter.status &&
                !tmpFiltered.includes(lead)
              ) {
                tmpFiltered.push(lead);
              }
            });
          });
          setFilteredLeads(tmpFiltered);
        }
        let tmpRej = 0;
        let tmpFin = 0;
        let tmpSt = 0;
        let tmpAcc = 0;
        let tmpPend = 0;
        if (res.data.length > 0) {
          res.data.forEach((lead: any) => {
            if (lead.status === "REJECTED") {
              tmpRej++;
            } else if (lead.status === "FINISHED") {
              tmpFin++;
            } else if (lead.status === "STARTED") {
              tmpSt++;
            } else if (lead.status === "ACCEPTED") {
              tmpAcc++;
            } else if (lead.status === "PENDING") {
              tmpPend++;
            }
          });
        }
        setNumAccepted(tmpAcc);
        setNumChecked(tmpPend);
        setNumFinished(tmpFin);
        setNumStarted(tmpSt);
        setNumRejected(tmpRej);
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };

  const getLeadsClient = () => {
    axiosService
      .get(
        `${process.env.REACT_APP_API_URL}/get_user_leads_client/` + auth.account
      )
      .then((res) => {
        setLeads(res.data);
        let tmpRej = 0;
        let tmpFin = 0;
        let tmpSt = 0;
        let tmpAcc = 0;
        let tmpPend = 0;
        res.data.forEach((lead: any) => {
          if (lead.status === "REJECTED") {
            tmpRej++;
          } else if (lead.status === "FINISHED") {
            tmpFin++;
          } else if (lead.status === "STARTED") {
            tmpSt++;
          } else if (lead.status === "ACCEPTED") {
            tmpAcc++;
          } else if (lead.status === "PENDING") {
            tmpPend++;
          }
        });
        setNumAccepted(tmpAcc);
        setNumChecked(tmpPend);
        setNumFinished(tmpFin);
        setNumStarted(tmpSt);
        setNumRejected(tmpRej);
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };
  const handleChangeMobileFilter = (value: string) => {
    setMobileFilter(value);
    if (value == "ACCEPTED") {
      handleAcceptedClickMobile();
    } else if (value == "FINISHED") {
      handleFinishedClickMobile();
    } else if (value == "STARTED") {
      handleStartedClickMobile();
    } else if (value == "REJECTED") {
      handleRejectedClickMobile();
    } else if (value == "PENDING") {
      handleCheckedClickMobile();
    } else {
      setFilteredLeads([]);
    }
  };
  const handleFinishedClickMobile = () => {
    setFinishedClicked(true);
    setCheckedClicked(false);
    setStartedClicked(false);
    setAcceptedClicked(false);
    setRejectedClicked(false);

    let tmp: any[] = [];
    leads.forEach((lead: any) => {
      if (lead.status === "FINISHED") {
        tmp.push(lead);
      }
    });
    setFilteredLeads(tmp);
  };
  const handleCheckedClickMobile = () => {
    setFinishedClicked(false);
    setCheckedClicked(true);
    setStartedClicked(false);
    setAcceptedClicked(false);
    setRejectedClicked(false);

    let tmp: any[] = [];
    leads.forEach((lead: any) => {
      if (lead.status === "PENDING") {
        tmp.push(lead);
      }
    });
    setFilteredLeads(tmp);
  };
  const handleAcceptedClickMobile = () => {
    setFinishedClicked(false);
    setCheckedClicked(false);
    setStartedClicked(false);
    setAcceptedClicked(true);
    setRejectedClicked(false);

    let tmp: any[] = [];
    leads.forEach((lead: any) => {
      if (lead.status === "ACCEPTED") {
        tmp.push(lead);
      }
    });
    setFilteredLeads(tmp);
  };
  const handleRejectedClickMobile = () => {
    setFinishedClicked(false);
    setCheckedClicked(false);
    setStartedClicked(false);
    setAcceptedClicked(false);
    setRejectedClicked(true);

    let tmp: any[] = [];
    leads.forEach((lead: any) => {
      if (lead.status === "REJECTED") {
        tmp.push(lead);
      }
    });
    setFilteredLeads(tmp);
  };
  const handleStartedClickMobile = () => {
    setFinishedClicked(false);
    setCheckedClicked(false);
    setStartedClicked(true);
    setAcceptedClicked(false);
    setRejectedClicked(false);

    let tmp: any[] = [];
    leads.forEach((lead: any) => {
      if (lead.status === "STARTED") {
        tmp.push(lead);
      }
    });
    setFilteredLeads(tmp);
  };

  const handleFinishedClick = () => {
    setFinishedClicked(!finishedClicked);
    if (!finishedClicked) {
      let tmp = filteredLeads;
      leads.forEach((lead: any) => {
        if (lead.status === "FINISHED") {
          tmp.push(lead);
        }
      });
      setFilteredLeads(tmp);
    } else {
      let tmp: any[] = [];
      filteredLeads.forEach((lead: any) => {
        if (lead.status !== "FINISHED") {
          tmp.push(lead);
        }
      });
      setFilteredLeads(tmp);
    }
  };
  const handleCheckedClick = () => {
    setCheckedClicked(!checkedClicked);
    if (!checkedClicked) {
      let tmp = filteredLeads;
      leads.forEach((lead: any) => {
        if (lead.status === "PENDING") {
          tmp.push(lead);
        }
      });
      setFilteredLeads(tmp);
    } else {
      let tmp: any[] = [];
      filteredLeads.forEach((lead: any) => {
        if (lead.status !== "PENDING") {
          tmp.push(lead);
        }
      });

      setFilteredLeads(tmp);
    }
  };
  const handleAcceptedClick = () => {
    setAcceptedClicked(!acceptedClicked);
    if (!acceptedClicked) {
      let tmp = filteredLeads;
      leads.forEach((lead: any) => {
        if (lead.status === "ACCEPTED") {
          tmp.push(lead);
        }
      });
      setFilteredLeads(tmp);
    } else {
      let tmp: any[] = [];
      filteredLeads.forEach((lead: any) => {
        if (lead.status !== "ACCEPTED") {
          tmp.push(lead);
        }
      });

      setFilteredLeads(tmp);
    }
  };
  const handleRejectedClick = () => {
    setRejectedClicked(!rejectedClicked);
    if (!rejectedClicked) {
      let tmp = filteredLeads;
      leads.forEach((lead: any) => {
        if (lead.status === "REJECTED") {
          tmp.push(lead);
        }
      });
      setFilteredLeads(tmp);
    } else {
      let tmp: any[] = [];
      filteredLeads.forEach((lead: any) => {
        if (lead.status !== "REJECTED") {
          tmp.push(lead);
        }
      });

      setFilteredLeads(tmp);
    }
  };
  const handleStartedClick = () => {
    setStartedClicked(!startedClicked);
    if (!startedClicked) {
      let tmp = filteredLeads;
      leads.forEach((lead: any) => {
        if (lead.status === "STARTED") {
          tmp.push(lead);
        }
      });
      setFilteredLeads(tmp);
    } else {
      let tmp: any[] = [];
      filteredLeads.forEach((lead: any) => {
        if (lead.status !== "STARTED") {
          tmp.push(lead);
        }
      });

      setFilteredLeads(tmp);
    }
  };
  const closeLeadPopup = () => {
    setShowAddLeadPopup(false);
    getLeads();
  };
  return (
    <div className="leads-basis">
      <div className="add-button-background">
        <span className="heading margin-l20">Leads</span>
        <span
          onClick={() => setShowAddLeadPopup(true)}
          className="text-Proxima Nova700 orange-text pointer"
          style={{ marginLeft: 20, marginTop: 6 }}
        >
          + Add a new Lead
        </span>
      </div>
      {!auth.expert && (
        <div
          className="row-space-between wrap "
          style={{ justifyContent: hideHints ? "flex-end" : "space-between" }}
        >
          <div
            className="card-hints"
            style={{ display: hideHints ? "none" : "" }}
          >
            <div className="leads-hints-left">
              <b className="heading-lora">
                Take part in our lead share program!
              </b>

              <span className="text-Proxima Nova lightgrey">
                When you share a lead with us, a Gyld Manager will get in touch
                with the prospective customer and will turn your into a job
                opportunity. Once the job starts you’ll receive your regular
                lead share payment..
              </span>

              <div className="leads-arrow-out">
                <span className="text-Proxima Nova-small grey-blue mobile-center">
                  Add a lead
                </span>
                <ForwardIcon className="leads-arrow" sx={{ height: 20 }} />
                <span className="text-Proxima Nova-small grey-blue mobile-center">
                  We will verify the lead
                </span>
                <ForwardIcon className="leads-arrow" sx={{ height: 20 }} />
                <span className="text-Proxima Nova-small grey-blue mobile-center">
                  You’ll be paid as soon as the job starts
                </span>
              </div>
            </div>
            <img
              // style={{ width: "30%" }}
              src={require("../../assets/images/leads-top-box-1.png")}
              className="hints-image"
            />
            {!hideHints && (
              <Tooltip title="Hide" placement="bottom" arrow>
                <RemoveCircleIcon
                  sx={{ fontSize: 30 }}
                  className="help-icon"
                  onClick={() => setHideHints(!hideHints)}
                  style={{
                    justifySelf: hideHints ? "flex-end" : "",
                  }}
                ></RemoveCircleIcon>
              </Tooltip>
            )}
          </div>

          <div
            className="row-space-between"
            style={{ display: hideHints ? "" : "none" }}
          >
            <HintsHidden
              hideHints={hideHints}
              setHideHints={setHideHints}
            ></HintsHidden>
          </div>
        </div>
      )}
      {auth.selectedView !== "LEAD PROVIDER" && (
        <div className="separate-area">
          <LeadInvoices />
        </div>
      )}
      {showAddLeadPopup && (
        <AddLead show={showAddLeadPopup} onClose={closeLeadPopup} />
      )}
      <div className="leads-background-grey">
        {window.innerWidth >= 1440 ? (
          <div className="projects-row-start">
            <div
              className="kpi-card-leads margin-right-small"
              style={{
                border: !startedClicked
                  ? "0px solid grey"
                  : "1px solid #FF6210",
                boxShadow: !startedClicked
                  ? "0px 2px 6px rgba(0, 0, 0, 0.16)"
                  : "none",
              }}
              onClick={handleStartedClick}
            >
              <HourglassEmptyIcon className="margin-kpi-projects req-turquoise" />
              <strong className="text-Proxima Nova700-small darkgrey padding-r5">
                Leads in Progress
              </strong>
              <div className="kpi-row">
                <span className="grey-text">
                  {" "}
                  {" ( " + numStarted.toString() + " )"}
                </span>
              </div>
            </div>

            <div
              className="kpi-card-leads margin-right-small"
              style={{
                border: !finishedClicked
                  ? "0px solid grey"
                  : "1px solid #FF6210",

                boxShadow: !finishedClicked
                  ? "0px 2px 6px rgba(0, 0, 0, 0.16)"
                  : "none",
              }}
              onClick={handleFinishedClick}
            >
              <DoneAllIcon className="margin-kpi-projects req-green" />
              <strong className="text-Proxima Nova700-small darkgrey padding-r5">
                Finished Leads
              </strong>
              <div className="kpi-row">
                <span className="grey-text">
                  {" "}
                  {" ( " + numFinished.toString() + " )"}
                </span>
              </div>
            </div>
            <div
              className="kpi-card-leads margin-right-small"
              style={{
                border: !acceptedClicked
                  ? "0px solid grey"
                  : "1px solid #FF6210",
                boxShadow: !acceptedClicked
                  ? "0px 2px 6px rgba(0, 0, 0, 0.16)"
                  : "none",
              }}
              onClick={handleAcceptedClick}
            >
              <ThumbUpOffAltIcon className="margin-kpi-projects req-purple" />
              <strong className="text-Proxima Nova700-small darkgrey padding-r5">
                Accepted Leads
              </strong>
              <div className="kpi-row">
                <span className="grey-text">
                  {" "}
                  {" ( " + numAccepted.toString() + " )"}
                </span>
              </div>
            </div>
            <div
              className="kpi-card-leads margin-right-small"
              style={{
                border: !checkedClicked
                  ? "0px solid grey"
                  : "1px solid #FF6210",
                boxShadow: !checkedClicked
                  ? "0px 2px 6px rgba(0, 0, 0, 0.16)"
                  : "none",
              }}
              onClick={handleCheckedClick}
            >
              <RunningWithErrorsIcon className="margin-kpi-projects req-orange" />
              <strong className="text-Proxima Nova700-small darkgrey padding-r5">
                {" "}
                Leads being checked
              </strong>
              <div className="kpi-row">
                <span className="grey-text">
                  {" "}
                  {" ( " + numChecked.toString() + " )"}
                </span>
              </div>
            </div>
            <div
              className="kpi-card-leads"
              style={{
                border: !rejectedClicked
                  ? "0px solid grey"
                  : "1px solid #FF6210",
                boxShadow: !rejectedClicked
                  ? "0px 2px 6px rgba(0, 0, 0, 0.16)"
                  : "none",
              }}
              onClick={handleRejectedClick}
            >
              <DoDisturbIcon className="margin-kpi-projects req-red" />
              <strong className="text-Proxima Nova700-small darkgrey padding-r5">
                {" "}
                Rejected Leads
              </strong>
              <div className="kpi-row">
                <span className="grey-text">
                  {" "}
                  {" ( " + numRejected.toString() + " )"}
                </span>
              </div>
            </div>
          </div>
        ) : (
          <Box className="full-percent-width margin-t20">
            <FormControl fullWidth>
              <InputLabel shrink={true} id="demo-simple-select-label">
                Filter Leads
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={mobileFilter}
                onChange={(event) => {
                  handleChangeMobileFilter(event.target.value);
                }}
                input={
                  <OutlinedInput
                    id="select-multiple-chip"
                    label="Filter Leads"
                    notched
                  />
                }
              >
                {" "}
                <MenuItem value={"ALL"}>
                  <ClearAllIcon className="margin-r3" />
                  All Leads
                </MenuItem>
                <MenuItem value={"STARTED"}>
                  {" "}
                  <HourglassEmptyIcon className="margin-r3 req-turquoise" />
                  Leads in Progress {" ( " + numStarted.toString() + " )"}
                </MenuItem>
                <MenuItem value={"FINISHED"}>
                  {" "}
                  <DoneAllIcon className="margin-r3 req-green" />
                  Finished Leads {" ( " + numFinished.toString() + " )"}
                </MenuItem>
                <MenuItem value={"ACCEPTED"}>
                  {" "}
                  <ThumbUpOffAltIcon className="margin-r3 req-purple" />
                  Accepted Leads {" ( " + numAccepted.toString() + " )"}
                </MenuItem>
                <MenuItem value={"PENDING"}>
                  {" "}
                  <RunningWithErrorsIcon className="margin-r3 req-orange" />
                  Leads being checked {" ( " + numChecked.toString() + " )"}
                </MenuItem>
                <MenuItem value={"REJECTED"}>
                  {" "}
                  <DoDisturbIcon className="margin-r3 req-red" />
                  Rejected Leads {" ( " + numRejected.toString() + " )"}
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        )}

        <div className="separate-area ">
          {filteredLeads.length > 0 ? (
            <div>
              {filteredLeads.map((lead: any) => {
                const itemProps =
                  auth.selectedGroup === "LEAD" &&
                  auth.selectedGroupId === lead.id
                    ? { ref: scrollToRef }
                    : {};

                return (
                  <div key={lead.id} {...itemProps} onClick={clearSelected}>
                    {" "}
                    <LeadCard
                      highlight={lead.id === auth.selectedGroupId}
                      key={lead.id}
                      hotSkills={hotSkills}
                      lead={lead}
                    />
                  </div>
                );
              })}
            </div>
          ) : leads.length > 0 ? (
            <div>
              {leads.map((lead: any) => {
                const itemProps =
                  auth.selectedGroup === "LEAD" &&
                  auth.selectedGroupId === lead.id
                    ? { ref: scrollToRef }
                    : {};
                {
                }
                return (
                  <div
                    key={lead.id}
                    {...itemProps}
                    onClick={clearSelected}
                    style={{ marginTop: 20 }}
                  >
                    <LeadCard
                      highlight={lead.id === auth.selectedGroupId}
                      key={lead.id}
                      lead={lead}
                      hotSkills={hotSkills}
                    />
                  </div>
                );
              })}
            </div>
          ) : (
            <br />
          )}
        </div>
      </div>
    </div>
  );
}

export default LeadsUser;
