import React, { useEffect } from "react";
import "./Developers.css";
import locationSlice from "../../store/slices/location";
import { useDispatch } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Carousel from "react-material-ui-carousel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import { Link } from "react-router-dom";

const Developers = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(locationSlice.actions.setLocation({ path: "/developers" }));
  }, []);

  return (
    <div className="container">
      <Carousel
        height={window.innerWidth < 800 ? 870 : 600}
        interval={7000}
        className="carousel margin-b50"
      >
        <div className="headerBar">
          <div className="leftSideHeader">
            <strong className="home-title">Tired of bad projects?</strong>
            <span className="home-subtitle lightgrey padding-up-down">
              Get vetted jobs only
            </span>
            <span className="text-Proxima Nova darkgrey text-align-left">
              The Gyld qualifies job leads carefully to offer you projects that
              align with your expertise and passion. Our project managers handle
              all client communication and remove the stress of dealing with
              unclear or poorly managed projects. Here, you can focus solely on
              what you do best—coding!
            </span>
            <button className="button-black-small text-Proxima Nova700-small nowrap margin-t40">
              <Link className="signUpLink" to="/contact">
                Request Sign Up
              </Link>
            </button>
          </div>
          <img
            className="dev-image1"
            src={require("../../assets/images/tired.png")}
            alt="developing vector"
          />
        </div>

        <div className="headerBar">
          <div className="leftSideHeader">
            <strong className="home-title">Don’t want to feel alone?</strong>
            <span className="home-subtitle lightgrey padding-up-down">
              Get support from your peers
            </span>
            <span className="text-Proxima Nova darkgrey text-align-left">
              The Gyld is all about teamwork. Every project is managed, and you
              have direct access to your peers, senior developers, and project
              managers for any guidance or support you need. You can be assured
              of prompt and seamless support from our team, allowing you to
              focus on delivering quality work without the administrative
              hassle.
            </span>
            <button className="button-black-small text-Proxima Nova700-small nowrap margin-t40">
              <Link className="signUpLink" to="/contact">
                Request Sign Up
              </Link>
            </button>
          </div>
          <img
            className="dev-image1"
            src={require("../../assets/images/alone.png")}
            alt="developing vector"
          />
        </div>

        <div className="headerBar">
          <div className="leftSideHeader ">
            <strong className="home-title">
              You want to learn a new skill?
            </strong>
            <span className="home-subtitle lightgrey padding-up-down">
              Join our mentorship program
            </span>
            <span className="text-Proxima Nova darkgrey text-align-left">
              The Gyld’s mentorship program is designed to help you grow. Work
              on real-world projects alongside experienced developers who will
              guide you through complex technical challenges. We pair junior and
              senior developers, allowing you to learn new skills and gain
              insights into advanced coding techniques, teamwork, and industry
              practices.
            </span>
            <button className="button-black-small text-Proxima Nova700-small nowrap margin-t40">
              <Link className="signUpLink" to="/contact">
                Request Sign Up
              </Link>
            </button>
          </div>
          <img
            className="dev-image1"
            src={require("../../assets/images/skill.png")}
            alt="developing vector"
          />
        </div>
      </Carousel>

      {/* <div className="dev-row-space-between-center margin-b20 full-percent-width">
        <div className="dev-leftSideHeader">
          <strong className="home-title">Want better incentives?</strong>
          <span className="home-subtitle lightgrey padding-up-down">
            Benefit from the Gyld’s profit share program
          </span>
          <span className="text-Proxima Nova darkgrey text-align-left">
            Gyld’s pricing is designed to be competitive, with hourly rates that
            reflect experience and skill levels. From the revenue generated, we
            allocate a portion to platform costs, project management, and our
            unique profit-share program. Every active Gyld developer shares in
            these profits based on the hours they’ve contributed. So you’re not
            only paid for your time, but you’re also rewarded for being a part
            of Gyld’s growth.
            At The Gyld, we believe that everyone deserves to
            be rewarded for their hard work. We’ve created a profit-share
            program where a portion of our quarterly profits is distributed
            among developers based on their contribution. So, the more hours you
            work on Gyld projects, the greater your share. Regardless of
            experience level, every developer has an equal opportunity to
            benefit from the profit share program.
          </span>
        </div>
        <div className="half-percent-width row-center">
          <img
            className="dev-image"
            src={require("../../assets/images/dev-slider3.png")}
            alt="developing vector"
          />
        </div>
      </div> */}

      <div className="pricing-cards margin-t40">
        <div className="pricing-cards-out">
          <div className="pricing-cards-top">
            <span className="home-title">Junior</span>
            <div>
              <span className="home-title">22€</span>
              <span className="text-Proxima Nova700">/hour</span>
            </div>
          </div>

          <div className="pricing-cards-bottom">
            <div className="row-start">
              <DoneRoundedIcon className="margin-right-small" />
              <span className="text-Proxima Nova700">
                Typically starting out their career
              </span>
            </div>
            <div className="row-start ">
              <DoneRoundedIcon className="margin-right-small" />
              <span className="text-Proxima Nova700">
                Experience with the skill will typically be 1 year or less
              </span>
            </div>
            <div className="row-start">
              <DoneRoundedIcon className="margin-right-small" />
              <span className="text-Proxima Nova700">
                Can rely on senior support network
              </span>
            </div>
            <div className="row-start">
              <DoneRoundedIcon className="margin-right-small" />
              <span className="text-Proxima Nova700">
                Can be actively mentored by veteran developers
              </span>
            </div>
          </div>
        </div>

        <div className="pricing-cards-out">
          <div className="pricing-cards-top">
            <span className="home-title">Mid</span>
            <div>
              <span className="home-title">30€</span>
              <span className="text-Proxima Nova700">/hour</span>
            </div>
          </div>

          <div className="pricing-cards-bottom">
            <div className="row-start">
              <DoneRoundedIcon className="margin-right-small" />
              <span className="text-Proxima Nova700">
                Typically supported multiple clients & projects
              </span>
            </div>
            <div className="row-start ">
              <DoneRoundedIcon className="margin-right-small" />
              <span className="text-Proxima Nova700">
                Skill experience will typically be 2+ years
              </span>
            </div>
            <div className="row-start ">
              <DoneRoundedIcon className="margin-right-small" />
              <span className="text-Proxima Nova700">
                Is building up industry specific hot skills
              </span>
            </div>
            <div className="row-start ">
              <DoneRoundedIcon className="margin-right-small" />
              <span className="text-Proxima Nova700">
                Will actively exchange with other industry and technology
                experts
              </span>
            </div>
          </div>
        </div>

        <div className="pricing-cards-out">
          <div className="pricing-cards-top">
            <span className="home-title">Senior</span>
            <div>
              <span className="home-title">37€</span>
              <span className="text-Proxima Nova700">/hour</span>
            </div>
          </div>
          <div className="pricing-cards-bottom">
            <div className="row-start">
              <DoneRoundedIcon className="margin-right-small" />
              <span className="text-Proxima Nova700">
                Typically supported dozens of clients & projects
              </span>
            </div>
            <div className="row-start">
              <DoneRoundedIcon className="margin-right-small" />
              <span className="text-Proxima Nova700">
                Skill experience will typically be 5+ years
              </span>
            </div>
            <div className="row-start">
              <DoneRoundedIcon className="margin-right-small" />
              <span className="text-Proxima Nova700">
                Has one or more favorite industries
              </span>
            </div>
            <div className="row-start">
              <DoneRoundedIcon className="margin-right-small" />
              <span className="text-Proxima Nova700">
                Is an industry and technology expert in the field
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="what-gyld-dev">
        <img
          className="what-image-dev"
          src={require("../../assets/images/banner.png")}
          alt="developing vector"
        />
        <div className="right-side-what-gyld-dev">
          <strong className="home-title ">Want better incentives?</strong>
          <span className="what-text">
            As Gyld member you automatically participate in at least one or all
            three profit share programs. The shares will be paid out on a
            quarterly basis.
          </span>
          <div className="what-column-space-between">
            <div>
              <div className="roww">
                <CheckCircleIcon className="green-check-icon" />
                <div className="roww-pad">
                  <span className="what-subtitle">Project Profit Share </span>
                </div>
              </div>
            </div>
            <span className="what-text">
              All Gyld developers automatically participate in the Gyld profit
              share program. A percentage of all project profits will be equally
              shared among its active members, based on the individual hours
              invested in a project.
            </span>

            <div>
              <CheckCircleIcon className="green-check-icon" />
              <span className="what-subtitle">Lead Share</span>
            </div>
            <span className="what-text">
              If you bring a project Lead to and it converts, you will be part
              of our lead share program and earn extra percentages.
            </span>
            <div>
              <CheckCircleIcon className="green-check-icon" />
              <span className="what-subtitle">Gyld Manager Share</span>
            </div>
            <span className="what-text">
              As a Mid or Senior developer, you may qualify as a Gyld Manager.
              You will be interfacing and coordinating directly with clients and
              support the project team. Since this role entails great
              responsibility, this opportunity is only open to trusted members.
            </span>
          </div>
          {/* <div className="what-gyld-end">
              <span className="home-text padding-r10">
                Think it‘s a match? &nbsp;
              </span>
              <button className="button-black-small text-Proxima Nova700-small nowrap">
                <Link className=" signUpLink" to="/contact">
                  Request Sign Up
                </Link>
              </button>
            </div> */}
        </div>
      </div>
    </div>
  );
};

export default Developers;
