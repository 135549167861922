import React, { useState, useEffect } from "react";
import Moment from "moment";
import "./ProjectCard.css";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import SelectApplicationType from "../select-application-type/SelectApplicationType";
import Tooltip from "@mui/material/Tooltip";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import PublicProfile from "../../screens/public-profile/PublicProfile";
import PublicProfileClient from "../../screens/public-profile/PublicProfileClient";
import ArticleIcon from "@mui/icons-material/Article";
import WhatshotIcon from "@mui/icons-material/Whatshot";

function ProjectCard(props: any) {
  const [showSelectApplicationType, setShowSelectApplicationType] =
    useState(false);
  const auth = useSelector((state: RootState) => state.auth);
  const [showProfile, setShowProfile] = useState(false);
  const [readMore, setReadMore] = useState(false);

  const [showProfileClient, setShowProfileClient] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>();
  const [selectedClient, setSelectedClient] = useState<any>();
  const applySelect = (value: string) => {
    props.apply(props.job.job.id, value, props.job.id);
  };

  const showPublicProfile = (user: any) => {
    setShowProfile(true);
    setSelectedUser(user.user_id);
  };
  const showPublicProfileClient = (user: any) => {
    setShowProfileClient(true);
    setSelectedClient(user.client_id);
  };
  const closeProfile = () => {
    setShowProfile(false);
    setSelectedUser(null);
    setShowProfileClient(false);
    setSelectedClient(null);
  };
  return (
    <div className={!readMore ? "opp-boxes" : "opp-boxes-readmore"}>
      {showSelectApplicationType && (
        <SelectApplicationType
          applySelect={applySelect}
          setShowSelectApplicationType={setShowSelectApplicationType}
        />
      )}
      {showProfile && (
        <div
          style={{
            visibility: showProfile ? "visible" : "hidden",
            opacity: showProfile ? "1" : "0",
          }}
          className={"overlay"}
        >
          <div className={"popup-pub-profile"}>
            <span className={"pub-prof-close"} onClick={closeProfile}>
              &times;
            </span>
            <PublicProfile user={selectedUser} />
          </div>
        </div>
      )}

      {showProfileClient && (
        <div
          style={{
            visibility: showProfileClient ? "visible" : "hidden",
            opacity: showProfileClient ? "1" : "0",
          }}
          className={"overlay"}
        >
          <div className={"popup-pub-profile "}>
            <span
              className={"close align-self-end margin-t5"}
              onClick={closeProfile}
            >
              &times;
            </span>
            <PublicProfileClient user={selectedClient} />
          </div>
        </div>
      )}
      <div className="row-space-between">
        <span className="text-Proxima Nova700">{props.job.job.name}</span>

        <a
          target="_blank"
          href={
            process.env.REACT_APP_API_URL?.split("/api")[0] + props.job?.file
          }
          download
        >
          <Tooltip title="View Contract" placement="bottom" arrow>
            <ArticleIcon className="opp-box-end-icon" />
          </Tooltip>
        </a>
      </div>

      <div className="opp-box-row">
        <span className="opp-status">
          Status:{" "}
          {props.job.job.status === "HAS_APPLICANTS"
            ? "Matched"
            : props.job.job.status}
        </span>
        {window.innerWidth > 1200 && <div className="ball"></div>}
        <span className="opp-status">Field: {props.job.job.industry}</span>
        {window.innerWidth > 1200 && <div className="ball"></div>}
        <span className="opp-status">Client: {props.job.job.client_type}</span>
        {window.innerWidth > 1200 && <div className="ball"></div>}
        <span className="opp-status">
          {" "}
          {Moment(props.job.job.start_date).format("D MMM yy")} -{" "}
          {Moment(props.job.job.end_date).format("D MMM yy")}
        </span>
        {window.innerWidth > 1200 && <div className="ball"></div>}
        <span className="opp-status">
          {props.job.job.job_type
            .toLowerCase()
            .replace(/_/g, " ")
            .charAt(0)
            .toUpperCase() +
            props.job.job.job_type.toLowerCase().replace(/_/g, " ").slice(1)}
        </span>
      </div>
      <div className="margin-t20 margin-b20">
        <span className="text-Proxima Nova-small opp-grey  ">
          {!readMore
            ? [...props.job.job.description].slice(0, 400)
            : props.job.job.description}
        </span>
        {[...props.job.job.description].length > 400 && (
          <span className="text-Proxima Nova-small opp-grey ">...</span>
        )}
        {[...props.job.job.description].length > 400 && (
          <span
            className="text-Proxima Nova-small orange-text pointer margin-l10"
            onClick={() => setReadMore(!readMore)}
          >
            {readMore ? "Read Less" : "Read More"}
          </span>
        )}
      </div>
      <div className="opp-box-row-buttons">
        {props.job.job.skills.map((skill: string) => {
          return (
            <span key={skill} className="lead-card-skills-buttons">
              {skill}
              {props.hotSkills?.map((hotSkill: any) => {
                if (hotSkill.name === skill) {
                  return (
                    <WhatshotIcon
                      key={hotSkill.name}
                      style={{
                        color: "#ff6210",
                        marginBottom: 5,
                      }}
                    />
                  );
                }
              })}
            </span>
          );
        })}
      </div>

      <div
        className={
          [...props.job.job.description].length > 400
            ? "row-space-between margin-b10n"
            : "row-space-between "
        }
      >
        <div className="opp-box-row-end">
          {auth.selectedView === "CLIENT" ? (
            <div className="opp-margin row-start-center">
              <span className="text-Proxima Nova-small">Developer: </span>
              {props.job.dev.image && props.job.dev.image.includes("media") ? (
                <img
                  onClick={() => showPublicProfile(props.job.dev)}
                  src={
                    process.env.REACT_APP_API_URL?.split("/api")[0] +
                    props.job.dev.image
                  }
                  className="profile-img-small"
                />
              ) : (
                <div
                  className="profile-img-small no-pic-small"
                  onClick={() => showPublicProfile(props.job.dev)}
                >
                  <span>{props.job.dev.username.charAt(0)}</span>
                </div>
              )}

              <span className="text-Proxima Nova700 darkgrey padding-normal">
                {props.job.dev.username}
              </span>
            </div>
          ) : (
            <div className="opp-margin row-start-center">
              <span className="text-Proxima Nova-small">Client: </span>
              {props.job.client.image &&
              props.job.client.image.includes("media") ? (
                <img
                  onClick={() => showPublicProfileClient(props.job.client)}
                  src={
                    process.env.REACT_APP_API_URL?.split("/api")[0] +
                    props.job.client.image
                  }
                  className="profile-img-small"
                />
              ) : (
                <div
                  className="profile-img-small no-pic-small"
                  onClick={() => showPublicProfileClient(props.job.client)}
                >
                  <span>{props.job.client.username.charAt(0)}</span>
                </div>
              )}

              <span className="text-Proxima Nova700 darkgrey padding-normal">
                {props.job.client.username}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ProjectCard;
