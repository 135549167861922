import React, { useEffect, useMemo, useState, useRef } from "react";
import { BarStack } from "@visx/shape";
import { AnyScaleBand, SeriesPoint } from "@visx/shape/lib/types";
import { Group } from "@visx/group";
import { Grid } from "@visx/grid";
import { AxisBottom } from "@visx/axis";
import { LinePath } from "@visx/shape";
import { scaleBand, scaleLinear, scaleOrdinal } from "@visx/scale";
import { timeParse, timeFormat } from "d3-time-format";
import { useTooltip, useTooltipInPortal, defaultStyles } from "@visx/tooltip";
import { LegendOrdinal } from "@visx/legend";
import { localPoint } from "@visx/event";
import { scaleLinear as scaleLinearLine } from "@visx/scale";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { RootState } from "../../store";
import { Menu, MenuItem } from "@mui/material";

interface JobData {
  date: string;

  Contract: number;
  Lead: number;
}

type TypeName = "Contract" | "Lead";

type TooltipDataContract = {
  bar: SeriesPoint<JobData>;
  key: TypeName;
  index: number;
  height: number;
  width: number;
  x: number;
  y: number;
  color: string;
};

export type BarStackProps = {
  data: any;
  dataLeads: any;
  totalPayments: number;
  totalLeads: number;
  width: number;
  height: number;
  margin?: { top: number; right: number; bottom: number; left: number };
  events?: boolean;
};

const purple1 = "#6c5efb";
const purple2 = "#c998ff";
export const purple3 = "#a44afe";
export const background = "#eaedff";
const defaultMargin = { top: 40, right: 0, bottom: 0, left: 0 };
const tooltipStyles = {
  ...defaultStyles,
  minWidth: 60,
  backgroundColor: "rgba(0,0,0,0.9)",
  color: "white",
};

let tooltipTimeout: number;

export default function FinancesDashClient({
  width,
  height,
  events = false,
  margin = defaultMargin,
  data,
  dataLeads,
  totalPayments,
  totalLeads,
}: BarStackProps) {
  const {
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip,
  } = useTooltip<TooltipDataContract>();

  const keys = Object.keys(data[0]).filter((d) => d !== "date") as TypeName[];

  const keysLeads = Object.keys(
    dataLeads.length > 0 ? dataLeads[0] : data[0]
  ).filter((d) => d !== "date") as TypeName[];

  const hoursTotalContract = data.reduce((allTotals: any, currentDate: any) => {
    const totalHoursContract = keys.reduce((dailyTotal, k) => {
      dailyTotal += Number(currentDate[k]);
      return dailyTotal;
    }, 0);
    allTotals.push(totalHoursContract);
    return allTotals;
  }, [] as number[]);
  const hoursTotalLead = dataLeads.reduce(
    (allTotals: any, currentDate: any) => {
      const totalHoursLead = keysLeads.reduce((dailyTotal, k) => {
        dailyTotal += Number(currentDate[k]);
        return dailyTotal;
      }, 0);
      allTotals.push(totalHoursLead);
      return allTotals;
    },
    [] as number[]
  );
  const [view, setView] = useState("Contract");
  const menuRef = useRef<HTMLDivElement>(null);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const parseDate = timeParse("%Y-%m-%d");
  const format = timeFormat("%b ");
  const formatDate: any = (date: string) => format(parseDate(date) as Date);

  // accessors
  const getDate = (d: JobData) => d.date;

  // scales
  const dateScale = scaleBand<string>({
    domain: data.map(getDate),
    padding: 0.2,
  });

  const dateScaleLead = scaleBand<string>({
    domain: dataLeads.map(getDate),
    padding: 0.2,
  });

  //   const dateScaleLine = scaleBand<string>({
  //     domain: lineData.map(getDate),
  //     padding: 0.2,
  //   });

  const hoursScaleContract = scaleLinear<number>({
    domain: [0, Math.max(...hoursTotalContract)],
    nice: true,
  });

  const colorScaleContract = scaleOrdinal<TypeName, string>({
    domain: keys,
    range: [purple1, purple2, purple3],
  });

  const hoursScaleLead = scaleLinear<number>({
    domain: [0, Math.max(...hoursTotalLead)],
    nice: true,
  });

  const colorScaleLead = scaleOrdinal<TypeName, string>({
    domain: keysLeads,
    range: [purple2, purple1, purple3],
  });

  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (view: string) => {
    setAnchorEl(null);
    setView(view);
  };

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    // TooltipInPortal is rendered in a separate child of <body /> and positioned
    // with page coordinates which should be updated on scroll. consider using
    // Tooltip or TooltipWithBounds if you don't need to render inside a Portal
    scroll: true,
  });
  const xMax = width;
  const yMax = height - margin.top - 100;
  dateScale.rangeRound([0, xMax]);
  dateScaleLead.rangeRound([0, xMax]);
  hoursScaleContract.range([yMax, 0]);
  hoursScaleLead.range([yMax, 0]);
  return width < 10 ? null : (
    <div>
      {dateScale && (
        <div
          style={{ position: "relative", padding: "20px 10px 10px 20px" }}
          className="column-space-between  card-finances full-percent-width"
        >
          {view === "Contract" ? (
            <span
              className="text-Proxima Nova700 margin-t20"
              style={{ alignSelf: "flex-start" }}
            >
              Total Paid: {totalPayments} €
            </span>
          ) : (
            <span
              className="text-Proxima Nova700 margin-t20"
              style={{ alignSelf: "flex-start" }}
            >
              Total Rebate: {totalLeads} €
            </span>
          )}
          <div className="selected-view">
            <span>{view}</span>

            <ArrowDropDownIcon onClick={handleClick} />
          </div>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem key={"Contract"} onClick={() => handleClose("Contract")}>
              Contracts
            </MenuItem>
            <MenuItem key={"Lead"} onClick={() => handleClose("Lead")}>
              Leads
            </MenuItem>
          </Menu>
          <svg ref={containerRef} width={width} height={height}>
            <rect
              x={0}
              y={0}
              width={width}
              height={height}
              fill={"#fff"}
              rx={14}
            />
            {view === "Contract" ? (
              <Grid
                top={margin.top}
                left={margin.left}
                xScale={dateScale}
                yScale={hoursScaleContract}
                width={xMax}
                height={yMax}
                stroke="black"
                strokeOpacity={0.1}
                xOffset={10}
              />
            ) : (
              <Grid
                top={margin.top}
                left={margin.left}
                xScale={dateScale}
                yScale={hoursScaleLead}
                width={xMax}
                height={yMax}
                stroke="black"
                strokeOpacity={0.1}
                xOffset={10}
              />
            )}

            <Group top={margin.top}>
              {view === "Contract" ? (
                <BarStack<JobData, TypeName>
                  data={data}
                  keys={keys}
                  x={getDate}
                  xScale={dateScale}
                  yScale={hoursScaleContract}
                  color={colorScaleContract}
                >
                  {(barStacks) =>
                    barStacks.map((barStack) =>
                      barStack.bars.map((bar) => (
                        <rect
                          key={`bar-stack-${barStack.index}-${bar.index}`}
                          x={bar.x}
                          y={bar.y}
                          height={bar.height}
                          width={bar.width}
                          fill={bar.color}
                          onClick={() => {
                            if (events)
                              alert(`clicked: ${JSON.stringify(bar)}`);
                          }}
                          onMouseLeave={() => {
                            tooltipTimeout = window.setTimeout(() => {
                              hideTooltip();
                            }, 300);
                          }}
                          onMouseMove={(event) => {
                            if (tooltipTimeout) clearTimeout(tooltipTimeout);
                            // TooltipInPortal expects coordinates to be relative to containerRef
                            // localPoint returns coordinates relative to the nearest SVG, which
                            // is what containerRef is set to in this example.
                            const eventSvgCoords = localPoint(event);
                            const left = bar.x + bar.width / 2;
                            showTooltip({
                              tooltipData: bar,
                              tooltipTop: eventSvgCoords?.y,
                              tooltipLeft: left,
                            });
                          }}
                        />
                      ))
                    )
                  }
                </BarStack>
              ) : (
                <BarStack<JobData, TypeName>
                  data={dataLeads}
                  keys={keysLeads}
                  x={getDate}
                  xScale={dateScaleLead}
                  yScale={hoursScaleLead}
                  color={colorScaleLead}
                >
                  {(barStacks) =>
                    barStacks.map((barStack) =>
                      barStack.bars.map((bar) => (
                        <rect
                          key={`bar-stack-${barStack.index}-${bar.index}`}
                          x={bar.x}
                          y={bar.y}
                          height={bar.height}
                          width={bar.width}
                          fill={bar.color}
                          onClick={() => {
                            if (events)
                              alert(`clicked: ${JSON.stringify(bar)}`);
                          }}
                          onMouseLeave={() => {
                            tooltipTimeout = window.setTimeout(() => {
                              hideTooltip();
                            }, 300);
                          }}
                          onMouseMove={(event) => {
                            if (tooltipTimeout) clearTimeout(tooltipTimeout);
                            // TooltipInPortal expects coordinates to be relative to containerRef
                            // localPoint returns coordinates relative to the nearest SVG, which
                            // is what containerRef is set to in this example.
                            const eventSvgCoords = localPoint(event);
                            const left = bar.x + bar.width / 2;
                            showTooltip({
                              tooltipData: bar,
                              tooltipTop: eventSvgCoords?.y,
                              tooltipLeft: left,
                            });
                          }}
                        />
                      ))
                    )
                  }
                </BarStack>
              )}
            </Group>

            <AxisBottom
              top={yMax + margin.top}
              scale={dateScale}
              tickFormat={formatDate}
              stroke={purple3}
              tickStroke={purple3}
              tickLabelProps={() => ({
                fill: purple3,
                fontSize: 11,
                textAnchor: "middle",
              })}
            />
          </svg>
          <div
            style={{
              position: "absolute",
              top: margin.top / 2 - 10,
              width: "100%",
              display: "flex",
              justifyContent: "center",
              fontSize: "14px",
            }}
          >
            {/* <LegendOrdinal
              scale={view === "Contract" ? colorScaleContract : colorScaleLead}
              direction="row"
              labelMargin="0 15px 0 0"
            /> */}
          </div>
          {tooltipOpen && tooltipData && (
            <TooltipInPortal
              top={tooltipTop}
              left={tooltipLeft}
              style={tooltipStyles}
            >
              <div
                style={{
                  color:
                    view === "Contract"
                      ? colorScaleContract(tooltipData.key)
                      : colorScaleLead(tooltipData.key),
                }}
              >
                <strong>{tooltipData.key}</strong>
              </div>
              <div>{tooltipData.bar.data[tooltipData.key]}€ </div>
              <div></div>
            </TooltipInPortal>
          )}
        </div>
      )}
    </div>
  );
}
