import "./PublicProfile.css";
import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";

import { useAlert } from "react-alert";
import {
  Button,
  Input,
  InputAdornment,
  OutlinedInput,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate, useLocation, Location } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import locationSlice from "../../store/slices/location";
import ImageUploading from "react-images-uploading";
import axiosService from "../../utils/axios";
import Autocomplete from "@mui/material/Autocomplete";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import moment from "moment";
import AddIcon from "@mui/icons-material/Add";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";

const PublicProfileClient = (props: any) => {
  const [UserName, setUserName] = useState("");

  const [Description, setDescription] = useState("");

  const [imageSel, setImageSel] = useState<any[]>([]);
  const [userId, setUserId] = useState<any>();

  const [country, setCountry] = useState("");
  const [user, setUser] = useState<any>();
  const dispatch = useDispatch();

  const alert = useAlert();
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useSelector((state: RootState) => state.auth);
  // useEffect(() => {
  //   dispatch(
  //     locationSlice.actions.setLocation({
  //       path: "/platform/public-profile",
  //     })
  //   );
  //   if (!auth.account) {
  //     navigate("/login");
  //   }
  // }, []);

  useEffect(() => {
    if (location.state) {
      setUserId(location.state);
    }
  }, [location]);

  useEffect(() => {
    if (props.user) {
      setUserId(props.user);
    }
  }, [props]);

  useEffect(() => {
    if (userId) {
      getUserInfo();
    }
  }, [userId]);

  useEffect(() => {
    setUserName("");
    setDescription("");
    setImageSel([]);

    setCountry("");
  }, []);

  const getUserInfo = () => {
    axiosService
      .get(
        `${process.env.REACT_APP_API_URL}/get_public_profile_client/` + userId
      )
      .then((res) => {
        setUser(res.data);
        setUserName(res.data.username);
        setDescription(res.data.description);
        setCountry(res.data.country);
        if (res.data.image !== null) {
          setImageSel([
            {
              data_url:
                process.env.REACT_APP_API_URL?.split("/api")[0] +
                res.data.image,
            },
          ]);
        }
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };
  // const reset = () => {
  //   setUnavailableStart("");
  // }

  return (
    // <div className="pub-profile-client-out">
    <div className="pub-profile-outline">
      <div className="pub-profile-scroll">
        <div className="column-start-center">
          <div className="image-item">
            {imageSel && imageSel.length > 0 ? (
              <img
                className="profile-page-pic"
                style={{ width: "100%" }}
                src={imageSel[imageSel.length - 1]["data_url"]}
                alt=""
                width="100"
              />
            ) : (
              <div className="profile-page-pic no-pic">
                <span>{UserName.charAt(0)}</span>
              </div>
            )}
            {/* <div className="image-item__btn-wrapper">

                    <button onClick={() => onImageUpdate(index)}>Update</button>
                    <button onClick={() => onImageRemove(index)}>Remove</button>
                  </div> */}
          </div>
          <span className="text-Proxima Nova700 opp-status padding-up-down">
            Username: {UserName}
          </span>

          <span className="text-Proxima Nova700 opp-status">
            Country: {country}
          </span>
        </div>
        <div className="column-start-start full-percent-height separate-area padding-r20">
          <span className="heading-lora padding-up-down">Description</span>
          <span className="text-Proxima Nova700 opp-status">
            {" "}
            {Description}
          </span>
        </div>
      </div>
    </div>
    // </div>
  );
};
export default PublicProfileClient;
