import React, { useState, useEffect } from "react";
import Moment from "moment";
import "./InvoiceCard.css";
import { useAlert } from "react-alert";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import AvTimerIcon from "@mui/icons-material/AvTimer";
import axiosService from "../../utils/axios";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import Tooltip from "@mui/material/Tooltip";

function InvoiceCard(props: any) {
  const alert = useAlert();

  const auth = useSelector((state: RootState) => state.auth);
  const cancelInvoice = () => {
    axiosService
      .delete(
        `${process.env.REACT_APP_API_URL}/cancel_invoice/` + props.invoice.id
      )
      .then((res) => {
        props.getProjects();
      })

      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };
  return (
    <div
      className={
        props.highlight
          ? "opp-box-timesheets-half full-percent-width highlight-selected"
          : "opp-box-timesheets-half full-percent-width"
      }
    >
      <div className="row-space-between">
        <span className="text-Proxima Nova700 opp-grey">
          Invoice {props.invoice.id}
        </span>
      </div>
      <div className="opp-box-row">
        <span className="opp-status">
          {props.invoice.createdAt && (
            <span className="font">
              {Moment(props.invoice.createdAt).format("D MMM yy")}
            </span>
          )}
        </span>
        {window.innerWidth > 1200 && <div className="ball"></div>}
        <span
          style={{
            color:
              props.invoice.status === "CANCELLED"
                ? "#c47878"
                : props.invoice.status === "NEW"
                ? "#767fad"
                : "#366B56",
          }}
          className="opp-status"
        >
          Status:{" "}
          {props.invoice.status
            .toLowerCase()
            .replace(/_/g, " ")
            .charAt(0)
            .toUpperCase() +
            props.invoice.status.toLowerCase().replace(/_/g, " ").slice(1)}
        </span>
        {window.innerWidth > 1200 && <div className="ball"></div>}
        <span className="opp-status">
          Amount: {props.invoice.amount + " €"}
        </span>
      </div>
      {/* <span className="text-Proxima Nova">
        <a href={props.timesheet.file} download>
          Download
        </a>
      </span> */}

      <div className="opp-box-end-end">
        <a
          target="_blank"
          href={
            process.env.REACT_APP_API_URL?.split("/api")[0] +
            props.invoice?.file
          }
          download
        >
          <div className="circle-left">
            <Tooltip title="Download" placement="bottom" arrow>
              <DownloadIcon style={{ color: "#626DA1" }} />
            </Tooltip>
          </div>
        </a>
        {props.invoice.status === "NEW" &&
          auth.selectedView === "DEVELOPER" && (
            <div className="circle-right">
              <Tooltip title="Cancel Invoice" placement="bottom" arrow>
                <CloseIcon
                  onClick={cancelInvoice}
                  style={{
                    color: "#626DA1",
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
            </div>
          )}
      </div>
    </div>
  );
}

export default InvoiceCard;
