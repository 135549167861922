import React, { useEffect, useState } from "react";
import "./SelectApplicationType.css";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Modal from "@mui/material/Modal";

const SelectApplicationType = (props: any) => {
  const [applicationType, setApplicationType] = useState("PROJECT");
  //   const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  const closeHandler = (e: any) => {
    setShow(false);
    props.onClose();
  };

  useEffect(() => {
    // dispatch(locationSlice.actions.setLocation({ path: "/developers" }));
    setShow(props.show);
  }, [props.show]);

  const close = (e: any) => {
    if (e.keyCode === 27) {
      closeHandler(true);
    }
  };

  const handleApply = () => {
    props.setShowSelectApplicationType(false);
    props.applySelect(applicationType);

    setShow(false);
  };

  return (
    // <div
    //   style={{
    //     visibility: "visible",
    //     opacity: "1",
    //   }}
    //   className={"overlay "}
    // >
    <Modal
      open={show}
      onClose={closeHandler}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={"popup-select"}>
        <div className="select-appli-scroll">
          <div className="row-space-between-center">
            <span className="text-Proxima Nova700">
              How do you want to apply fot this Job?
            </span>
            <span className={"close"} onClick={closeHandler}>
              &times;
            </span>
          </div>

          <div className="select-appli-column text-Proxima Nova700-small">
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={applicationType}
                name="radio-buttons-group"
                onChange={(event) => setApplicationType(event.target.value)}
              >
                <FormControlLabel
                  value="PROJECT"
                  control={<Radio />}
                  label="I want to do it"
                />

                <FormControlLabel
                  value="LEARN"
                  control={<Radio />}
                  label="I want to learn"
                />
                <FormControlLabel
                  value="HELP"
                  control={<Radio />}
                  label="I want to help"
                />
              </RadioGroup>
            </FormControl>
          </div>

          <div className="add-phase-row-flex-end padding-up-down">
            <button
              className="button-white-small text-Proxima Nova700-small margin-r20"
              onClick={closeHandler}
            >
              Cancel
            </button>
            <button
              className="button-black-small-nm text-Proxima Nova700-small"
              onClick={handleApply}
            >
              Apply
            </button>
          </div>
          {/* <div className="row-start">
            <button className="check-button"></button>
            <span className="text-Proxima Nova lightgrey">
              Do not show this msg again
            </span>
          </div> */}

          {/* <img
          onClick={() => props.setShowSelectApplicationType(false)}
          className="close-icon"
          src={require("../../assets/images/close.png")}
        /> */}
        </div>
      </div>
    </Modal>
  );
};

export default SelectApplicationType;
