import { useEffect, useState } from "react";
import "./AddInvoice.css";
import PropTypes from "prop-types";
import axiosService from "../../utils/axios";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Button, Fab, Input, TextareaAutosize, TextField } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { AddIcCallOutlined } from "@mui/icons-material";
import { useAlert } from "react-alert";
import Modal from "@mui/material/Modal";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 500,
    },
  },
};

const AddInvoice = (props: any) => {
  const [show, setShow] = useState(false);
  const [chargesDevContracts, setChargesDevContracts] = useState<any[]>([]);
  const [chargesDevProfitShares, setChargesDevProfitShares] = useState<any[]>(
    []
  );
  const [chargesLeadRebates, setChargesLeadRebates] = useState<any[]>([]);
  const [selectedChargesDevContracts, setSelectedChargesDevContracts] =
    useState<any[]>([]);
  const [selectedChargesDevProfitShares, setSelectedChargesDevProfitShares] =
    useState<any[]>([]);
  const [selectedChargesLeadRebates, setSelectedChargesLeadRebates] = useState<
    any[]
  >([]);
  const [totalAmountContracts, setTotalAmountContracts] = useState(0);
  const [totalAmountProfitShares, setTotalAmountProfitShares] = useState(0);
  const [totalAmountLeadRebates, setTotalAmountLeadRebates] = useState(0);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [hours, setHours] = useState<number>(0);
  const [status, setStatus] = useState("");
  const [file, setFile] = useState<any>();

  const alert = useAlert();

  const [isFirst, setIsFirst] = useState(true);

  const auth = useSelector((state: RootState) => state.auth);

  const closeHandler = (e: any) => {
    reset();
    setShow(false);
    props.onClose(false);
  };

  useEffect(() => {
    const close = (e: any) => {
      if (e.keyCode === 27) {
        closeHandler(true);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);
  useEffect(() => {
    if (isFirst) {
      setIsFirst(false);
    }
    setChargesDevContracts([]);
    setChargesDevProfitShares([]);
    setChargesLeadRebates([]);
    setTotalAmountContracts(0);
    setTotalAmountLeadRebates(0);
    setTotalAmountProfitShares(0);
    setSelectedChargesDevContracts([]);
    setSelectedChargesDevProfitShares([]);
    setSelectedChargesLeadRebates([]);
    setFile(null);
    getCharges();
    setFromDate("");
    setToDate("");
    setHours(0);
  }, []);

  const onFileChange = (event: any) => {
    setFile(event.target.files[0]);
  };
  const reset = () => {
    setChargesDevContracts([]);
    setChargesDevProfitShares([]);
    setChargesLeadRebates([]);
    setTotalAmountContracts(0);
    setTotalAmountLeadRebates(0);
    setTotalAmountProfitShares(0);
    setSelectedChargesDevContracts([]);
    setSelectedChargesDevProfitShares([]);
    setSelectedChargesLeadRebates([]);
    setFile(null);
    setFromDate("");
    setToDate("");
    setHours(0);
  };

  const getCharges = () => {
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_charges_dev_contracts_user`)
      .then((res) => {
        let charges: any[] = [];
        res.data.map((charge: any) => {
          if (charge.status === "NEW" && !charge.invoice) {
            charges.push(charge);
          }
        });
        setChargesDevContracts(charges);
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });

    axiosService
      .get(
        `${process.env.REACT_APP_API_URL}/get_charges_dev_profit_shares_user`
      )
      .then((res) => {
        let charges: any[] = [];
        res.data.map((charge: any) => {
          if (charge.status === "NEW" && !charge.invoice) {
            charges.push(charge);
          }
        });
        setChargesDevProfitShares(charges);
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_charges_lead_rebates_user`)
      .then((res) => {
        let charges: any[] = [];
        res.data.map((charge: any) => {
          if (charge.status === "NEW" && !charge.invoice) {
            charges.push(charge);
          }
        });
        setChargesLeadRebates(charges);
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };

  const addInvoice = () => {
    const form = new FormData();

    form.append("status", "NEW");

    form.append("file", file, file.name);
    form.append("user", auth.account ? auth.account.toString() : "");

    axiosService
      .post(`${process.env.REACT_APP_API_URL}/add_invoice_dev`, form)
      .then((res) => {
        let invoice_id = res.data.id;
        let body = {
          invoice_id: invoice_id,
          contract_id: "",
          charge_contract_ids: selectedChargesDevContracts,
          charge_profit_shares_ids: selectedChargesDevProfitShares,
          charge_lead_rebates_ids: selectedChargesLeadRebates,
        };
        if (!props.lead) {
          body.contract_id = props.contract_id;
        }
        axiosService
          .post(`${process.env.REACT_APP_API_URL}/update_invoice_charges`, body)
          .then((res) => {
            reset();
            alert.success("The Invoice was added");

            props.onClose(true);
          })

          .catch((err) => {
            if (err.response) {
              Object.keys(err.response.data).forEach(function (key, index) {
                alert.error(key + ":" + err.response.data[key]);
              });
            } else {
              alert.error(err.message);
            }

            console.log(err);
          });
      })

      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  const fileData = () => {
    if (file) {
      return (
        <div className="row-center padding-normal full-percent-width">
          <h2 className="text-Proxima Nova-small margin-r3">File Details:</h2>

          <p> {file.name}</p>
        </div>
      );
    } else {
      return <h4>Please select a File</h4>;
    }
  };
  const handleChangeSelectedChargesContracts = (
    event: SelectChangeEvent<typeof selectedChargesDevContracts>
  ) => {
    const {
      target: { value },
    } = event;
    setSelectedChargesDevContracts(
      typeof value === "string" ? value.split(",") : value
    );
    let tot = 0;
    console.log(value);
    chargesDevContracts.forEach((charge: any) => {
      if (typeof value === "string") {
        value.split(",").forEach((val: string) => {
          if (val === charge.id.toString() || val === charge.id) {
            tot += charge.net_amount;
          }
        });
      } else {
        value.forEach((val: string) => {
          if (val === charge.id.toString() || val === charge.id) {
            tot += charge.net_amount;
          }
        });
      }
    });
    setTotalAmountContracts(tot);
  };
  const handleChangeSelectedChargesProfitShares = (
    event: SelectChangeEvent<typeof selectedChargesDevProfitShares>
  ) => {
    const {
      target: { value },
    } = event;
    setSelectedChargesDevProfitShares(
      typeof value === "string" ? value.split(",") : value
    );
    let tot = 0;
    console.log(value);
    chargesDevProfitShares.forEach((charge: any) => {
      if (typeof value === "string") {
        value.split(",").forEach((val: string) => {
          if (val === charge.id.toString() || val === charge.id) {
            tot += charge.net_amount;
          }
        });
      } else {
        value.forEach((val: string) => {
          if (val === charge.id.toString() || val === charge.id) {
            tot += charge.net_amount;
          }
        });
      }
    });
    setTotalAmountProfitShares(tot);
  };

  const handleChangeSelectedChargesLeadRebates = (
    event: SelectChangeEvent<typeof selectedChargesLeadRebates>
  ) => {
    const {
      target: { value },
    } = event;
    setSelectedChargesLeadRebates(
      typeof value === "string" ? value.split(",") : value
    );
    let tot = 0;
    console.log(value);
    chargesLeadRebates.forEach((charge: any) => {
      if (typeof value === "string") {
        value.split(",").forEach((val: string) => {
          if (val === charge.id.toString() || val === charge.id) {
            tot += charge.net_amount;
          }
        });
      } else {
        value.forEach((val: string) => {
          if (val === charge.id.toString() || val === charge.id) {
            tot += charge.net_amount;
          }
        });
      }
    });
    setTotalAmountLeadRebates(tot);
  };

  return (
    // <div
    //   style={{
    //     visibility: show ? "visible" : "hidden",
    //     opacity: show ? "1" : "0",
    //   }}
    //   className={"overlay"}
    // >
    <Modal
      open={show}
      onClose={closeHandler}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={"popup-invoice "}>
        <div className="add-invoice-scroll">
          <div className="row-space-between-center">
            <span className="text-Proxima Nova700">Add invoice</span>
            <span className={"close"} onClick={closeHandler}>
              &times;
            </span>
          </div>
          <div className="column-start-start">
            {chargesDevContracts.length > 0 && !props.lead && (
              <div className="padding-up-down full-percent-width">
                <Box className="add-invoice-width">
                  <FormControl fullWidth>
                    <InputLabel shrink={true} id="demo-simple-select-label">
                      Charges from Contracts
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={selectedChargesDevContracts}
                      onChange={handleChangeSelectedChargesContracts}
                      input={
                        <OutlinedInput
                          id="select-multiple-chip"
                          label="Charges from Contracts"
                          notched
                        />
                      }
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                    >
                      {chargesDevContracts.map((charge: any) => (
                        <MenuItem
                          style={{ width: 600 }}
                          key={charge.id}
                          value={charge.id}
                        >
                          <Checkbox
                            checked={
                              selectedChargesDevContracts.indexOf(charge.id) >
                              -1
                            }
                          />
                          <ListItemText
                            primary={
                              charge.ref + " Amount " + charge.net_amount
                            }
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </div>
            )}
            {chargesDevProfitShares.length > 0 && !props.lead && (
              <div className="padding-up-down full-percent-width">
                <Box className="add-invoice-width">
                  <FormControl fullWidth>
                    <InputLabel shrink={true} id="demo-simple-select-label">
                      Charges from Profit Shares
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={selectedChargesDevProfitShares}
                      onChange={handleChangeSelectedChargesProfitShares}
                      input={
                        <OutlinedInput
                          id="select-multiple-chip"
                          label=" Charges from Profit Shares"
                          notched
                        />
                      }
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                    >
                      {chargesDevProfitShares.map((charge: any) => (
                        <MenuItem
                          style={{ width: 600 }}
                          key={charge.id}
                          value={charge.id}
                        >
                          <Checkbox
                            checked={
                              selectedChargesDevProfitShares.indexOf(
                                charge.id
                              ) > -1
                            }
                          />
                          <ListItemText
                            primary={
                              charge.ref + " Amount " + charge.net_amount
                            }
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </div>
            )}
            {chargesLeadRebates.length > 0 && props.lead && (
              <div className="padding-up-down full-percent-width">
                <Box className="add-invoice-width">
                  <FormControl fullWidth>
                    <InputLabel shrink={true} id="demo-simple-select-label">
                      Charges from Lead Rebates
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={selectedChargesLeadRebates}
                      onChange={handleChangeSelectedChargesLeadRebates}
                      input={
                        <OutlinedInput
                          id="select-multiple-chip"
                          label=" Charges from Lead Rebates"
                          notched
                        />
                      }
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                    >
                      {chargesLeadRebates.map((charge: any) => (
                        <MenuItem
                          style={{ width: 600 }}
                          key={charge.id}
                          value={charge.id}
                        >
                          <Checkbox
                            checked={
                              selectedChargesLeadRebates.indexOf(charge.id) > -1
                            }
                          />
                          <ListItemText
                            primary={
                              charge.ref + " Amount " + charge.net_amount
                            }
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </div>
            )}
            {totalAmountContracts +
              totalAmountLeadRebates +
              totalAmountProfitShares >
              0 && (
              <div className="row-center padding-normal full-percent-width">
                <b>
                  Total Net Amount:{" "}
                  {totalAmountContracts +
                    totalAmountLeadRebates +
                    totalAmountProfitShares}{" "}
                  €
                </b>
              </div>
            )}
            {(chargesDevProfitShares.length > 0 ||
              chargesDevContracts.length > 0 ||
              chargesLeadRebates.length > 0) && (
              <div className="column-center padding--up-down">
                <label htmlFor="upload-invoice">
                  <input
                    style={{ display: "none" }}
                    id="upload-invoice"
                    name="upload-invoice"
                    type="file"
                    accept="application/pdf"
                    onChange={onFileChange}
                  />
                  <Button
                    color="primary"
                    size="small"
                    component="span"
                    aria-label="add"
                  >
                    Upload File
                  </Button>
                </label>
                {fileData()}
              </div>
            )}
            {chargesDevProfitShares.length === 0 &&
              chargesDevContracts.length === 0 &&
              chargesLeadRebates.length === 0 && (
                <span>
                  {props.lead
                    ? "There are no available charges to be invoiced."
                    : " There are no available charges to invoice. Please add a timesheet and wait until it's status is set to 'OK'"}
                </span>
              )}
          </div>
        </div>
        <div
          style={{
            left:
              chargesDevProfitShares.length === 0 &&
              chargesDevContracts.length === 0 &&
              chargesLeadRebates.length === 0
                ? "80%"
                : "55%",
            top: 0,
          }}
          className="add-invoice-row-flex-end"
        >
          <button
            className="button-white-small text-Proxima Nova700-small margin-r20"
            onClick={() => props.onClose(true)}
          >
            {" "}
            Cancel
          </button>
          {(chargesDevProfitShares.length > 0 ||
            chargesDevContracts.length > 0 ||
            chargesLeadRebates.length > 0) && (
            <button
              className="button-black-small-nm text-Proxima Nova700-small"
              onClick={addInvoice}
            >
              {" "}
              Upload Invoice
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
};

AddInvoice.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  contract_id: PropTypes.number,
  lead: PropTypes.bool,
};

export default AddInvoice;
