import React, { useState, useEffect } from "react";
import "./Login.css";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router";
import authSlice from "../../store/slices/auth";
import locationSlice from "../../store/slices/location";
import { useAlert } from "react-alert";

import {
  Box,
  FormControl,
  Input,
  InputLabel,
  TextareaAutosize,
  TextField,
} from "@mui/material";

function Login() {
  const [changePassword, setChangePassword] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const alert = useAlert();

  useEffect(() => {
    dispatch(locationSlice.actions.setLocation({ path: "/login" }));
  }, []);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: (values: any) => {
      handleLogin(values.email, values.password);
    },
    validationSchema: Yup.object({
      email: Yup.string().trim().required("Email is required"),
      password: Yup.string().trim().required("Password is required"),
    }),
  });
  const formik_change = useFormik({
    initialValues: {
      email: "",
      old_password: "",
      new_password: "",
    },
    onSubmit: (values: any) => {
      handleChangePassword(
        values.email,
        values.old_password,
        values.new_password
      );
    },
    validationSchema: Yup.object({
      email: Yup.string().trim().required("Email is required"),
      old_password: Yup.string().trim().required("Old password is required"),
      new_password: Yup.string().trim().required("New password is required"),
    }),
  });
  const handleLogin = (email: string, password: string) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/log_in`, { email, password })
      .then((res) => {
        if (!res.data.is_first) {
          dispatch(
            authSlice.actions.setAuthTokens({
              token: res.data.token,
            })
          );
          dispatch(authSlice.actions.setAccount(res.data.user_id));
          dispatch(authSlice.actions.setClientId(res.data.client_id));
          dispatch(authSlice.actions.setDev(res.data.isDev));
          dispatch(authSlice.actions.setLeadProvider(res.data.isLeadProvider));
          dispatch(authSlice.actions.setClient(res.data.isClient));
          dispatch(authSlice.actions.setAdmin(res.data.isAdmin));
          dispatch(authSlice.actions.setStaff(res.data.isStaff));
          dispatch(authSlice.actions.setSkills(res.data.dev_skills));
          if (res.data.isDev) {
            dispatch(authSlice.actions.setSelectedView("DEVELOPER"));
          } else if (res.data.isClient) {
            dispatch(authSlice.actions.setSelectedView("CLIENT"));
          } else if (res.data.isAdmin) {
            dispatch(authSlice.actions.setSelectedView("GYLD MANAGER"));
          } else if (res.data.isLeadProvider) {
            dispatch(authSlice.actions.setSelectedView("LEAD PROVIDER"));
          } else if (res.data.isStaff) {
            dispatch(authSlice.actions.setSelectedView("STAFF"));
          }

          navigate("/platform");
        } else {
          setChangePassword(true);
        }
      })
      .catch((err) => {
        alert.error("Wrong Credentials");
      });
  };
  const handleChangePassword = (
    email: string,
    old_password: string,
    new_password: string
  ) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/change_password`, {
        email,
        old_password,
        new_password,
      })
      .then((res) => {
        if (res.data.first)
          dispatch(
            authSlice.actions.setAuthTokens({
              token: res.data.token,
            })
          );
        dispatch(authSlice.actions.setAccount(res.data.user_id));
        dispatch(authSlice.actions.setClientId(res.data.client_id));
        dispatch(authSlice.actions.setDev(res.data.isDev));
        dispatch(authSlice.actions.setLeadProvider(res.data.isLeadProvider));
        dispatch(authSlice.actions.setClient(res.data.isClient));
        dispatch(authSlice.actions.setAdmin(res.data.isAdmin));
        dispatch(authSlice.actions.setStaff(res.data.isStaff));
        dispatch(authSlice.actions.setSkills(res.data.dev_skills));
        if (res.data.isDev) {
          dispatch(authSlice.actions.setSelectedView("DEVELOPER"));
        } else if (res.data.isClient) {
          dispatch(authSlice.actions.setSelectedView("CLIENT"));
        } else if (res.data.isAdmin) {
          dispatch(authSlice.actions.setSelectedView("GYLD MANAGER"));
        } else if (res.data.isLeadProvider) {
          dispatch(authSlice.actions.setSelectedView("LEAD PROVIDER"));
        } else if (res.data.isStaff) {
          dispatch(authSlice.actions.setSelectedView("STAFF"));
        }

        navigate("/platform");
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }
      });
  };

  return (
    <div className="login-main">
      {!changePassword ? (
        <div className="login-card">
          <span className="home-subtitle padding-up-down20">
            Log in to your account
          </span>
          <form onSubmit={formik.handleSubmit}>
            <div className="login-height">
              <Box className="login-width">
                <FormControl>
                  <InputLabel id="demo-simple-select-label"></InputLabel>
                  <TextField
                    className="border-b border-gray-300 w-full px-2 h-8 rounded focus:border-blue-500"
                    id="email"
                    type="email"
                    label="Email"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </FormControl>
              </Box>

              {formik.errors.email ? (
                <div className="padding-error">{formik.errors.email} </div>
              ) : null}
            </div>

            <div className="login-height">
              <Box className="login-width">
                <FormControl>
                  <InputLabel id="demo-simple-select-label"></InputLabel>
                  <TextField
                    className="border-b border-gray-300 w-full px-2 h-8 rounded focus:border-blue-500"
                    id="password"
                    type="password"
                    label="Password"
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </FormControl>
              </Box>

              {formik.errors.password ? (
                <div className="padding-error">{formik.errors.password} </div>
              ) : null}
            </div>
            <div className="column-center">
              <button type="submit" className="login-button ">
                Login
              </button>
            </div>
          </form>
          <button
            onClick={() => setChangePassword(true)}
            className="text-Proxima Nova700-small orange padding-up-down"
          >
            Change your password
          </button>
        </div>
      ) : (
        <div className="login-card-change">
          <span className="home-subtitle padding-up-down20">
            Change your password
          </span>
          <form onSubmit={formik_change.handleSubmit}>
            <div className="login-height">
              <Box className="login-width">
                <FormControl>
                  <InputLabel id="demo-simple-select-label"></InputLabel>
                  <TextField
                    className="border-b border-gray-300 w-full px-2 h-8 rounded focus:border-blue-500"
                    id="email"
                    type="email"
                    label="Email"
                    name="email"
                    value={formik_change.values.email}
                    onChange={formik_change.handleChange}
                    onBlur={formik_change.handleBlur}
                  />
                </FormControl>
              </Box>

              {formik_change.errors.email ? (
                <div className="padding-error">
                  {formik_change.errors.email}{" "}
                </div>
              ) : null}
            </div>

            <div className="login-height">
              <Box className="login-width">
                <FormControl>
                  <InputLabel id="demo-simple-select-label"></InputLabel>
                  <TextField
                    className="border-b border-gray-300 w-full px-2 h-8 rounded focus:border-blue-500"
                    id="old_password"
                    type="password"
                    label="Old Password"
                    name="old_password"
                    value={formik_change.values.old_password}
                    onChange={formik_change.handleChange}
                    onBlur={formik_change.handleBlur}
                  />
                </FormControl>
              </Box>

              {formik_change.errors.old_password ? (
                <div className="padding-error">
                  {formik_change.errors.old_password}{" "}
                </div>
              ) : null}
            </div>
            <div className="login-height">
              <Box className="login-width">
                <FormControl>
                  <InputLabel id="demo-simple-select-label"></InputLabel>
                  <TextField
                    className="border-b border-gray-300 w-full px-2 h-8 rounded focus:border-blue-500"
                    id="new_password"
                    type="password"
                    label="New Password"
                    name="new_password"
                    value={formik_change.values.new_password}
                    onChange={formik_change.handleChange}
                    onBlur={formik_change.handleBlur}
                  />
                </FormControl>
              </Box>

              {formik_change.errors.new_password ? (
                <div className="padding-error">
                  {formik_change.errors.new_password}{" "}
                </div>
              ) : null}
            </div>
            <div className="column-center">
              <button type="submit" className="login-button-change ">
                Change Password
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}

export default Login;
